import React, { useContext, useState, useEffect, Fragment } from "react";
import CartContext from "../context/CartContext";
import PriceCodeDataContext from "../context/PriceCodeDataContext";
import PaymentsContext from "../context/PaymentsContext";
import matzo from "../images/matzo.png";
import PlaceOrder from "./PlaceOrder";
import { useHistory } from "react-router-dom";
import { CustomerContext } from "../context/CustomerContext";
import { useTotalPrice } from "../context/TotalPriceContext"; // import the custom hook

import "../styles/Cart.css";
import GeneralAlert from "../components/GeneralAlert";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CarTypePopup from "../components/CarTypePopup";

const Cart = ({
  popupHandler,
  alertHandler,
  handleShowAlert,
  balance,
  oDeliveryDate=localStorage.getItem('oDelivery'),
  setGeneralAlert,
  setGeneralAlertText,
}) => {
  console.log(oDeliveryDate);
  const deliveryD = new Date(oDeliveryDate);

  // Format the date to MM/DD/YYYY
  const formattedPropDate = deliveryD.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const GOOGLE_API_KEY = "AIzaSyB62k0-8YzdEYoR_9Y68c9eWFHKSHxHtl4";

  const [cart, setCart] = useContext(CartContext);
  const [totalDiscount, setTotalDiscount] = useState(null);
  const { customerId } = useContext(CustomerContext);
  const [customerData, setCustomerData] = useState([]);
  const [payments, setPayments] = useContext(PaymentsContext);
  const [priceCodeData, setPriceCodeData] = useContext(PriceCodeDataContext);
  const [carLists, setCarLists] = useState([]);
  const [showCarTypePopup,setShowCarTypePopup]= useState(false);
  const [comment, setComment] = useState("");
  const [deliveryOption, setDeliveryOption] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const cartActive = useContext(CartContext);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isToggleActive, setToggleActive] = useState(false);
  const [checkPrice, setCheckPrice] = useState(0);
  const [updateTheAddress, setUpdateTheAdress] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let ZipCode =
    localStorage.getItem("ZipCode") !== null
      ? localStorage.getItem("ZipCode")
      : "";

  let shipVia;
  const [isValidZipCodeForDelivery, setIsValidZipCodeForDelivery] =
    useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [
    isDeliveryFeePasswordPromptVisible,
    setIsDeliveryFeePasswordPromptVisible,
  ] = useState(false);
  const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
  const [isPasswordCorrect, setPasswordCorrect] = useState(false);
  const [isDisplayPasswordMsg, setDisplayPasswordMsg] = useState(false);
  const { priceCode: PriceCodeFromContext, setPriceCode } =
    useContext(CustomerContext);
  const { totalPrice, setTotalPrice, setTempTotalPrice } = useTotalPrice(); // use the custom hook
  const savedTotalPrice = localStorage.getItem("savedTotalPrice");
  const [loading, setLoading] = useState(false); // To handle loading state during API request.
  const [tempBalance, setTempBalance] = useState(0);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [shouldDeliveryModalVisible, setShouldDeliveryModalVisible] =
    useState(false);
  const [selectedPriceCode, setSelectedPriceCode] = useState(null);
  const [selectedCarList, setSelectedCarList] = useState(null);
  const [inputCarValue,setInputCarValue]=useState(null)
  const [enterNewAddress, setEnterNewAddress] = useState(false);
  const [newAddressDetails, setNewAddressDetails] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryAreaId, setDeliveryAreaId] = useState("");
  const [quantityMultiple, setQuantityMulitple] = useState(1);
  const [isNewCustomerAddress, setIsNewCustomerAddress] = useState(false);
  const [cusId, setCusId] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [isPaymentButtonVisible, setIsPaymentButtonVisible] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  let Subaccount;
  const history = useHistory();
  let count = 1;
  const [editingItemName, setEditingItemName] = useState(null);
  const [password, setPassword] = useState("");
  const [editedDeliveryFee, setEditedDeliveryFee] = useState(0);
  const [isDeliveryFeeEditing, setIsDeliveryFeeEditing] = useState(false);
  const [tempAddress1, setTempAddress1] = useState(null);
  const [tempAddress2, setTempAddress2] = useState(null);
  const [tempCity, setTempCity] = useState(null);
  const [tempState, setTempState] = useState(null);
  const [tempZipCode, setTempZipCode] = useState(null);
  const [shouldDeliveryOptionVisible, setShouldDeliveryOptionVisible] =
    useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPricePasswordPromptVisible, setIsPricePasswordPromptVisible] =
    useState(false);
  const [zeroOutStanding, setZeroOutStanding] = useState(false);

  console.log(totalPrice);


  useEffect(() => {
    if(localStorage.getItem('oDelivery')){
      
      setSelectedDateOption(formattedPropDate);
    }
  }, []);

  // useEffect(() => {
  //   console.log(cart)
  //   if (customerData.length > 0) {
  //     setTempAddress1((val) => customerData[0].Address1);
  //     setTempAddress2((val) => customerData[0].Address2);
  //     setTempCity((val) => customerData[0].City);
  //     setTempState((val) => customerData[0].State);
  //     setTempZipCode((val) => customerData[0].ZipCode);
  //   }
  // }, [customerData]);

  const handleDeliveryFeePasswordSubmit = (event) => {
    event.preventDefault();
    // Replace this with your actual password validation logic
    const enteredPassword = event.target.password.value;
    if (enteredPassword === "3486") {
      setEditedDeliveryFee((val) => deliveryFee);
      setIsDeliveryFeeEditing((val) => true);
      setIsDeliveryFeePasswordPromptVisible((val) => false);
    } else {
      setPasswordCorrect(false); // Set password correctness to false
      setDisplayPasswordMsg(true);
    }
  };

  const handleCloseDeliveryFeePasswordPrompt = () => {
    setIsDeliveryFeePasswordPromptVisible((val) => false);
    setDisplayPasswordMsg((val) => false);
  };

  const handleDeliveryFeeDoubleClick = () => {
    setIsDeliveryFeePasswordPromptVisible((val) => true);
  };

  const handleDeliveryFeeChange = (event) => {
    setEditedDeliveryFee((val) => event.target.value);
    //setDeliveryFee(val => event.target.value);
  };

  const handleDeliveryFeeBlur = () => {
    if (editedDeliveryFee === "") {
      setDeliveryFee((val) => 0);
      setEditedDeliveryFee((val) => 0);
      setIsDeliveryFeeEditing((val) => false);
    } else {
      setDeliveryFee((val) => editedDeliveryFee);
      setIsDeliveryFeeEditing((val) => false);
    }
    // Handle any other logic you need when editing is done
  };

  const fetchCustomerDetails = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      console.log("CusID", customerId);
      setCusId((id) => customerId);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 401) {
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
        setCustomerData(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
    //fetch
  }, [customerId]);
  useEffect(() => {
    //fetch
  }, [selectedDateOption]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token == null) {
      handleCancelClick();
      history.replace("/login/");
    }

    return history.listen((location) => {
      if (cart.length > 0) {
        localStorage.setItem("savedTotalPrice", totalPrice); // Save the totalPrice before confirmation

        const userConfirmed = window.confirm(
          "You have items in your cart. Navigating away will clear your cart. Do you wish to continue?"
        );

        if (userConfirmed) {
          setCart([]); // Clear the cart only if user confirms
        } else {
          history.goBack();
        }
      }
    });
  }, [history, setCart, cart, totalPrice]);

  useEffect(() => {
    refreshList();

    const handleStorageChange = (e) => {
      if (e.key === "PriceCode") {
        refreshList();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [PriceCodeFromContext]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "DeliveryZipCode/IsZipCodeValid/" +
        ZipCode,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 401) {
          history.replace("/login/");
        }
        return response.json();
      })
      .then((data) => {
        setIsValidZipCodeForDelivery(data);
        if (data) {
          setShouldDeliveryModalVisible(true);
        }
      });
  }, [ZipCode]);
  useEffect(() => {
    if (tempZipCode !== "" && tempZipCode !== null && tempZipCode?.length == 5) {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "DeliveryZipCode/IsZipCodeValid/" +
          tempZipCode,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response?.status === 401) {
            history.replace("/login/");
          }
          return response.json();
        })
        .then((data) => {
          setIsValidZipCodeForDelivery(data);
          if (data) {
            setShouldDeliveryModalVisible(true);
          }
        });
    }
  }, [tempZipCode]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }

    console.log(ZipCode);
    if (cart[0]?.ShipZip) {
      ZipCode = cart[0]?.ShipZip;
    }
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}DeliveryZipCode/GetZipCodeData/${ZipCode}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 401) {
          history.replace("/login/");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          //setSelectedDateOption((prevVal) => data[0].Date);
          setDeliveryAreaId((id) => data[0].DeliveryAreaId);

          const futureDatesData = data?.filter((item) =>
            isFutureDate(item.Date)
          );
          // const futureDatesData = data.filter((item) =>{
          //   const parts = item.Date.split('/');
          //   const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
          //   item.Date = formattedDate;
          //   return isFutureDate(formattedDate);

          // });

          console.log(futureDatesData);
          setDateOptions((prevOptions) => futureDatesData);
        }
      });
  }, [ZipCode, deliveryOption]);

  const getTotalPrice = () => {
    const getCurrTotal = localStorage.getItem("currSelected");

    console.log(deliveryOption);
    if (deliveryOption === "Delivery") {
      //
      const balance = localStorage.getItem("balance");
      //   if(checkPrice === 1){
      let newDeliveryFee = 0;
      console.log(cart);
      console.log(cart[0].Freight)
      if (deliveryFee !== cart[0].Freight) {
        if(cart[0].Freight){
          newDeliveryFee = parseFloat(deliveryFee) - parseFloat(cart[0].Freight);
        }else{
          newDeliveryFee = parseFloat(deliveryFee)
        }
        console.log(newDeliveryFee);
        //  localStorage.setItem('newDeliFee', newDeliveryFee)
        // handleUpdateAddressClick()
        // setTotalPrice((val) =>deliveryFee -  cart[0].Freight )
      }
      // const getNewDeliFee = localStorage.getItem("newDeliFee")
      //     let calculatedTotalPrice = 0;
      //     for (let i =0; i < cart.length; i++) {
      //       calculatedTotalPrice += cart[i].price * cart[i].quantity;
      //     }
      //     setTotalPrice(prev=>calculatedTotalPrice-1)
      //     return calculatedTotalPrice-1

      // }
      // console.log(totalPrice)

      if (balance !== tempBalance && balance == "0" && balance != null) {
        //
        console.log(deliveryFee, cart[0].Freight);
        console.log(deliveryFee !== cart[0].Freight);
        const localStorageIncreasedPrice =
          localStorage.getItem("increasedPrice");
        console.log(localStorageIncreasedPrice);

        // else

        if (localStorageIncreasedPrice !== null) {
          let increasedPrice = localStorage.getItem("increasedPrice");
          console.log(increasedPrice);
          let tempcalculatedTotalPrice = 0;
          for (let i = 0; i < cart.length; i++) {
            if (cart[i].Balance || cart[i].Balance == 0) {
            } else {
              tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
            }
          }

          if (tempcalculatedTotalPrice > 0) {
            tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
          }

          let newtempcalculatedTotalPrice =
            parseFloat(increasedPrice) +
            parseFloat(newDeliveryFee) +
            parseFloat(tempcalculatedTotalPrice);
          console.log(
            "new: ---------------------    :",
            newtempcalculatedTotalPrice
          );
          // if(getNewDeliFee){

          //   newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) + parseFloat(getNewDeliFee)

          // }
          if (getCurrTotal) {
            newtempcalculatedTotalPrice =
              parseFloat(newtempcalculatedTotalPrice) +
              parseFloat(getCurrTotal);
          }
          localStorage.setItem("savedTotalPrice", newtempcalculatedTotalPrice);
          // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
          setTempTotalPrice(newtempcalculatedTotalPrice);
          // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
          return newtempcalculatedTotalPrice;

          setTotalPrice((val) => localStorage.getItem("increasedPrice"));
        } else {
          console.log(" in else");
          let tempcalculatedTotalPrice = 0;
          for (let i = 0; i < cart.length; i++) {
            if (cart[i].Balance || cart[i].Balance == 0) {
            } else {
              tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
            }
          }

          if (tempcalculatedTotalPrice > 0) {
            tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
          }

          let newtempcalculatedTotalPrice =
            parseFloat(newDeliveryFee) + parseFloat(tempcalculatedTotalPrice) ;
          console.log(
            "new: ---------------------    :",
            newtempcalculatedTotalPrice
          );
          // if(getNewDeliFee){
          //   newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) + parseFloat(getNewDeliFee)
          // }
          if (getCurrTotal) {
            newtempcalculatedTotalPrice =
              parseFloat(newtempcalculatedTotalPrice) +
              parseFloat(getCurrTotal);
          }
          localStorage.setItem("savedTotalPrice", newtempcalculatedTotalPrice);
          // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
          setTempTotalPrice(newtempcalculatedTotalPrice);
          // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
          return newtempcalculatedTotalPrice;
          setTotalPrice((prevPrice) => 0);
        }

        return totalPrice;
      }

      if (balance == null) {
        console.log("here");
        let calculatedTotalPrice = 0;
        for (let i = 0; i < cart.length; i++) {
          calculatedTotalPrice += cart[i].price * cart[i].quantity;
        }
        calculatedTotalPrice =
          parseFloat(calculatedTotalPrice) +
          parseFloat(deliveryFee) +
          (quantityMultiple > 1 ? (quantityMultiple - 1) * 10 : 0);

        setTotalPrice((price) => calculatedTotalPrice);
        localStorage.setItem("savedTotalPrice", calculatedTotalPrice);

        return totalPrice;
      } else if (parseInt(balance) == 0) {
        console.log("here is");
        setTotalPrice((price) => {
          return (
            parseFloat(totalPrice).toFixed(2) +
            (
              parseFloat(deliveryFee) +
              (quantityMultiple > 1 ? (quantityMultiple - 1) * 10 : 0)
            ).toFixed(2)
          );
        });
        let calculatedTotalPrice = 0;

        for (let i = 0; i < cart.length; i++) {
          calculatedTotalPrice += cart[i].price * cart[i].quantity;
        }

        calculatedTotalPrice = (
          parseFloat(calculatedTotalPrice) +
          parseFloat(deliveryFee) +
          (quantityMultiple > 1 ? (quantityMultiple - 1) * 10 : 0)
        ).toFixed(2);
        localStorage.setItem("savedTotalPrice", calculatedTotalPrice);

        return totalPrice;
      } else {
        console.log("hm in else");
        console.log(totalPrice);
        console.log(cart);
        let calculatedTotalPrice = 0;
        let tempcalculatedTotalPrice = 0;

        for (let i = 0; i < cart.length; i++) {
          calculatedTotalPrice += cart[i].price * cart[i].quantity;
        }
        calculatedTotalPrice = (
          parseFloat(calculatedTotalPrice) +
          parseFloat(deliveryFee) * quantityMultiple
        ).toFixed(2);
        for (let i = 0; i < cart.length; i++) {
          if (cart[i].Balance) {
          } else {
            tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
          }
        }

        if (tempcalculatedTotalPrice > 0) {
          tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
        }

        console.log(tempcalculatedTotalPrice);
        // console.log(parseFloat(totalPrice) + (parseFloat(deliveryFee) * quantityMultiple).toFixed(2))
        if (balance < totalPrice) {
          // setTotalPrice((price) => {
          //   return ( (parseFloat(totalPrice)+  parseFloat(deliveryFee) * quantityMultiple).toFixed(2)
          if (totalPrice > 0) {
            console.log(localStorage.getItem("increaseTotalPrice"));
            console.log(parseFloat(totalPrice));
            let newtempcalculatedTotalPrice =
              parseFloat(totalPrice) +
              parseFloat(newDeliveryFee) +
              parseFloat(tempcalculatedTotalPrice);
            // if(getNewDeliFee){
            //   newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) + parseFloat(getNewDeliFee)
            // }
            if (getCurrTotal) {
              newtempcalculatedTotalPrice =
                parseFloat(newtempcalculatedTotalPrice) +
                parseFloat(getCurrTotal);
            }
            console.log(
              "new: ---------------------    :",
              newtempcalculatedTotalPrice
            );
            localStorage.setItem(
              "savedTotalPrice",
              newtempcalculatedTotalPrice
            );
            // setTotalPrice(newtempcalculatedTotalPrice)
            setTempTotalPrice(newtempcalculatedTotalPrice);
            return newtempcalculatedTotalPrice;
            // setTotalPrice((price)=>newtempcalculatedTotalPrice)
          } else {
            let newtempcalculatedTotalPrice =
              parseFloat(totalPrice) +
              parseFloat(newDeliveryFee) +
              parseFloat(tempcalculatedTotalPrice);
            // if(getNewDeliFee){
            //   newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) + parseFloat(getNewDeliFee)
            // }
            if (getCurrTotal) {
              newtempcalculatedTotalPrice =
                parseFloat(newtempcalculatedTotalPrice) +
                parseFloat(getCurrTotal);
            }
            console.log(
              "new: ---------------------    :",
              newtempcalculatedTotalPrice
            );
            localStorage.setItem(
              "savedTotalPrice",
              newtempcalculatedTotalPrice
            );
            // setTotalPrice(newtempcalculatedTotalPrice)
            setTempTotalPrice(newtempcalculatedTotalPrice);
            return newtempcalculatedTotalPrice;
            setTotalPrice((price) => tempcalculatedTotalPrice);
            localStorage.setItem("savedTotalPrice", tempcalculatedTotalPrice);
          }
          //   );
          // });
          // console.log(totalPrice,typeof totalPrice)
          // if(totalPrice > 0){

          //   // tempcalculatedTotalPrice = parseFloat(tempcalculatedTotalPrice) + parseFloat(totalPrice)
          //   // console.log(tempcalculatedTotalPrice)

          //   setTotalPrice((price) => totalPrice);
          // }else{
          // }

          //         let newTotalPrice =0;

          // if (totalPrice > 0) {
          //   newTotalPrice = (parseFloat(totalPrice) + parseFloat(tempcalculatedTotalPrice)).toFixed(2);
          // } else {
          //   newTotalPrice = parseFloat(tempcalculatedTotalPrice).toFixed(2);
          // }
          // setTotalPrice(newTotalPrice)
        } else {
          let newtempcalculatedTotalPrice =
            parseFloat(totalPrice) +
            parseFloat(balance) +
            parseFloat(newDeliveryFee) +
            parseFloat(tempcalculatedTotalPrice);
          // if(getNewDeliFee){
          //   newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) + parseFloat(getNewDeliFee)
          // }
          if (getCurrTotal) {
            newtempcalculatedTotalPrice =
              parseFloat(newtempcalculatedTotalPrice) +
              parseFloat(getCurrTotal);
          }
          console.log(
            "new: ---------------------    :",
            newtempcalculatedTotalPrice
          );
          localStorage.setItem("savedTotalPrice", newtempcalculatedTotalPrice);
          // setTotalPrice(newtempcalculatedTotalPrice)
          setTempTotalPrice(newtempcalculatedTotalPrice);
          setTempBalance(newtempcalculatedTotalPrice);
          return newtempcalculatedTotalPrice;
          setTotalPrice(balance);
          localStorage.setItem("savedTotalPrice", calculatedTotalPrice);
        }
        return totalPrice;
      }
    } else {
      const balance = localStorage.getItem("balance");
      console.log(balance);
      console.log(tempBalance);
      const shipVia = localStorage.getItem("shipvia");
      if (balance !== tempBalance && balance == "0" && balance != null) {
        if (localStorage.getItem("increasedPrice") != null) {
          console.log("idhar");
          console.log(cart);
          let increasedPrice = localStorage.getItem("increasedPrice");
          console.log(increasedPrice);
          let tempcalculatedTotalPrice = 0;
          for (let i = 0; i < cart.length; i++) {
            if (cart[i].Balance || cart[i].Balance == 0) {
            } else {
              tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
            }
          }

          if (tempcalculatedTotalPrice > 0) {
            tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
          }

          let newtempcalculatedTotalPrice =
            parseFloat(increasedPrice) + parseFloat(tempcalculatedTotalPrice);
          console.log(
            "new: ---------------------    :",
            newtempcalculatedTotalPrice
          );
          if (getCurrTotal) {
            newtempcalculatedTotalPrice =
              parseFloat(newtempcalculatedTotalPrice) +
              parseFloat(getCurrTotal);
          }
          localStorage.setItem("savedTotalPrice", newtempcalculatedTotalPrice);
          // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
          setTempTotalPrice(newtempcalculatedTotalPrice);
          // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
          return newtempcalculatedTotalPrice;
          console.log(tempcalculatedTotalPrice);
          console.log(getCurrTotal);
          console.log(totalPrice);

          setTotalPrice((val) => localStorage.getItem("increasedPrice"));

          return totalPrice;
        } else {
          // setNewCount(newCount+1)
          console.log(cart);

          let calculateTotal = 0;
          //     const newQuantity = Number(e.target.value);
          // const newincreasedPrice = (newQuantity - cartItem.quantity) * cartItem.Price;
          //     let prevInreasedPrice = 0;
          //     if (localStorage.getItem("newincreasedPrice") != null) {
          //       prevInreasedPrice = parseFloat(localStorage.getItem("increasedPrice"));
          //       localStorage.removeItem("increasedPrice");
          //     }
          //     localStorage.setItem(
          //       "newincreasedPrice",
          //       parseFloat(newincreasedPrice) + prevInreasedPrice
          //     );
          let tempcalculatedTotalPrice = 0;
          for (let i = 0; i < cart.length; i++) {
            if (cart[i].Balance || cart[i].Balance == 0) {
            } else {
              tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
            }
          }

          if (tempcalculatedTotalPrice > 0) {
            tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
          }

          let newtempcalculatedTotalPrice = parseFloat(
            tempcalculatedTotalPrice
          );
          console.log(
            "new: ---------------------    :",
            newtempcalculatedTotalPrice
          );
          if (getCurrTotal) {
            newtempcalculatedTotalPrice =
              parseFloat(newtempcalculatedTotalPrice) +
              parseFloat(getCurrTotal);
          }
          localStorage.setItem("savedTotalPrice", newtempcalculatedTotalPrice);
          // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
          setTempTotalPrice(newtempcalculatedTotalPrice);
          // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
          return newtempcalculatedTotalPrice;

          for (let i = 0; i < cart.length; i++) {
            console.log("start of loop");
            console.log(cart[i].Balance);

            console.log(cart[i].price, cart[i].quantity);
            if (cart[i].Balance !== undefined) {
            } else {
              calculateTotal += cart[i].price * cart[i].quantity;
            }
          }
          console.log(calculateTotal);
          setTotalPrice((price) => calculateTotal);

          return totalPrice;
        }
      }

      if (balance == null) {
        let calculatedTotalPrice = 0;
        for (let i = 0; i < cart.length; i++) {
          calculatedTotalPrice += cart[i].price * cart[i].quantity;
        }

        if (shipVia === "Delivery") {
          calculatedTotalPrice = (
            parseFloat(calculatedTotalPrice) +
            parseFloat(deliveryFee) +
            (quantityMultiple > 1 ? (quantityMultiple - 1) * 10 : 0)
          ).toFixed(2);
        } else {
          console.log(calculatedTotalPrice);
          calculatedTotalPrice = calculatedTotalPrice.toFixed(2);
        }

        setTotalPrice((price) => calculatedTotalPrice);
        localStorage.setItem("savedTotalPrice", calculatedTotalPrice);

        return totalPrice;
      } else if (parseInt(balance) == 0) {
        console.log("in else if");

        // setAlertText("Balance is 0");
        // setShowAlert(true);
        if (localStorage.getItem("increasedPrice") != null) {
          setAlertText(localStorage.getItem("increasedPrice"));
          setShowAlert(true);
          // alert(localStorage.getItem("increasedPrice"));
          setTotalPrice((val) =>
            parseFloat(localStorage.getItem("increasedPrice"))
          );
          localStorage.removeItem("increasedPrice");

          return totalPrice;
        } else {
          setTotalPrice(totalPrice);
          let calculatedTotalPrice = 0;
          console.log(totalPrice);
          console.log(cart);
          for (let i = 0; i < cart.length; i++) {
            calculatedTotalPrice += cart[i].price * cart[i].quantity;
          }
          if (shipVia === "Delivery") {
            calculatedTotalPrice = (
              parseFloat(calculatedTotalPrice) +
              parseFloat(deliveryFee) +
              (quantityMultiple > 1 ? (quantityMultiple - 1) * 10 : 0)
            ).toFixed(2);
          } else {
            calculatedTotalPrice = calculatedTotalPrice.toFixed(2);
          }
          localStorage.setItem("savedTotalPrice", calculatedTotalPrice);

          return totalPrice;
        }
      } else {
        console.log("in else");
        let calculatedTotalPrice = 0;
        let tempcalculatedTotalPrice = 0;
        for (let i = 0; i < cart.length; i++) {
          calculatedTotalPrice += cart[i].price * cart[i].quantity;
        }
        if (shipVia === "Delivery") {
          calculatedTotalPrice = (
            parseFloat(calculatedTotalPrice) +
            parseFloat(deliveryFee) +
            (quantityMultiple > 1 ? (quantityMultiple - 1) * 10 : 0)
          ).toFixed(2);
        } else {
          calculatedTotalPrice = calculatedTotalPrice.toFixed(2);
        }

        console.log(cart);

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].Balance) {
          } else {
            tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
          }
        }

        if (tempcalculatedTotalPrice > 0) {
          tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
        }
        if (parseFloat(balance) < parseFloat(totalPrice)) {
          if (totalPrice > 0) {
            console.log(getCurrTotal);
            console.log(tempcalculatedTotalPrice);
            console.log(parseFloat(totalPrice));
            let newtempcalculatedTotalPrice =
              parseFloat(totalPrice) + parseFloat(tempcalculatedTotalPrice);
            console.log(
              "new: ---------------------    :",
              newtempcalculatedTotalPrice
            );
            if (getCurrTotal) {
              newtempcalculatedTotalPrice =
                parseFloat(newtempcalculatedTotalPrice) +
                parseFloat(getCurrTotal);
            }
            localStorage.setItem(
              "savedTotalPrice",
              newtempcalculatedTotalPrice
            );
            // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
            setTempTotalPrice(newtempcalculatedTotalPrice);
            // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
            return newtempcalculatedTotalPrice;
            // setTotalPrice((price)=>newtempcalculatedTotalPrice)
          } else {
            console.log(" in else");
            let tempcalculatedTotalPrice = 0;
            for (let i = 0; i < cart.length; i++) {
              if (cart[i].Balance || cart[i].Balance == 0) {
              } else {
                tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
              }
            }

            if (tempcalculatedTotalPrice > 0) {
              tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
            }

            let newtempcalculatedTotalPrice = parseFloat(
              tempcalculatedTotalPrice
            );
            console.log(
              "new: ---------------------    :",
              newtempcalculatedTotalPrice
            );
            if (getCurrTotal) {
              newtempcalculatedTotalPrice =
                parseFloat(newtempcalculatedTotalPrice) +
                parseFloat(getCurrTotal);
            }
            localStorage.setItem(
              "savedTotalPrice",
              newtempcalculatedTotalPrice
            );
            // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
            setTempTotalPrice(newtempcalculatedTotalPrice);
            // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
            return newtempcalculatedTotalPrice;
            setTotalPrice((price) => tempcalculatedTotalPrice);
            localStorage.setItem("savedTotalPrice", tempcalculatedTotalPrice);
          }
          console.log("here");
          // setTotalPrice((price) => totalPrice);
          // localStorage.setItem("savedTotalPrice", calculatedTotalPrice);
        } else {
          console.log("may be here");
          let tempcalculatedTotalPrice = 0;
          for (let i = 0; i < cart.length; i++) {
            if (cart[i].Balance || cart[i].Balance == 0) {
            } else {
              tempcalculatedTotalPrice += cart[i].price * cart[i].quantity;
            }
          }

          if (tempcalculatedTotalPrice > 0) {
            tempcalculatedTotalPrice = tempcalculatedTotalPrice.toFixed(2);
          }

          let newtempcalculatedTotalPrice =
            parseFloat(tempcalculatedTotalPrice) + parseFloat(balance);
          console.log(
            "new: ---------------------    :",
            newtempcalculatedTotalPrice
          );
          if (getCurrTotal) {
            newtempcalculatedTotalPrice =
              parseFloat(newtempcalculatedTotalPrice) +
              parseFloat(getCurrTotal);
          }
          localStorage.setItem("savedTotalPrice", newtempcalculatedTotalPrice);
          // setTotalPrice((prev)=>newtempcalculatedTotalPrice)
          setTempTotalPrice(newtempcalculatedTotalPrice);
          // newtempcalculatedTotalPrice = parseFloat(newtempcalculatedTotalPrice) - parseFloat(totalPrice) - parseFloat(tempcalculatedTotalPrice)
          setTempBalance((temp) => newtempcalculatedTotalPrice);
          return newtempcalculatedTotalPrice;
          setTotalPrice((price) => balance);
          localStorage.setItem("savedTotalPrice", calculatedTotalPrice);
        }

        return totalPrice;
      }
    }
  };

  const isFutureDate = (dateString) => {
    const today = new Date();
    const parts = dateString.split("/");

    // Note: Months are 0-indexed in JavaScript Date, so subtract 1 from the month
    const itemDate = new Date(parts[2], parts[1] - 1, parts[0]);

    return itemDate >= today;
  };

  const handleUpdateAddressClick = (newdeliveryfee) => {
    const info = {
      Comment: comment,
      Subaccount: subAccount,
      InvoiceNo: cart[0]?.InvoiceNo,
      ShipVia: deliveryOption,
      ShipAdrs1: tempAddress1 !== null ? tempAddress1 : cart[0]?.ShipAdrs1,
      ShipAdrs2: tempAddress2 !== null ? tempAddress2 : cart[0]?.ShipAdrs2,
      ShipCity: tempCity !== null ? tempCity : cart[0]?.ShipCity,
      ShipState: tempState !== null ? tempState : cart[0]?.ShipCity,
      ShipZip: tempZipCode !== null ? tempZipCode: cart[0]?.ShipZip,
      Freight: deliveryOption === "Delivery" ? newdeliveryfee : cart[0]?.Freight,
      Date: new Date().toISOString().slice(0, 19).replace("T", " "),
      DeliveryDate:
        deliveryOption === "Delivery"
          ? convertDateFormat(selectedDateOption)
          : deliveryDate,
    };

    console.log(info);
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "invoice/UpdatePartialOrder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(info),
    })
      .then((res) => {
        if (res?.status === 401) {
          history.replace("/login/");
        }
        return res.json();
      })
      .then((data) => {
        setIsPaymentButtonVisible(true);
        // setAlertText("Shipping Updated Succesfully!");
        // setShowAlert(true);
        // fetchOrderDetails()
        // alert(data);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "DeliveryArea/" + deliveryAreaId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 401) {
          history.replace("/login/");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 1) {
          setDeliveryFee((fee) => data[0].DeliveryPrice);

          getTotalPrice();

          
        }
      });
  }, [deliveryAreaId]);

  useEffect(() => {
    console.log(PriceCodeFromContext);
    const filterPriceCode = priceCodeData?.find(
      (priceCode) => priceCode.Price == PriceCodeFromContext
    );
    console.log(filterPriceCode);
    const newString =
      filterPriceCode?.Price + " : " + filterPriceCode?.PriceDesc;
    console.log(newString);
    setSelectedPriceCode(newString);
  }, [PriceCodeFromContext]);
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}PriceCode/GetPriceCodes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 401) {
          history.replace("/login/");
        }
        // Check if response is OK
        if (!response?.ok) {
          const text = await response.text();
          throw new Error(text || "Network response was not ok");
        }
        const data = await response.json();
        setPriceCodeData(data);
        setSelectedPriceCode((val) => data[0].ID + ":" + data[0].PriceDesc);
      } catch (error) {
        console.log("error");
      }
    };
    const getCarListData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}ListCar/GetListCarData`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 401) {
          history.replace("/login/");
        }
        // Check if response is OK
        if (!response?.ok) {
          const text = await response.text();
          throw new Error(text || "Network response was not ok");
        }
        const data = await response.json();
        setCarLists(data);
      } catch (error) {
        console.log("error");
      }
    };

    fetchOrderDetails(); // This invokes the function to fetch the order details
    getCarListData();
  }, []);

  useEffect(() => {
    //alert('price code changed: ' + PriceCodeFromContext);
    console.log("state updated: " + isPasswordPromptVisible);
  }, [isPasswordPromptVisible]);

  useEffect(() => {
    setDeliveryOption((option) =>
      localStorage.getItem("shipvia") != null
        ? localStorage.getItem("shipvia")
        : option
    );
  });

  const refreshList = () => {
    console.log("PriceCodeFromContext", PriceCodeFromContext);
    const selectedPriceCode = PriceCodeFromContext || "1";
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "inventory/GetByPriceCode/" +
        selectedPriceCode,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response?.status === 401) {
          history.replace("/login/");
        }
        return response.json();
      })
      .then((data) => {
        const updatedCart = cart.map((cartItem) => {
          const inventoryItem = data.find(
            (item) => item.StyleNo === cartItem.StyleNo
          );
          console.log("inventoryItem", inventoryItem);
          if (inventoryItem) {
            return {
              ...cartItem,
              discount:
                (inventoryItem.OriginalPrice - inventoryItem.Price) *
                cartItem.quantity,
              price: inventoryItem.Price,
            };
          }
          return cartItem; // If no match in inventory, return the original cart item
        });
        setCart(updatedCart);
      });
  };

  useEffect(() => {
    const handlePageRefresh = () => {
      setIsOpen(false);
      setTotalPrice(tempBalance);
      setCart([]);
      setComment("");
      setPayments([]);
      localStorage.removeItem("balance");
      localStorage.removeItem("shipvia");
    };
    window.addEventListener("beforeunload", handlePageRefresh);
    return () => {
      window.removeEventListener("beforeunload", handlePageRefresh);
    };
  }, []);

  const handleCancelClick = () => {
    localStorage.removeItem("increasedPrice");
    localStorage.removeItem("shipvia");
    localStorage.removeItem("balance");
    localStorage.removeItem("currSelected");
    localStorage.removeItem("newDeliFee");
    localStorage.removeItem("oDelivery");
    setIsOpen(false);
    setTempTotalPrice(0);
    setTotalPrice(tempBalance);
    setCart([]);
    setComment("");
    setPayments([]);
    setIsDeliveryFeeEditing((val) => false);
    setEditedDeliveryFee((val) => 0);
    setDeliveryOption((val) => "");
    setShouldDeliveryModalVisible((val) => false);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleChange = () => {
    setToggleActive((prevState) => !prevState);
  };

  const handlePlaceOrder = async () => {
    // if (
    //   deliveryFee !== cart[0]?.Freight &&
    //   cart[0]?.Freight !== undefined
    // ) {
      handleUpdateAddressClick(deliveryFee);
    // }
    const token = localStorage.getItem("authToken");
    let data;
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${customerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.status === 401) {
      history.replace("/login/");
    }
    if (!response?.ok) {
      throw new Error(`HTTP error! status: ${response?.status}`);
    } else {
      data = await response.json();
      setCustomerData(data);
    }

    // let custToUpdate = {
    //   customerId: customerId,
    //   CarNo: selectedCarList,
    // }
    // ...data,
    if (data.length == 1) {
      data[0].CarNo = selectedCarList;
    }
    // console.log(custToUpdate)
    fetch(`${process.env.REACT_APP_API_ENDPOINT}customer`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data[0]),
    }).then((response) => {
      if (!response?.ok) {
        console.log("error: Cannot update");
      }
    });

    // updateOrderHandler()
    console.log(deliveryOption, "selected", selectedDateOption);
    if (deliveryOption === "" && deliveryOption !== "Delivery") {
      setAlertText("Please Select Delivery Method!");
      setShowAlert(true);
      // alert("Please Select Delivery Method!");
    } else if (deliveryDate === "" && deliveryOption !== "Delivery") {
      setAlertText("Please Select Order Date!");
      setShowAlert(true);
      // alert("Please Select Order Date!");
    } else if (selectedDateOption === "" && deliveryOption === "Delivery") {
      setAlertText("Please Select Delivery Date!");
      setShowAlert(true);
      
      // alert("Please Select Delivery Date!");
    } else {
      setIsPlacingOrder(true); // add this line
      localStorage.removeItem("shipvia");
    }
  };

  const handleCancelOrder = () => {
    setIsPlacingOrder(false);
    setPayments([]);
  };

  useEffect(() => {
    let totalQuantity = 0;
    for (let i = 0; i < cart.length; i++) {
      totalQuantity += cart[i].quantity;
    }
    const isMultipleOf20WithRemainder = totalQuantity % 20 !== 0;
    setQuantityMulitple((val) => {
      if (isMultipleOf20WithRemainder) {
        return parseInt(totalQuantity / 20) + 1;
      } else {
        return parseInt(totalQuantity / 20);
      }
    });
  }, [cart]);

  useEffect(() => {
    if (cart.length === 0) {
      setIsPlacingOrder(false);
    } else {
      if (
        cart[cart.length-1].Balance === 0 &&
        localStorage.getItem("increasedPrice") == null
      ) {
        setIsPaymentButtonVisible(false);
      } else {
  
        setIsPaymentButtonVisible(true);
      }
    }
  }, [cart]);

  useEffect(() => {
    if (cart.length > 0) {
      setIsOpen(true);
      setSubAccount((prevValue) => cart[0].SubAccount);
      setComment((prevVal) => cart[0].Comment);

      if (cart[0].Date != undefined) {
        setDeliveryDate((date) => cart[0].Date.split("T")[0]);
      }
    }
  }, [cart]);

  const removeFromCart = (itemToRemove) => {
    if (
      window.confirm("Are you sure you want to delete this item from cart?")
    ) {
      setCart((prevCart) => {
        return prevCart.filter(
          (item) => item.StyleDesc !== itemToRemove.StyleDesc
        );
      });
    }
  };

  const removePayment = (payment) => {
    //console.log(payment);
    if (window.confirm("Are you sure you want to delete this payment?")) {
      setPayments((prevPayments) => {
        return prevPayments.filter((item) => item.id !== payment.id);
      });
      setTotalPrice((prevTotalPrice) => {
        let num1 = parseFloat(prevTotalPrice).toFixed(2);
        let num2 = parseFloat(payment.amount).toFixed(2);
        return parseFloat(num1) + parseFloat(num2);
      });
    }
  };

  // You can create a new component or function to render the details for clarity.
  const CartDetails = () => (
    <div>
      <h4>Details from Cart</h4>
      {/* Render cart details here */}
    </div>
  );
  if (cart.length === 0) {
    // Return null or some other placeholder when cart is empty
    return null;
  }

  const handleDeliveryChange = (event) => {
    localStorage.removeItem("shipvia");
    setDeliveryOption((option) => event.target.value);
    
    setDeliveryDate('')
    setSelectedDateOption('')
    // if (event.target.value === 'Delivery') {
    //   const newPayment = {
    //     id: (Math.floor(Math.random() * (500 - 1 + 1)) + 1),
    //     method: 'Delivery',
    //     amount: deliveryFee,
    //     info: 'Delivery',
    //     date: new Date().toISOString().slice(0, 19).replace('T', ' ')
    //   };
    //   setPayments(prevPayments => [...prevPayments, newPayment]);
    // }
  };

  const handleDeliveryDateChange = (event) => {
    setDeliveryDate((prevDate) => event.target.value);
  };

  const handleSelectDeliveryDateChange = (event) => {
    //const selectedIndex = event.target.selectedIndex;
    //const selectedOptionText = event.target.options[selectedIndex].text;
    setSelectedDateOption((prevValue) => {
      return event.target.value;
    });
  };

  const subAccountChangeHandler = (event) => {
    setSubAccount((prevValue) => {
      return event.target.value;
    });
  };
  const handleCarListChange = (event) => {
    setSelectedCarList((prevValue) => event.target.value);
  };
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCarSelection = (carNo,carDes) => {
    console.log(carNo)
    setSelectedCarList(carNo);
    setSearchTerm(`${carNo}: ${carDes}`)
    // setInputCarValue(`${carNo}: ${carDes}`);
    setIsDropdownOpen(false);
  };
  const filteredCarLists = carLists?.filter(
    (data) =>
      data?.CarNo?.toString()?.includes(searchTerm) ||
      data?.CarDesc?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  const handleQuantityChange = (e, cartItem) => {
    console.log(cartItem);
    const newQuantity = Number(e.target.value);
    setCheckPrice(cartItem.price);
    const increasedPrice = (newQuantity - cartItem.quantity) * cartItem.Price;
    // const increasedPrice = (newQuantity - cartItem.newquantity ? cartItem.newquantity : cartItem.quantity) * cartItem.Price;
    let prevInreasedPrice = 0;
    if (localStorage.getItem("balance") == "0") {
      if (localStorage.getItem("increasedPrice") != null) {
        prevInreasedPrice = parseFloat(localStorage.getItem("increasedPrice"));
        localStorage.removeItem("increasedPrice");
      }
      localStorage.setItem(
        "increasedPrice",
        parseFloat(increasedPrice) + prevInreasedPrice
      );
      setIsPaymentButtonVisible((val) => true);
    } else {
      console.log("here is a payment button");
      setTotalPrice(parseFloat(totalPrice) + parseFloat(increasedPrice));
    }
    setCart((prevCart) => {
      const updatedCart = prevCart.map((item) =>
        item.StyleDesc === cartItem.StyleDesc
          ? {
              ...item,
              quantity: newQuantity,
              discount:
                newQuantity * item.OriginalPrice - newQuantity * item.price,
            }
          : item
      );
      // Filter out items with quantity of 0
      return updatedCart.filter((item) => item.quantity !== 0);
    });
  };

  const handlePriceCodeChange = (event) => {
    event.preventDefault();
    console.log("inside ");
    console.log(priceCodeData);
    console.log(event.target.value);
    localStorage.setItem("newlySelectedPriceCode", event.target.value);
    console.log(isPasswordPromptVisible);
    setIsPasswordPromptVisible((val) => true);
  };

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    // Replace this with your actual password validation logic
    const enteredPassword = event.target.password.value;
    setPassword((val) => event.target.password.value);
    if (enteredPassword === "3486") {
      setEditedDeliveryFee((val) => deliveryFee);
      setIsDeliveryFeeEditing((val) => true);
      const newPriceCode = localStorage.getItem("newlySelectedPriceCode");
      // setDropdownValue(newPriceCode);
      // const selectedPriceCode = parseInt(event.target.value, 10);
      setPriceCode(newPriceCode);
      refreshList();
      setIsPasswordPromptVisible(false);
      setPassword("");
      setDisplayPasswordMsg(false);
    } else {
      setPasswordCorrect(false); // Set password correctness to false
      setDisplayPasswordMsg(true);
    }
  };

  const handleClosePasswordPrompt = () => {
    setIsPasswordPromptVisible(false);
    setDisplayPasswordMsg(false);
  };

  const handlePasswordPrompt = () => {
    setIsPasswordPromptVisible(true);
    setPasswordCorrect(true); // Reset the password correctness state
  };

  const handleCloseDeliveryModal = () => {
    setShouldDeliveryModalVisible(false);
  };

  const handleAddNewAddress = (item = "") => {
    if (item !== "") {
      setTempAddress1((val) =>
        cart[0].ShipAdrs1 ? cart[0].ShipAdrs1 : item.Address1
      );
      setTempAddress2((val) =>
        cart[0].ShipAdrs2 ? cart[0].ShipAdrs2 : item.Address2
      );
      setTempCity((val) => (cart[0].ShipCity ? cart[0].ShipCity : item.City));
      setTempState((val) =>
        cart[0].ShipState ? cart[0].ShipState : item.State
      );
      setTempZipCode((val) =>
        cart[0].ShipZip ? cart[0].ShipZip : item.ZipCode
      );
    }
    setShouldDeliveryModalVisible(false);
    setEnterNewAddress(true);
  };

  const handleEnterAddress = () => {
    setShouldDeliveryModalVisible((val) => false);
    setEnterNewAddress((val) => true);
    setIsNewCustomerAddress((val) => true);
  };

  const handleCloseNewAddressPrompt = () => {
    setShouldDeliveryModalVisible(true);
    setEnterNewAddress(false);
  };

  const handleAddNewCustomerAddress = () => {
    setUpdateTheAdress(true); //
    if (tempZipCode.length < 5 && tempZipCode.length > 0) {
      setAlertText("Please Enter 5 digit Zipcode");
      setShowAlert(true);

      return;
    } else {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}DeliveryZipCode/GetZipCodeData/${tempZipCode}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response?.status === 401) {
            history.replace("/login/");
          }
          return response.json();
        })
        .then((data) => {
          if (!data) {
            setShouldDeliveryOptionVisible((val) => false);
            setAlertText(
              "We don’t delivery to this zip code, please select a different delivery method or a different zip code."
            );
            // alert(
            //   "We don’t delivery to this zip code, please select a different delivery method or a different zip code."
            // );
            setShouldDeliveryModalVisible(false);
            setEnterNewAddress((val) => false);
            setShowAlert(true);
          } else {
            setShouldDeliveryOptionVisible((val) => true);
            setShouldDeliveryModalVisible(false);
            setEnterNewAddress(false);
            console.log(tempZipCode);
            fetch(
              process.env.REACT_APP_API_ENDPOINT +
                "DeliveryZipCode/GetZipCodeData/" +
                tempZipCode,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
              .then((response) => {
                if (response?.status === 401) {
                  history.replace("/login/");
                }
                return response.json();
              })
              .then((data) => {
                if (data.length > 0) {
                  //setSelectedDateOption((prevVal) => data[0].Date);
                  setDeliveryAreaId((id) => data[0].DeliveryAreaId);
                  const futureDatesData = data.filter((item) =>
                    isFutureDate(item.Date)
                  );
                  // const futureDatesData = data.filter((item) =>{
                  //   const parts = item.Date.split('/');
                  //   const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
                  //   item.Date = formattedDate;
                  //   return isFutureDate(formattedDate);

                  // });
                  // if(updateTheAddress){
                  // }
                  setDateOptions((prevOptions) => futureDatesData);
                }
              });
          }
        })
        .catch(() => {
          setShouldDeliveryOptionVisible((val) => false);
        });
    }
  };

  const handleSaveNewCustomerAddress = () => {
    if (tempZipCode.length < 5 && tempZipCode.length > 0) {
      setAlertText("Please Enter 5 digit Zipcode");
      setShowAlert(true);
      // alert("Please Enter 5 digit Zipcode");
      return;
    } else {
      const newAddress = {
        CustomerID: cusId,
        Address1: tempAddress1,
        Address2: tempAddress2,
        City: tempCity,
        State: tempState,
        ZipCode: tempZipCode,
      };
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "DeliveryZipCode/IsZipCodeValid/" +
          tempZipCode,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response?.status === 401) {
            history.replace("/login/");
          }
          return response.json();
        })
        .then((data) => {
          if (!data) {
            setAlertText(
              "We don’t delivery to this zip code, please select a different delivery method or a different zip code."
            );
            // alert(
            //   "We don’t delivery to this zip code, please select a different delivery method or a different zip code"
            // );
            setShouldDeliveryModalVisible(false);
            setEnterNewAddress(true);
            setShowAlert(true);
          } else {
            fetch(
              process.env.REACT_APP_API_ENDPOINT +
                "Customer/SaveCustomerNewAddress",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(newAddress),
              }
            ).then((response) => {
              if (response?.status === 401) {
                history.replace("/login/");
              }
            });
            setShouldDeliveryModalVisible(false);
            setEnterNewAddress(false);
          }
        });
    }
  };

  const handleGoogleField = (value) => {
    if (value.value.terms.length > 3) {
      const addressSlice = value.value.terms.slice(
        0,
        value.value.terms.length - 3
      );
      console.log(addressSlice);
      setTempAddress1((prevState) =>
        addressSlice.map((term) => term.value).join(", ")
      );
      
      setNewAddressDetails((prevDetails) => ({
        ...prevDetails,
        address1: addressSlice.map((term) => term.value).join(", ")
      }));
      
      setTempCity(
        (prevState) => value.value.terms[value.value.terms.length - 2].value
        );
        setNewAddressDetails((prevDetails) => ({
          ...prevDetails,
          newaddresscity: value.value.terms[value.value.terms.length - 2].value
        }));
        setTempState(
          (prevState) => value.value.terms[value.value.terms.length - 3].value
          );
          setNewAddressDetails((prevDetails) => ({
            ...prevDetails,
            newaddressstate: value.value.terms[value.value.terms.length - 3].value
          }));

      // const element = document.querySelector('. css-1qrxvr1-singleValue');
      // if (element) {
      //     element.innerHTML = ''; // Set inner HTML to empty string
      // }
      // const other = document.querySelector('. css-1jqq78o-placeholder');
      // if (other) {
      //     other.innerHTML = this.state.Address1; // Set inner HTML to empty string
      // }
    } else {
      setTempAddress1((prevState) => value.label);

    }
  };

  const handleNewAddressDetailsChange = (event) => {
    const { name, value } = event.target;

    if (name === "address1") {
      setTempAddress1((val) => value);
    }
    if (name === "address2") {
      setTempAddress2((val) => value);
    }
    if (name === "newaddresscity") {
      setTempCity((val) => value);
    }
    if (name === "newaddressstate") {
      setTempState((val) => value);
    }
    if (name === "newaddresszip") {
      const sanitizedValue = value.replace(/\D/g, "");
      const zipCode = sanitizedValue.slice(0, 5);
      setTempZipCode((val) => zipCode);
    }

    if (name === "newaddresszip") {
      const sanitizedValue = event.target.value.replace(/\D/g, "");
      const zipCode = sanitizedValue.slice(0, 5);
      setNewAddressDetails((prevDetails) => ({
        ...prevDetails,
        [name]: zipCode,
      }));
    } else {
      setNewAddressDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const convertDateFormat = (inputDate) => {
    
    if (oDeliveryDate&& !inputDate) {
      const formattedDate = oDeliveryDate?.split("T")[0];
      return formattedDate;
    } else {
      console.log(inputDate);
      if(inputDate !== ""){
        const parts = inputDate.split("/");
        console.log(parts);
        const parsedDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
        // Format the date as 'YYYY-MM-DD'
       
        const formattedDate = parsedDate.toISOString().split("T")[0];
        return formattedDate;

      }
      // Parse the input date in the format 'DD/MM/YYYY'
    }
  };

  const getTotalDiscount = (cartItems) => {
    console.log(cartItems);
    let TotalDiscount = 0;
    cartItems.forEach((cartItem) => (TotalDiscount += cartItem.discount));
    setTotalDiscount(parseFloat(TotalDiscount).toFixed(2));
    return TotalDiscount;
  };

  const getTotalCartQuantity = (cartItems) => {
    let TotalQuantity = 0;
    cartItems.forEach((cartItem) => (TotalQuantity += cartItem.quantity));
    return TotalQuantity;
  };

  const updateOrderHandler = () => {
    // if (
    //   deliveryFee !== cart[0]?.Freight &&
    //   cart[0]?.Freight !== undefined
    // ) {
      // handleUpdateAddressClick(deliveryFee);
    // }
    const info = {
      Comment: comment,
      Subaccount: subAccount,
      InvoiceNo: cart[0].InvoiceNo,
      ShipVia: deliveryOption,
      ShipAdrs1: tempAddress1 !== null ? tempAddress1 : cart[0]?.ShipAdrs1,
      ShipAdrs2: tempAddress2 !== null ? tempAddress2 : cart[0]?.ShipAdrs2,
      ShipCity: tempCity !== null ? tempCity : cart[0]?.ShipCity,
      ShipState: tempState !== null ? tempState : cart[0]?.ShipCity,
      ShipZip: tempZipCode !== null ? tempZipCode: cart[0]?.ShipZip,
      Freight: deliveryFee !== cart[0].Freight && deliveryOption === "Delivery"? deliveryFee : cart[0].Freight,
      Date: new Date().toISOString().slice(0, 19).replace("T", " "),
      DeliveryDate:
        deliveryOption === "Delivery"
          ? convertDateFormat(selectedDateOption)
          : deliveryDate,
    };

    console.log(info);
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "invoice/UpdatePartialOrder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(info),
    })
      .then((res) => {
        if (res?.status === 401) {
          history.replace("/login/");
        }
        return res.json();
      })
      .then((data) => {
        // fetchOrderDetails()
        // alert(data);
        handleCancelClick()
        return data
      }).then((data) => {
        setGeneralAlertText(data);
        setGeneralAlert(true);
      })
  };

  const handlePriceDoubleClick = (itemName) => {
    setEditingItemName((name) => itemName);
    setIsPricePasswordPromptVisible((val) => true);
  };

  const handlePricePasswordSubmit = (event) => {
    event.preventDefault();
    // Replace this with your actual password validation logic
    const enteredPassword = event.target.password.value;
    if (enteredPassword == "3486") {
      setPassword(enteredPassword);
      setIsPricePasswordPromptVisible(false);
      setDisplayPasswordMsg(false);
    }
  };
  const isPriceEditable = (itemName) => {
    if (password == "3486") {
      return itemName === editingItemName;
    }
  };

  const handlePriceEdit = (newPrice, itemName) => {
    console.log(newPrice, itemName);

    setEditingItemName(null);
    const updatedItems = cart.map((item) =>
      item.name === itemName
        ? { ...item, discount: item.originalPrice - newPrice, price: newPrice }
        : item
    );
    setCart((prevItems) => updatedItems);
    const newTotalPrice = updatedItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    setTotalPrice((val) => newTotalPrice);
    setPassword((val) => "");
    setIsPricePasswordPromptVisible((val) => false);
  };

  const handleInput = (e, itemName) => {
    const editedPrice = parseFloat(e.target.value) || 0;
    const updatedItems = cart.map((item) =>
      item.name === itemName ? { ...item, price: editedPrice } : item
    );
    setCart((prevItems) => updatedItems);
    const newTotalPrice = updatedItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    setTotalPrice(newTotalPrice);
  };

  const closeCarPopup =()=>{
    setShowCarTypePopup(false);
  }

  if (!isOpen) {
    return <></>;
  } else {
    return (
      <>
        {showAlert && (
          <GeneralAlert popupText={alertText} onClose={handleCloseAlert} />
        )}
        {showCarTypePopup && <CarTypePopup onClose={closeCarPopup} 
    setSearchTerm={setSearchTerm } setSelectedCarList={setSelectedCarList}
        
        />}
        <PaymentsContext.Consumer>
          {([payments, setPayments]) => (
            <>
              <div
                style={{
                  // position: 'sticky',
                  top: "2px",
                  right: "5px",
                  // bottom:0,
                  // width: isPlacingOrder ? '90%' : '45%',
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    marginLeft: isPlacingOrder ? "0" : "20px",
                    flexDirection: "column",
                  }}
                >
                  {/* If isPlacingOrder is false, render the cart, otherwise don't */}
                  {!isPlacingOrder && (
                    <div
                      className="classDetailsOuter"
                      style={{
                        flexBasis: "70%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1f1d2b",
                          border: "2px solid  #673F36",
                          padding: "20px",
                          color: "white",
                          borderRadius: "10px",
                          width: "100%",
                          overflowY: "auto",
                          minHeight: 685,
                          height: "100vh",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h4>Order</h4>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <label
                                htmlFor="total"
                                style={{
                                  fontSize: "18px",
                                  color: "#9A6452",
                                  marginRight: "10px",
                                }}
                              >
                                Total
                              </label>
                              {deliveryOption === "Delivery" && (
                                <input
                                  id="total"
                                  readOnly
                                  //value={`$${(parseFloat(getTotalPrice()) + (parseFloat(deliveryFee) * quantityMultiple)).toFixed(2)}`}
                                  value={`$${parseFloat(
                                    getTotalPrice()
                                  ).toFixed(2)}`}
                                  style={{
                                    backgroundColor: "#1f1d2b",
                                    color: "white",
                                    fontSize: "30px",
                                    border: "none",
                                    outline: "none",
                                    margin: 0,
                                    // textAlign: "right",
                                    // flexGrow: 1,
                                    width: "120px",
                                    padding: "0px",
                                  }}
                                />
                              )}
                              {deliveryOption !== "Delivery" && (
                                <input
                                  id="total"
                                  readOnly
                                  value={`$${parseFloat(
                                    getTotalPrice()
                                  ).toFixed(2)}`}
                                  style={{
                                    backgroundColor: "#1f1d2b",
                                    color: "white",
                                    fontSize: "30px",
                                    border: "none",
                                    outline: "none",
                                    // textAlign: "right",
                                    // flexGrow: 1,
                                    margin: 0,
                                    width: "120px",
                                    padding: "0px",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          {/* <br /> */}

                          <hr
                            style={{
                              borderBottom: "2px solid rgb(103, 63, 54)",
                              opacity: "1",
                              margin: 0,
                            }}
                          />
                          <div
                            style={{
                              // marginTop: "20px",
                              backgroundColor: "#1f1d2b",
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "column", // Add this line
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {isPaymentButtonVisible && (
                                <>
                                  <button
                                    onClick={handlePlaceOrder}
                                    style={{
                                      backgroundColor: "#E8C48A",
                                      color: "black",
                                      border: "none",
                                      borderRadius: "6px",
                                      padding: "4px 10px",
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      width: "60%", // Add this line
                                    }}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#d6c3a2"; // Slightly darker shade on hover
                                      e.currentTarget.style.transform =
                                        "scale(1.05)"; // Slight scale for a "pop" effect
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#E8C48A";
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                    }}
                                  >
                                    Continue to Payment
                                  </button>

                                  <button
                                    onClick={handleCancelClick}
                                    style={{
                                      background: "none",
                                      color: "#9A6447",
                                      textDecoration: "underline",
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      width: "30%", // Add this line
                                    }}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.color = "white"; // Slightly darker shade on hover
                                      e.currentTarget.style.transform =
                                        "scale(1.05)"; // Slight scale for a "pop" effect
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.color = "#9A6447";
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {!isPaymentButtonVisible && (
                                <>
                                  <button
                                    onClick={updateOrderHandler}
                                    style={{
                                      backgroundColor: "#E8C48A",
                                      color: "black",
                                      border: "none",
                                      borderRadius: "6px",
                                      padding: "4px 10px",
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      width: "60%", // Add this line
                                    }}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#d6c3a2"; // Slightly darker shade on hover
                                      e.currentTarget.style.transform =
                                        "scale(1.05)"; // Slight scale for a "pop" effect
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#E8C48A";
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                    }}
                                  >
                                    Update
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    style={{
                                      background: "none",
                                      color: "#9A6447",
                                      textDecoration: "underline",
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      width: "30%", // Add this line
                                    }}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.color = "white"; // Slightly darker shade on hover
                                      e.currentTarget.style.transform =
                                        "scale(1.05)"; // Slight scale for a "pop" effect
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.color = "#9A6447";
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                            </div>
                            {/* {deliveryOption === "Delivery" && (
                              <button
                                onClick={handleUpdateAddressClick}
                                style={{
                                  backgroundColor: "#E8C48A",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "6px",
                                  padding: "4px 10px",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  width: "100%", // Add this line
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#d6c3a2"; // Slightly darker shade on hover
                                  e.currentTarget.style.transform =
                                    "scale(1.05)"; // Slight scale for a "pop" effect
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#E8C48A";
                                  e.currentTarget.style.transform = "scale(1)";
                                }}
                              >
                                Update Address
                              </button>
                            )} */}
                          </div>

                          {customerData.map((item, index) => (
                            <>
                              <hr
                                style={{
                                  marginTop: 0,
                                  borderBottom: "2px solid rgb(103, 63, 54)",
                                  opacity: "1",
                                }}
                              />
                              <div
                                key={index}
                                style={{
                                  cursor: "pointer",
                                  border: "1px solid #e7d2b7",
                                  borderRadius: "10px",
                                  padding: "5px",
                                  width: "100%",
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#e7d2b7",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Shipping Address:
                                  </h5>
                                  <button
                                    style={{
                                      backgroundColor: "#E8C48A",
                                      color: "black",
                                      border: "none",
                                      borderRadius: "6px",
                                      padding: "4px 10px",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      marginLeft: "35%",
                                    }}
                                    onClick={() => handleAddNewAddress(item)}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#d6c3a2"; // Slightly darker shade on hover
                                      e.currentTarget.style.transform =
                                        "scale(1.05)"; // Slight scale for a "pop" effect
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#E8C48A";
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                    }}
                                  >
                                    Update
                                  </button>
                                </div>

                                <p>
                                  {tempAddress1 !== null && updateTheAddress
                                    ? tempAddress1
                                    : cart[0].ShipAdrs1
                                    ? cart[0].ShipAdrs1
                                    : item.Address1}
                                  {(tempAddress1 !== null ||
                                    item.Address1 ||
                                    cart[0].ShipAdrs1) &&
                                    ", "}
                                  {tempAddress2 !== null && updateTheAddress
                                    ? tempAddress2
                                    : cart[0].ShipAdrs2
                                    ? cart[0].ShipAdrs2
                                    : item.Address2}
                                  {(tempAddress2 !== null ||
                                    item.Address2 ||
                                    cart[0].ShipAdrs2) &&
                                    ", "}
                                  {tempCity !== null && updateTheAddress
                                    ? tempCity
                                    : cart[0].ShipCity
                                    ? cart[0].ShipCity
                                    : item.City}
                                  {(tempCity !== null ||
                                    item.City ||
                                    cart[0].ShipCity) &&
                                    ", "}
                                  {tempState !== null && updateTheAddress
                                    ? tempState
                                    : cart[0].ShipState
                                    ? cart[0].ShipState
                                    : item.State}
                                  {(tempState !== null ||
                                    item.State ||
                                    cart[0].ShipState) &&
                                    ", "}
                                  {tempZipCode !== null && updateTheAddress
                                    ? tempZipCode
                                    : cart[0].ShipZip
                                    ? cart[0].ShipZip
                                    : item.ZipCode}
                                </p>
                                {/* <p><strong>Phone:</strong> {item.Phone1}</p>
                                <p><strong>Email:</strong> {item.EmailAdrs}</p> */}
                              </div>
                            </>
                          ))}
                          <hr
                            style={{
                              borderBottom: "2px solid rgb(103, 63, 54)",
                              opacity: "1",
                            }}
                          />
                          <div
                            style={{
                              maxHeight: "300px", // you can adjust the value according to your needs
                              overflow: "auto",
                              WebkitOverflowScrolling: "touch",
                            }}
                          >
                            <table style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th style={{ textAlign: "center" }}>Qty</th>
                                  <th>Price</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className="productNamePrice">
                                {cart.map((item) => (
                                  <tr key={count++}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* <img
                                          className="card-img-top rounded-circle"
                                          src={matzo}
                                          alt="Product"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            marginRight: "10px",
                                          }}
                                        /> */}
                                        <div>
                                          <div>{item.name}</div>
                                          <div>
                                            <small>
                                              $
                                              {item.price == null
                                                ? 0.0
                                                : item.price.toFixed(2)}
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td style={{ padding: "0px" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="number"
                                          value={
                                            item.newquantity
                                              ? item.quantity + item.newquantity
                                              : item.quantity
                                          }
                                          onChange={(e) =>
                                            handleQuantityChange(e, item)
                                          }
                                          style={{
                                            width: "70px",
                                            // height: "40px",

                                            textAlign: "left",
                                            padding: "5px 8px",
                                            backgroundColor: "#1f1d2b",
                                            color: "white",
                                            borderRadius: "10px",
                                            border: "solid 1px #80818A",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td style={{ minWidth: "50px" }}>
                                      $
                                      <span
                                        style={{
                                          border: isPriceEditable(item.name)
                                            ? "1px solid white"
                                            : "",
                                          padding: isPriceEditable(item.name)
                                            ? "5px 1px"
                                            : "",
                                          borderRadius: isPriceEditable(
                                            item.name
                                          )
                                            ? "10px"
                                            : "",
                                        }}
                                        onDoubleClick={() =>
                                          handlePriceDoubleClick(item.name)
                                        }
                                        contentEditable={isPriceEditable(
                                          item.name
                                        )}
                                        onBlur={(e) =>
                                          handlePriceEdit(
                                            parseFloat(e.target.innerText),
                                            item.name
                                          )
                                        }
                                        onInput={(e) =>
                                          handleInput(e, item.name)
                                        }
                                      >
                                        {/* {item.price === 1 ? (item.price + item.quantity).toFixed(2): (item.price * item.quantity).toFixed(2)} */}
                                        {(item.price * item.quantity).toFixed(
                                          2
                                        )}
                                      </span>
                                      <small
                                        style={{
                                          display: "block",
                                          textAlign: "center",
                                          color: "gray",
                                        }}
                                      >
                                        ${item.discount.toFixed(2)}
                                      </small>
                                    </td>
                                    <td
                                      onClick={() => removeFromCart(item)}
                                      style={{
                                        cursor: "pointer",
                                        color: "#C46A49",
                                      }}
                                    >
                                      X
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tr>
                                <th>
                                  Total Quantity: {getTotalCartQuantity(cart)}
                                </th>
                              </tr>
                            </table>
                          </div>

                          {deliveryOption === "Delivery" && (
                            <>
                              <hr
                                style={{
                                  borderBottom: "2px solid rgb(103, 63, 54)",
                                  opacity: "1",
                                  margin: "5px",
                                }}
                              />
                              <div>
                                <span style={{ marginLeft: "20px" }}>
                                  Total Delivery Charges
                                </span>
                                {/* <span style={{ marginLeft: '180px' }}>${(parseFloat(deliveryFee) * quantityMultiple).toFixed(2)}</span> */}
                                <span
                                  style={{ marginLeft: "180px" }}
                                  onDoubleClick={handleDeliveryFeeDoubleClick}
                                >
                                  $
                                  {isDeliveryFeeEditing ? (
                                    <input
                                      type="number"
                                      value={editedDeliveryFee}
                                      onChange={handleDeliveryFeeChange}
                                      onBlur={handleDeliveryFeeBlur}
                                      autoFocus
                                      style={{
                                        width: "70px",
                                        // height: "40px",
                                        textAlign: "left",
                                        padding: "5px 8px",
                                        backgroundColor: "#1f1d2b",
                                        color: "white",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  ) : (
                                    (
                                      parseFloat(deliveryFee) +
                                      (quantityMultiple > 1
                                        ? (quantityMultiple - 1) * 10
                                        : 0)
                                    ).toFixed(2)
                                  )}
                                </span>
                              </div>
                              <hr
                                style={{
                                  borderBottom: "2px solid rgb(103, 63, 54)",
                                  opacity: "1",
                                  margin: "5px",
                                }}
                              />
                            </>
                          )}
                          <div
                            style={{
                              // marginTop: "20px",
                              backgroundColor: "#1f1d2b",
                              color: "white",
                              borderRadius: "10px",
                              fontSize: "14px",
                            }}
                          >
                            <p className="orderNote">Order Note</p>
                            <textarea
                              style={{
                                width: "100%",
                                height: "75px",
                                backgroundColor: "#1f1d2b",
                                color: "white",
                                borderRadius: "6px",
                                resize: "none",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                                color: "white",
                              }}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </div>
                          <div
                            style={{
                              // marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Price Code: </label>
                            <select
                              value={PriceCodeFromContext}
                              onChange={(e) => handlePriceCodeChange(e)}
                              style={{
                                margin: 0,
                                width: "180px",
                                // height: "50px",
                                padding: "7px 10px",
                                // textAlign: "center",
                                backgroundColor: "#1f1d2b",
                                //color: "#80818A",
                                color: "white",
                                borderRadius: "6px",
                                border: "solid 1px #80818A",
                              }}
                            >
                              {priceCodeData.map((data) => (
                                <option key={data.Price} value={data.Price}>
                                  {data.Price + " : " + data.PriceDesc}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            style={{
                              // marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Order Type</label>
                            <select
                              value={
                                localStorage.getItem("shipvia") == null ||
                                localStorage.getItem("shipvia") === ""
                                  ? deliveryOption
                                  : localStorage.getItem("shipvia")
                              }
                              onChange={handleDeliveryChange}
                              style={{
                                marginBottom: 0,
                                width: "180px",
                                // height: "50px",
                                padding: "7px 10px",
                                backgroundColor: "#1f1d2b",
                                //color: "#80818A",
                                color: "white",
                                borderRadius: "6px",
                                border: "solid 1px #80818A",
                              }}
                            >
                              <option value="" disabled selected>
                                Please Choose
                              </option>
                              <option value="Take Away">Take Away</option>
                              <option value="Appointment">Appointment</option>
                              {shouldDeliveryOptionVisible && (
                                <option
                                  value="Delivery"
                                  disabled={!isValidZipCodeForDelivery}
                                  title={
                                    !isValidZipCodeForDelivery
                                      ? "Zip Code Not In List"
                                      : "Zip Code is in the List"
                                  }
                                >
                                  Delivery
                                </option>
                              )}
                            </select>
                          </div>
                          {(!isValidZipCodeForDelivery ||
                            deliveryOption !== "Delivery") && (
                            <div
                              style={{
                                // marginTop: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <label>Date</label>
                              {/* <DatePicker
                                selected={deliveryDate}
                                onChange={handleDeliveryDateChange}
                                dateFormat="MM/dd/yyyy" // Set the desired date format
                              /> */}
                              <input
                                type="date"
                                value={deliveryDate}
                                onChange={handleDeliveryDateChange}
                                min={new Date().toISOString().split("T")[0]} // Allows selection of today's date or future dates
                                style={{
                                  marginBottom: 0,
                                  width: "180px",
                                  // height: "50px",
                                  padding: "7px 10px",
                                  backgroundColor: "#1f1d2b",
                                  //color: "#80818A",
                                  color: "white",
                                  borderRadius: "6px",
                                  border: "solid 1px #80818A",
                                  colorScheme: "dark",
                                }}
                              />
                            </div>
                          )}
                          {isValidZipCodeForDelivery &&
                            deliveryOption === "Delivery" && (
                              <div
                                style={{
                                  // marginTop: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label>Delivery Dates</label>
                                <select
                                  // value={selectedDateOption}
                                  style={{
                                    width: "180px",
                                    // height: "50px",
                                    marginBottom: 0,
                                    padding: "5px 10px",
                                    backgroundColor: "#1f1d2b",
                                    color: "#80818A",
                                    borderRadius: "6px",
                                    border: "solid 1px #80818A",
                                  }}
                                  onChange={handleSelectDeliveryDateChange}
                                >
                                  {oDeliveryDate !== null && !selectedDateOption &&
                                  oDeliveryDate !==
                                    undefined ? //   {formattedPropDate} // <option value={oDeliveryDate} selected>
                                  // </option>
                                  null : (
                                    <option value="" disabled selected>
                                      Select Date
                                    </option>
                                  )}
                                  {/* {oDeliveryDate !== null &&
                                    oDeliveryDate !== undefined &&
                                    formattedPropDate &&
                                    !dateOptions.some((option) => {
                                      const parts = option.Date.split("/");
                                      // Rearrange the parts to get the desired format
                                      const formattedDate =
                                        parts[1] +
                                        "/" +
                                        parts[0] +
                                        "/" +
                                        parts[2];
                                      
                                      return (
                                        formattedDate === formattedPropDate
                                      );
                                    }) && (
                                      <option value={oDeliveryDate} selected>
                                        {formattedPropDate}
                                      </option>
                                    )} */}
                                  {dateOptions.map((option, index) => {
                                    const parts = option.Date.split("/");
                                    // Rearrange the parts to get the desired format
                                    const formattedDate =
                                      parts[1] +
                                      "/" +
                                      parts[0] +
                                      "/" +
                                      parts[2];
                                    const isSelected =
                                      formattedPropDate == formattedDate;
                                      
                                    return (
                                      <option
                                        key={index}
                                        value={formattedDate}
                                        title={option.Description}
                                        selected={isSelected ? true : false} // Set selected to true only if it matches
                                      >
                                        {formattedDate +
                                          " : " +
                                          option.Description.slice(0, 20)}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}
                          <div
                            style={{
                              // marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Sub Account</label>
                            <input
                              type="text"
                              value={subAccount}
                              onChange={subAccountChangeHandler}
                              style={{
                                marginBottom: 0,
                                width: "180px",
                                // height: "50px",
                                padding: "5px 10px",
                                backgroundColor: "#1f1d2b",
                                color: "#80818A",
                                borderRadius: "6px",
                                border: "solid 1px #80818A",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Car Type</label>
                            <div style={{ position: 'relative' }}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            onFocus={toggleDropdown}
            onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)}
            style={{
              margin: 0,
              width: "143px",
              padding: "7px 10px",
              backgroundColor: "#1f1d2b",
              color: "white",
              borderRadius: "6px",
              border: "solid 1px #80818A",
            }}
          />
          {isDropdownOpen && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                backgroundColor: "#1f1d2b",
                color: "white",
                borderRadius: "6px",
                border: "solid 1px #80818A",
                zIndex: 9999,
              }}
            >
              {filteredCarLists.map((data) => (
                <div
                  key={data.ID}
                  onClick={() => handleCarSelection(data.CarNo,data.CarDesc)}
                  style={{ padding: '5px 10px', cursor: 'pointer' }}
                >
                  {data.CarNo + " : " + data.CarDesc}
                </div>
              ))}
            </div>
          )}
          <button
                                    onClick={()=>setShowCarTypePopup(true)}
                                    style={{
                                      backgroundColor: "#E8C48A",
                                      color: "black",
                                      border: "none",
                                      borderRadius: "6px",
                                      padding: "4px 10px",
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      marginLeft: "6px", // Add this line
                                    }}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#d6c3a2"; // Slightly darker shade on hover
                                      e.currentTarget.style.transform =
                                        "scale(1.05)"; // Slight scale for a "pop" effect
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.backgroundColor =
                                        "#E8C48A";
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                    }}
                                  >
                                    +
                                  </button>
        </div>
                            {/* <input
                              type="text"
                              placeholder="Search car..."
                              value={searchTerm}
                              onChange={handleSearchTermChange}
                              style={{
                                margin: 0,
                                width: "180px",
                                padding: "7px 10px",
                                backgroundColor: "#1f1d2b",
                                color: "white",
                                borderRadius: "6px",
                                border: "solid 1px #80818A",
                              }}
                            />
                            <select
                              value={selectedCarList}
                              onChange={(e) => handleCarListChange(e)}
                              style={{
                                margin: 0,
                                width: "180px",
                                // height: "50px",
                                padding: "7px 10px",
                                // textAlign: "center",
                                backgroundColor: "#1f1d2b",
                                //color: "#80818A",
                                color: "white",
                                borderRadius: "6px",
                                border: "solid 1px #80818A",
                              }}
                            >
                             
                              {/* <option value={null} selected disabled>
                                Select
                              </option> */}
                              {/* <option value={null}>Not Selected</option>
                              {searchTerm === "" &&
                                carLists.map((data) => (
                                  <option key={data.ID} value={data.CarNo}>
                                    {data.CarNo + " : " + data.CarDesc}
                                  </option>
                                ))}
                              {searchTerm !== "" &&
                                filteredCarLists.map((data) => (
                                  <option key={data.ID} value={data.CarNo}>
                                    {data.CarNo + " : " + data.CarDesc}
                                  </option>
                                ))}
                            </select> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isPlacingOrder && (
                    <div
                      style={{
                        //display: "flex",
                        width: "425px",
                        //justifyContent: "flex-end",
                        // marginRight: "230px",
                        zIndex: 6,
                      }}
                    >
                      <PlaceOrder
                        payingBalance={false}
                        setZeroOutStanding={setZeroOutStanding}
                        existingBalance={balance}
                        popupHandler={popupHandler}
                        alertHandler={alertHandler}
                        totalDiscount={totalDiscount}
                        handleShowAlert={handleShowAlert}
                        onCancel={handleCancelOrder}
                        isToggleActive={isToggleActive}
                        onToggleChange={handleToggleChange}
                        getTotalPrice={totalPrice}
                        style={{
                          //flexBasis: "10%",
                          position: "fixed",
                          marginLeft: "20px",
                          backgroundColor: "#1f1d2b",
                          color: "white",
                          padding: "20px",
                          borderRadius: "10px",
                          marginTop: "-200px",
                        }}
                        comment={comment}
                        subaccount={subAccount}
                        setSubAccount={setSubAccount}
                        deliveryOption={deliveryOption}
                        setDeliveryOption={setDeliveryOption}
                        total={totalPrice}
                        deliveryDate={
                          deliveryOption === "Delivery"
                            ? convertDateFormat(selectedDateOption)
                            : deliveryDate
                        }
                        setDeliveryDate={setDeliveryDate}
                        setSelectedDateOption={setSelectedDateOption}
                        newAddressDetails={newAddressDetails}
                        deliveryFee={
                          deliveryFee +
                          (quantityMultiple > 1
                            ? (quantityMultiple - 1) * 10
                            : 0)
                        }
                      />
                    </div>
                  )}

                  {isDeliveryFeePasswordPromptVisible && (
                    <div
                      style={{
                        backgroundColor: "#1f1d2b",
                        color: "white",
                        padding: "15px",
                        height: "30%",
                        width: "35%",
                        borderRadius: "10px",
                        border: "solid 2px #673F36",
                        position: "fixed",
                        top: "50%",
                        right: "5%",
                        // transform: "translate(-50%, -50%)",
                        backgroundColor: "#1f1d2b",
                        zIndex: 4,
                      }}
                      className="password-prompt"
                    >
                      <form onSubmit={handleDeliveryFeePasswordSubmit}>
                        <h5>Enter Password</h5>
                        <hr
                          style={{
                            border: "1px solid #673F36",
                            opacity: "1",
                          }}
                        />
                        <label>
                          Password:
                          <input
                            style={{ marginLeft: "15px" }}
                            autoFocus
                            type="password"
                            name="password"
                          />
                        </label>
                        <button
                          style={{
                            backgroundColor: "#e7d2b7",
                            color: "black",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px 5px 5px 15px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          style={{
                            backgroundColor: "#e7d2b7",
                            color: "black",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          onClick={handleCloseDeliveryFeePasswordPrompt}
                        >
                          Cancel
                        </button>
                      </form>
                      {/* Display a message if the password is incorrect */}
                      {!isPasswordCorrect && isDisplayPasswordMsg && (
                        <p>Wrong password. Please try again.</p>
                      )}
                    </div>
                  )}
                  {isPricePasswordPromptVisible && (
                    <div
                      style={{
                        backgroundColor: "#1f1d2b",
                        color: "white",
                        padding: "15px",
                        height: "30%",
                        width: "35%",
                        borderRadius: "10px",
                        border: "solid 2px #673F36",
                        position: "fixed",
                        top: "50%",
                        right: "5%",
                        // transform: "translate(-50%, -50%)",
                        backgroundColor: "#1f1d2b",
                        zIndex: 4,
                      }}
                      className="password-prompt"
                    >
                      <form onSubmit={handlePricePasswordSubmit}>
                        <h5>Enter Password</h5>
                        <hr
                          style={{
                            border: "1px solid #673F36",
                            opacity: "1",
                          }}
                        />
                        <label>
                          Password:
                          <input
                            style={{ marginLeft: "15px" }}
                            autoFocus
                            type="password"
                            name="password"
                          />
                        </label>
                        <button
                          style={{
                            backgroundColor: "#e7d2b7",
                            color: "black",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px 5px 5px 15px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          style={{
                            backgroundColor: "#e7d2b7",
                            color: "black",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          onClick={() => setIsPricePasswordPromptVisible(false)}
                        >
                          Cancel
                        </button>
                      </form>
                      {!isPasswordCorrect && isDisplayPasswordMsg && (
                        <p>Wrong password. Please try again.</p>
                      )}
                    </div>
                  )}

                  {isPasswordPromptVisible && (
                    <div
                      style={{
                        backgroundColor: "#1f1d2b",
                        color: "white",
                        padding: "15px",
                        height: "30%",
                        width: "35%",
                        borderRadius: "10px",
                        border: "solid 2px #673F36",
                        position: "fixed",
                        top: "50%",
                        right: "5%",
                        // transform: "translate(-50%, -50%)",
                        backgroundColor: "#1f1d2b",
                        zIndex: 4,
                      }}
                      className="password-prompt"
                    >
                      <form onSubmit={handlePasswordSubmit}>
                        <h5>Enter Password</h5>
                        <hr
                          style={{
                            border: "1px solid #673F36",
                            opacity: "1",
                          }}
                        />
                        <label>
                          Password:
                          <input
                            style={{ marginLeft: "15px" }}
                            autoFocus
                            type="password"
                            name="password"
                          />
                        </label>
                        <button
                          style={{
                            backgroundColor: "#e7d2b7",
                            color: "black",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px 5px 5px 15px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          style={{
                            background: "none",
                            color: "white",
                            textDecoration: "underline",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          onClick={handleClosePasswordPrompt}
                        >
                          Cancel
                        </button>
                      </form>
                      {/* Display a message if the password is incorrect */}
                      {!isPasswordCorrect && isDisplayPasswordMsg && (
                        <p>Wrong password. Please try again.</p>
                      )}
                    </div>
                  )}
                </div>
                {isPlacingOrder && (
                  <div
                    style={{
                      // marginLeft: '-105%',
                      width: "",
                      backgroundColor: "#1f1d2b",
                      color: "white",
                      padding: "30px",
                      border: "2px solid rgb(103, 63, 54)",
                      borderRadius: "12px",
                      marginRight: "10px",
                      height: "fit-content",
                      zIndex: 5,
                    }}
                  >
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                        borderBottom: "2px solid rgb(103, 63, 54)",
                        paddingBottom: "18px",
                      }}>

                    <h4
                      
                    >
                      Order Confirmation
                      <small
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          display: "block",
                        }}
                      >
                        {selectedPriceCode.split(":")[1]}
                      </small>
                    </h4>
                    <h4
                      
                    >
                      Balance
                      <small
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          display: "block",
                        }}
                      >
                        ${parseFloat(balance).toFixed(2)}
                      </small>
                    </h4>
                    </div>
                    {/* <hr
      style={{
        color: "white",
        backgroundColor: "rgb(103, 63, 54)",
        height: 3,
        border: "none",
        marginBottom: "20px",
      }}
    /> */}
                    <div
                      className="OrderConformationHead"
                      style={{
                        maxHeight: "400px",
                        width: "400px",

                        overflow: "auto",
                        // border: "1px solid #343245",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <table style={{ width: "100%", borderSpacing: "0" }}>
                        <thead>
                          <tr style={{ fontWeight: "bold" }}>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart.map((item, index) => (
                            <tr
                            // style={{
                            //   backgroundColor:
                            //     index % 2 === 0
                            //       ? "#272635"
                            //       : "transparent",
                            // }}
                            >
                              <td>{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>
                                ${(item.price * item.quantity).toFixed(2)}
                              </td>
                            </tr>
                          ))}
                          {deliveryOption === "Delivery" && (
                            <>
                              <tr>
                                <td>
                                  <hr />
                                </td>
                                <td>
                                  <hr />
                                </td>
                                <td>
                                  <hr />
                                </td>
                              </tr>
                              <tr>
                                <td>{deliveryOption}</td>
                                <td></td>
                                <td>
                                  $
                                  {(
                                    parseFloat(deliveryFee) +
                                    (quantityMultiple > 1
                                      ? (quantityMultiple - 1) * 10
                                      : 0)
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {payments.length > 0 && (
                      <div
                        style={{
                          //maxHeight: "400px",
                          //overflow: "auto",
                          border: "1px solid #343245",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                      >
                        <table style={{ width: "100%", borderSpacing: "0" }}>
                          <thead>
                            <tr style={{ fontWeight: "bold" }}>
                              <th>Type</th>
                              <th>Info</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#272635" : "transparent",
                                }}
                              >
                                <td>{item.method}</td>
                                <td>{item.info}</td>
                                <td>
                                  $
                                  {item.amount == null
                                    ? 0.0
                                    : parseFloat(item.amount).toFixed(2)}
                                </td>
                                <td>
                                  {
                                    new Date(item.date)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </td>
                                <td
                                  onClick={() => removePayment(item)}
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  X
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div
                      className="OrderConfirmationPrice"
                      style={{
                        // marginTop: "130px",
                        marginTop: "14px",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                        justifyContent: "flex-end",
                        width: "93%",
                      }}
                    >
                      <label htmlFor="total" style={{ fontSize: "14px" }}>
                        Total
                      </label>
                      <span
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >{`$${savedTotalPrice}`}</span>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label htmlFor="total" style={{ fontSize: "16px" }}>
                        Total Quantity:
                      </label>
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {getTotalCartQuantity(cart)}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label htmlFor="total" style={{ fontSize: "16px" }}>
                        Total Discount:
                      </label>
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        ${parseFloat(getTotalDiscount(cart)).toFixed(2)}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label htmlFor="total" style={{ fontSize: "24px" }}>
                        Total Outstanding:
                      </label>
                      <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                        {zeroOutStanding ? "$0.00" : `$${totalPrice}`}
                      </span>
                    </div>
                  </div>
                )}
                {shouldDeliveryModalVisible &&
                  isValidZipCodeForDelivery &&
                  deliveryOption === "Delivery" && (
                    <div
                      style={{
                        backgroundColor: "#1f1d2b",
                        color: "white",
                        //padding: "5px",

                        height: "25%",
                        borderRadius: "10px",
                        zIndex: 20,
                      }}
                      className="password-prompt"
                    >
                      {customerData.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "rgb(31, 29, 43)",
                            border: "solid 2px #673F36",
                            borderRadius: "10px",
                            padding: "25px",
                            width: "350px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {item.Address1 == "" && (
                            <>
                              <h4>No Address Available for Delivery!!!</h4>
                              <hr />
                              <button
                                style={{
                                  backgroundColor: "#E8C48A",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "6px",
                                  padding: "4px 10px",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  width: "95%", // Add this line
                                }}
                                onClick={handleEnterAddress}
                              >
                                Enter Address
                              </button>
                              <button
                                style={{
                                  backgroundColor: "#E8C48A",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "6px",
                                  padding: "4px 10px",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  width: "95%", // Add this line
                                }}
                                onClick={handleCloseDeliveryModal}
                              >
                                Cancel
                              </button>
                            </>
                          )}
                          {item.Address1 != "" && (
                            <>
                              <h4>
                                Do you want delivery on the following address?
                              </h4>
                              <hr
                                style={{
                                  borderBottom: "2px solid rgb(103, 63, 54)",
                                  opacity: "1",
                                }}
                              />
                              <p>
                                <strong>Address:</strong> {item.Address1},{" "}
                                {item.Address2}, {item.City}, {item.State},{" "}
                                {item.ZipCode}
                              </p>
                              <button
                                style={{
                                  backgroundColor: "#E8C48A",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "6px",
                                  padding: "4px 10px",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  width: "95%", // Add this line
                                }}
                                onClick={handleCloseDeliveryModal}
                              >
                                Use Current Address
                              </button>
                              <button
                                style={{
                                  backgroundColor: "#E8C48A",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "6px",
                                  padding: "4px 10px",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  width: "95%", // Add this line
                                }}
                                onClick={handleAddNewAddress}
                              >
                                Deliver to New Address
                              </button>
                            </>
                          )}
                          <button
                            style={{
                              backgroundColor: "#E8C48A",
                              color: "black",
                              border: "none",
                              borderRadius: "6px",
                              padding: "4px 10px",
                              fontSize: "18px",
                              cursor: "pointer",
                              width: "95%",
                            }}
                            onClick={handleCloseDeliveryModal}
                          >
                            Cancel
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </>
          )}
        </PaymentsContext.Consumer>
        {enterNewAddress && (
          <div
            style={{
              backgroundColor: "rgb(31, 29, 43)",
              border: "solid 2px #673F36",
              width: "30%",
              padding: "30px",
              borderRadius: "10px",
              position:
                "fixed" /* or fixed, depending on your layout requirements */,
              top: "15%" /* Adjust the top position as needed */,
              right: "5%" /* Adjust the left position as needed */,
              zIndex: "20",
              opacity: "1",
            }}
          >
            <h4> Shipping Address</h4>
            {/* <br /> */}
            <hr
              style={{
                borderBottom: "2px solid rgb(103, 63, 54)",
                opacity: "1",
              }}
            />
            <div
              className="input-group mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className="input-group-text"
                style={{ width: "100px", height: "40px" }}
              >
                Address 1
              </span>
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_API_KEY}
                selectProps={{
                  onChange: (value) => handleGoogleField(value),
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "black",
                      cursor: "text",
                      height: "30px",
                      minWidth: "200px",
                      width: "200px",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  },
                }}
              />
              {/* <input
                value={tempAddress1}
                style={{ maxWidth: "400px",height:"40px" }}
                type="text"
                name="address1"
                onChange={handleNewAddressDetailsChange}
                className="form-control"
                placeholder="Address 1"
              /> */}
            </div>
            <div
              className="input-group mb-3"
              style={{ display: "flex", alignItems: "center", gap: "1rem" }}
            >
              <span>Current Address 1:</span>
              <label>{tempAddress1}</label>
            </div>
            <div
              className="input-group mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className="input-group-text"
                style={{ width: "100px", height: "40px" }}
              >
                Address 2
              </span>
              <input
                value={tempAddress2}
                style={{ maxWidth: "400px", height: "40px" }}
                type="text"
                className="form-control"
                name="address2"
                onChange={handleNewAddressDetailsChange}
                placeholder="Address 2"
              />
            </div>
            <div
              className="input-group mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className="input-group-text"
                style={{ width: "100px", height: "40px" }}
              >
                City
              </span>
              <input
                value={tempCity}
                style={{ maxWidth: "400px", height: "40px" }}
                type="text"
                className="form-control"
                name="newaddresscity"
                onChange={handleNewAddressDetailsChange}
                placeholder="City"
              />
            </div>
            <div
              className="input-group mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className="input-group-text"
                style={{ width: "100px", height: "40px" }}
              >
                State
              </span>
              <input
                value={tempState}
                style={{ maxWidth: "400px", height: "40px" }}
                type="text"
                className="form-control"
                name="newaddressstate"
                onChange={handleNewAddressDetailsChange}
                placeholder="State"
              />
            </div>
            <div
              className="input-group mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                className="input-group-text"
                style={{ width: "100px", height: "40px" }}
              >
                Zip Code
              </span>
              <input
                value={tempZipCode}
                style={{ maxWidth: "400px", height: "40px" }}
                type="text"
                className="form-control"
                maxLength={5}
                name="newaddresszip"
                onChange={handleNewAddressDetailsChange}
                placeholder="Zip Code"
              />
            </div>
            {isNewCustomerAddress && (
              <button
                style={{
                  backgroundColor: "#E8C48A",
                  color: "black",
                  border: "none",
                  borderRadius: "6px",
                  padding: "4px 10px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                onClick={handleSaveNewCustomerAddress}
              >
                Save
              </button>
            )}
            {!isNewCustomerAddress && (
              <button
                style={{
                  backgroundColor: "#E8C48A",
                  color: "black",
                  border: "none",
                  borderRadius: "6px",
                  padding: "4px 10px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                onClick={handleAddNewCustomerAddress}
              >
                Add
              </button>
            )}
            <button
              style={{
                backgroundColor: "#E8C48A",
                color: "black",
                border: "none",
                borderRadius: "6px",
                padding: "4px 10px",
                fontSize: "18px",
                cursor: "pointer",
                width: "40%",
                marginLeft: "20px",
              }}
              onClick={handleCloseNewAddressPrompt}
            >
              Cancel
            </button>
          </div>
        )}
      </>
    );
  }
};

export default Cart;
