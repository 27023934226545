import React, { useEffect, useState, useContext } from "react";
import { CustomerContext } from "../context/CustomerContext";
import CartContext from "../context/CartContext";
import { useTotalPrice } from "../context/TotalPriceContext";
import Cart from "./Cart";
import PlaceOrder from "./PlaceOrder";
import LoadingSpinner from "./LoadingSpinner";
import { OrderStatusEnum } from "../data/OrderStatusEnum";
import { useHistory } from "react-router-dom";
import StatusPopup from "../components/StatusPopup";
import PriceCodeDataContext from "../context/PriceCodeDataContext";

const ShowOrders = () => {
  const [order, setOrder] = useState([]);
  const [singleOrder, setSingleOrder] = useState();
  const { customerId } = useContext(CustomerContext);
  const [priceCodeData, setPriceCodeData] = useContext(PriceCodeDataContext);
  const { totalPrice, setTotalPrice } = useTotalPrice(); // use the custom hook
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [cart, setCart] = useContext(CartContext);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [showChangePopup, setShowChangePopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [isEditOrder, setIsEditOrder] = useState(false);
  const [currentSelected,setCurrentSelected] = useState(false);
  const { priceCode: PriceCodeFromContext, setPriceCode } =
    useContext(CustomerContext);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/New_York'
  };

  const history = useHistory();
  useEffect(() => {
    document.body.style.overflow = "auto"; // Set overflow to auto when component mounts
    return () => {
      document.body.style.overflow = "initial"; // Reset overflow when component unmounts
    };
  }, []);

  const handleCloseStatusPopup = () => {
    setShowChangePopup(false);
  };
  useEffect(() => {
    // Remove modal backdrop from DOM when component mounts
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
  }, []);
  useEffect(() => {
    // fetchOrders();
    FilterFn();
  }, [customerId]);

  useEffect(() => {
    if (noData && order.length === 0) {
      setIsLoading(false);
    }
  }, [noData, isLoading, order]);

  const getEstTime = (date) => {
    if(date !== null){

      const londonTime = new Date(date); // Assuming item.Date is your London time
      const estTime = new Date(londonTime.getTime()); // Subtracting 5 hours for EST
      const estFormattedTime = estTime.toLocaleString('en-US', options);
      return estFormattedTime;
    } else {
      return null;
    }
  }
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const searchValueChangeHandler = (e) => {
    const filterValue = e.target.value;
    localStorage.removeItem("orderSearchValue");
    localStorage.setItem("orderSearchValue", filterValue);
    setSearchInput((prevInput) => e.target.value);
    setFilterValue((prevValue) => e.target.value);
  };

  const searchValueHandler = (e) => {
    if (e.key === "Enter") {
      FilterFn();
    } else if (filterValue.length === 0) {
      setOffset((prevOffset) => 0);
    } else {
    }
  };

  const FilterFn = async () => {
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    try {
      setOrder([]);
      setIsLoading(true);
      setNoData(false);
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "invoices/filter?filter=" +
        encodeURIComponent(filterValue) +
        "&offset=" +
        offset,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        setNoData((val) => true);
        setIsLoading((val) => false);
        console.log(data)
        setOrder((prevData) => data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    setOffset((prevValue) => prevValue + 20);
    FilterFn();
  };

  const showPrevious = () => {
    setOffset((prevValue) => prevValue - 20);
    FilterFn();
  };

  const [successMessage, setSuccessMessage] = useState(null);

  const handlelabelCheckedBack = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/PrintLabelStoreBackById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    console.log("Printing label...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };
  const handlelabelCheckedFront = async () => {
    // Call label API
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/PrintLabelStoreFrontById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    console.log("Printing label...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };
  const handleEmailReceipt = async () => {
    // Call label API
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/EmailOrderById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    console.log("Emailing receipt...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };
  const handlePrintReceipt = async () => {
    // Call label API
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/PrintReceiptById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    // Call print API
    console.log("Printing receipt...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const handleStatusPopup = (o_status, invoice_no) => {
    console.log(o_status);
    setShowChangePopup(true);
    setSelectedPopupOrder(invoice_no);
    if (o_status === null) {
      setCurrentStatus(-1);
    } else {
      setCurrentStatus(o_status);
    }
  };

  const getTotalCartQuantity = (cartItems) =>{
    let TotalQuantity = 0
    cartItems.forEach((cartItem) => TotalQuantity += cartItem.Qty);
    return TotalQuantity;
  }

  useEffect(() => {
    if (currentStatus !== null) {
      setShowChangePopup(true);
    }
  }, [currentStatus]);
  const [quantityMultiple, setQuantityMulitple] = useState(1);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedPopupOrder, setSelectedPopupOrder] = useState(null);
  const [orderPayments, setOrderPayments] = useState([]);
  const [orderDelete, setOrderDelete] = useState(false);
  const [singleCustomer, setSingleCustomer] = useState(null);

  const singleCustomerOrders = async () => {
    try {
      setIsLoading(true);
      setNoData(false);
      setOrder([]);
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}invoices/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data)
        // Sort the data by Date in descending order
        // data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        // Calculate the total price for each order
        data.forEach((order) => {
          order.totalPrice = order.InvoiceDetails.reduce(
            (total, detail) => total + detail.PriceTot,
            0
          );
        });
        
        setNoData(true);
        setIsLoading(false);
        setOrder((prevOrders) => data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
      // setIsLoading((val) => false);
    }
  };
  const getOrderEnum = (order) => {
    const orderEnum = OrderStatusEnum.find(
      (singleEnum) => singleEnum.id == order
    );
    return orderEnum.name;
  };

  const fetchOrderDetails = async (item) => {
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      setIsLoading(true);
      setNoData(false);
      const cust_response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${item.CustomerID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(cust_response?.status === 401){
        history.replace("/login/");
      }
      if (cust_response?.ok) {
        let cust_data = await cust_response.json();
        setSingleCustomer(cust_data[0]);
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}invoices/${item.CustomerID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        // Sort the data by Date in descending order
        // data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        // Calculate the total price for each order
        data.forEach((order) => {
          order.totalPrice = order.InvoiceDetails.reduce(
            (total, detail) => total + detail.PriceTot,
            0
          );
        });
        setNoData((val) => true);
        setIsLoading((val) => false);
        setSingleOrder(data.find((val) => val.InvoiceNo === item.InvoiceNo));
        setCart((prevCart) => []);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
      // setIsLoading((val) => false);
    }
  };
  const getPriceCodeDescription =(priceCode)=>{
    
    const codeData = priceCodeData.find(data=> data.Price == priceCode)
    if(codeData){
      return codeData.PriceDesc
    } else {
      return null
    }
  }

  useEffect(() => {
    //
    let totalQuantity = singleOrder?.InvoiceDetails.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.Qty;
      },
      0
    );

    const isMultipleOf20WithRemainder = totalQuantity % 20 !== 0;
    setQuantityMulitple((val) => {
      if (isMultipleOf20WithRemainder) {
        return parseInt(totalQuantity / 20) + 1;
      } else {
        return parseInt(totalQuantity / 20);
      }
    });
  }, [singleOrder, singleCustomer, orderPayments]);

  const [loadingIndex, setLoadingIndex] = useState(-1);
  const handleOrderSelect = async (item, index) => {
    // setShowInner(true)
    setDeliveryDate(item.DeliveryDate);

    setLoadingIndex(index);
    setIsLoading(true);
    await fetchOrderDetails(item);
    let invoiceNo = item.InvoiceNo;
    if (!orderDelete) {
      if (selectedOrder === invoiceNo) {
        setSelectedOrder(null);
      } else {
        const token = localStorage.getItem("authToken");
        if (token == null) {
          history.replace("/login/");
        }
        const items = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}SavePaymentData/GetPaymentsByInvoiceNo/${invoiceNo}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if(items?.status === 401){
          history.replace("/login/");
        }

        if (!items?.ok) {
          throw new Error(`HTTP error! status: ${items?.status}`);
        } else {
          let data = await items.json();
          setOrderPayments((prevPayments) => data);
        }
        setIsLoading(false);
        setSelectedOrder((val) => invoiceNo);
        setLoadingIndex(-1);
      }
    } else {
      setOrderDelete(false);
    }
  };

  const addAllToCartForEdit = (
    invoiceDetails,
    subaccount,
    balance,
    shipVia,
    orderDate,
    comment,
    cart,
    setCart
  ) => {
    let newCart = [];
    let tempPriceCode;
    invoiceDetails.forEach((detail) => {
      const newItem = {
        StyleNo: `${detail.StyleNo}`,
        StyleDesc: detail.StyleDesc,
        IDTno: detail.IDTno,
        Date: orderDate,
        InvoiceNo: detail.InvoiceNo,
        PriceCode: detail.PriceCode,
        OriginalPrice: detail.OriginalPrice,
        discount: detail.Qty * detail.OriginalPrice - detail.Qty * detail.Price,
        Price: detail.Price,
        SubAccount: subaccount,
        Comment: comment,
        PriceList: detail.Price,
        name: detail.StyleDesc,
        price: detail.Price,
        Balance: balance,
        quantity: detail.Qty,
      };
      tempPriceCode = newItem.PriceCode;
      newCart = [...newCart, newItem];
    });
    setCart((prevCart) => newCart);
    setPriceCode((prevPriceCode) => tempPriceCode);
    localStorage.setItem("balance", balance);
    localStorage.setItem("shipvia", shipVia);
    setIsEditOrder((val) => true);
  };

  return (
    <>
      {showChangePopup && (
        <StatusPopup
          onClose={handleCloseStatusPopup}
          currentStatus={currentStatus}
          invoiceNo={selectedPopupOrder}
          refetch={()=> currentSelected ? singleCustomerOrders(): FilterFn()}
        />
      )}
      <CartContext.Consumer>
        {([cart, setCart]) => (
          <>
            <div className="title-date-container">
              <h1>Orders History</h1>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
                <input
                  type="search"
                  className="form-control2 m-2 search-input"
                  onChange={searchValueChangeHandler}
                  value={searchInput}
                  onKeyDown={searchValueHandler}
                  placeholder="Search Orders"
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    height: "38px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    marginLeft: "80%",
                  }}
                  autoFocus
                />
                <div style={{ textAlign: "left" }}>
                  <input
                    type="checkbox"
                    style={{ marginRight: "8px" }}
                    id="filterUser"
                    name="filterUser"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCurrentSelected(true)
                        singleCustomerOrders();
                      } else {
                        setCurrentSelected(false)
                        FilterFn();
                      }
                    }}
                  />
                  <label htmlFor="filterUser">Current User Orders</label>
                </div>
              </div>
            </div>
            <div></div>
            <div className="table-main">
            {offset >= 20 && !isLoading  && (
                <div className="center-container">
                  <button
                    className="btn transparent-button mt-3"
                    onClick={showPrevious}
                  >
                    Show Previous
                  </button>
                </div>
              )}
              <div className="d-flex flex-row align-items-center ">
                {order.length > 0 ? (
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid black",
                      padding: "10px",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "transparent",
                          color: "#e7d2b7",
                        }}
                      >
                        <th>Order No</th>
                        <th>Customer Name</th>
                        <th>Order Date</th>
                        <th>Delivery Date</th>
                        <th>Type</th>
                        <th>Price Code Desc</th>
                        <th>Total Price</th>
                        <th>Order Status</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr
                            onClick={() => handleOrderSelect(item, index)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{item.InvoiceNo}</td>
                            <td>{item.NameFirst} {item.NameLast}</td>
                            {/* <td>{(new Date(item.Date)).toLocaleString('en-US', options)}</td> */}

                            {/* <td>{getEstTime(item.Date)}</td> */}
                          <td>{getEstTime(item.TimeDT)}</td>

                            {/* <td>{formatDate(item.Date)}</td> */}
                            <td>
                              {item.DeliveryDate
                                ? formatDate(item.DeliveryDate)
                                : ""}
                            </td>
                            <td>{item.ShipVia}</td>
                            <td>{ getPriceCodeDescription(item.PriceCode)}</td>
                            <td>
                              $
                              {item.totalPrice
                                ? parseFloat(item.totalPrice).toFixed(2)
                                : parseFloat(item.PriceTot).toFixed(2)}
                            </td>
                            <td>
                              {item.OrderStatus
                                ? getOrderEnum(item.OrderStatus)
                                : ""}
                            </td>
                            <td>${item.Balance < 0 ? "0.00" : item.Balance.toFixed(2)}</td>
                            <td>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Stop propagation here
                                  
                                  handleStatusPopup(
                                    item.OrderStatus,
                                    item.InvoiceNo
                                  );
                                  // Add your button click logic here
                                }}
                                style={{
                                  width: "130px",
                                  padding: "5px",
                                  color: "black",
                                }}
                              >
                                Change Status
                              </button>
                            </td>
                          </tr>
                          {selectedOrder === item.InvoiceNo  ? (
                            <>
                              {/* <tr>
                              <td colSpan="2"></td>
                              <td colSpan="1">
                                <strong
                                  style={{
                                    fontSize: "25px",
                                    borderBottom: "2px solid rgb(103, 63, 54)",
                                  }}
                                >
                                  Order # {selectedOrder}
                                </strong>
                              </td>
                              <td colSpan="3"></td>
                            </tr> */}

                              <tr>
                                <td colSpan="3">
                                  <div
                                    style={{
                                      minHeight: "500px",
                                      cursor: "pointer",
                                      border: "1px solid #e7d2b7",

                                      padding: "15px",
                                      width: "300px",
                                      boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >

                                    <h3
                                      style={{
                                        color: "#e7d2b7",
                                        borderBottom: "1px solid #e7d2b7",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      {singleCustomer?.NameFirst}{" "}
                                      {singleCustomer?.NameLast}
                                    </h3>
                                    <p>
                                      <strong>Cust ID:</strong>{" "}
                                      {singleCustomer?.CustomerID}
                                    </p>
                                    <p>
                                      <strong>Address:</strong>{" "}
                                      {singleCustomer?.Address1}
                                      {singleCustomer?.Address1 && ", "}
                                      {singleCustomer?.Address2}
                                      {singleCustomer?.Address2 && ", "}
                                      {singleCustomer?.City}
                                      {singleCustomer?.City && ", "}
                                      {singleCustomer?.State}
                                      {singleCustomer?.State && ", "}
                                      {singleCustomer?.ZipCode}
                                    </p>
                                    <p>
                                      <strong>Phone1:</strong>{" "}
                                      {singleCustomer?.Phone1}
                                    </p>
                                    <p>
                                      <strong>Phone2:</strong>{" "}
                                      {singleCustomer?.Phone2}
                                    </p>
                                    <p>
                                      <strong>Email:</strong>{" "}
                                      {singleCustomer?.EmailAdrs}
                                    </p>
                                  </div>
                                </td>
                                <td colSpan="2">
                                  <div
                                    style={{
                                      minHeight: "500px",
                                      cursor: "pointer",
                                      border: "1px solid #e7d2b7",

                                      padding: "15px",
                                      width: "300px",
                                      boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <p>
                                      <h3
                                        style={{
                                          color: "#e7d2b7",
                                          borderBottom: "1px solid #e7d2b7",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        Shipping Info
                                      </h3>
                                    </p>
                                    {singleOrder ? (
                                      <>
                                        <p>
                                          <strong>Via:</strong>{" "}
                                          {singleOrder.ShipVia}
                                        </p>
                                        <p>
                                          <strong>Type:</strong>{" "}
                                          {singleOrder.ShipType}
                                        </p>
                                        <p>
                                          <strong>City:</strong>{" "}
                                          {singleOrder.ShipCity
                                            ? singleOrder.ShipCity
                                            : singleCustomer.City}
                                        </p>
                                        <p>
                                          <strong>State:</strong>{" "}
                                          {singleOrder.ShipState
                                            ? singleOrder.ShipState
                                            : singleCustomer.State}
                                        </p>
                                        <p>
                                          <strong>Address1:</strong>{" "}
                                          {singleOrder.ShipAdrs1
                                            ? singleOrder.ShipAdrs1
                                            : singleCustomer.Address1}
                                        </p>
                                        <p>
                                          <strong>Address2:</strong>{" "}
                                          {singleOrder.ShipAdrs2
                                            ? singleOrder.ShipAdrs2
                                            : singleCustomer.Address2}
                                        </p>
                                        <p>
                                          <strong>Zip:</strong>{" "}
                                          {singleOrder.ShipZip
                                            ? singleOrder.ShipZip
                                            : singleCustomer.ZipCode}
                                        </p>
                                        <p>
                                          <strong>Total Price:</strong>{" "}
                                          {singleOrder.totalPrice}
                                        </p>
                                      </>
                                    ) : (
                                      <p>
                                        <strong>No Shipping Info</strong>
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td colSpan="4">
                                  <div

                                    style={{
                                      minWidth: "420px",
                                      minHeight: "500px",
                                      cursor: "pointer",
                                      border: "1px solid #e7d2b7",
                                      width: "fit-content",
                                      boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <p>
                                      <h3
                                        style={{
                                          color: "#e7d2b7",
                                          borderBottom: "1px solid #e7d2b7",
                                          paddingTop: "15px",
                                          paddingLeft: "15px",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        Orders & Payments
                                      </h3>
                                    </p>
                                    <table
                                      style={{
                                        width: "100%",
                                        borderSpacing: "0",
                                      }}
                                    >
                                      <thead>
                                        <tr style={{ fontWeight: "bold" }}>
                                          <th>S.No</th>
                                          <th>Product</th>
                                          <th>Qty</th>
                                        <th>DisPct</th>
                                          <th>PriceList</th>
                                        <th>Price</th>
                                        <th>PriceTot</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {singleOrder?.InvoiceDetails?.map(
                                          (order, index) => (
                                            <tr key={index}>
                                              <td colSpan="1">{++index}</td>
                                              <td colSpan="1" style={{whiteSpace:"nowrap"}}>
                                                {order.StyleDesc}
                                              </td>
                                              <td colSpan="1">{order.Qty}</td>
                                              <td colSpan="1">{order.DiscountPercent}%</td>
                                              <td colSpan="1">${order.PriceList}</td>
                                              <td colSpan="1">${order.Price}</td>
                                              <td colSpan="1">${order.PriceTot}</td>
                                              <td colSpan="1"></td>
                                            </tr>
                                          )
                                        )}
                                        <tr>
                                        <td colSpan="2">

<div
style={{
display: "flex",
alignItems: "center",
justifyContent: "space-between",
}}
>
<label htmlFor="total" style={{ fontSize: "16px" }}>
Total Quantity:
</label>
<span
style={{ fontSize: "20px", fontWeight: "bold" }}
>{getTotalCartQuantity(singleOrder.InvoiceDetails)}</span>
</div>
  </td>
                                        </tr>
                                        <tr>
                                          <td colSpan="3"></td>
                                          <td colSpan="2">
                                            <button
                                              onClick={() =>
                                                addAllToCartForEdit(
                                                  singleOrder.InvoiceDetails,
                                                  singleOrder.SubAccount,
                                                  singleOrder.Balance,
                                                  singleOrder.ShipVia,
                                                  singleOrder.DeliveryDate,
                                                  singleOrder.Comment,
                                                  cart,
                                                  setCart
                                                )
                                              }
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              disabled={
                                                singleOrder?.OrderStatus === 8 ||
                                                singleOrder?.OrderStatus === 10
                                              }
                                            >
                                              Edit Order
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div
                                      style={{
                                        //maxHeight: "400px",
                                        //overflow: "auto",
                                        border: "1px solid #343245",
                                        borderRadius: "8px",
                                        padding: "10px",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            Payments
                                          </tr>
                                          <tr>
                                            <th>Id</th>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orderPayments?.map(
                                            (payment, index) => (
                                              <tr key={index}>
                                                <td>{++index}</td>
                                                <td>
                                                  {formatDate(
                                                    payment.CreditDate
                                                  )}
                                                </td>
                                                <td>{payment.PayType}</td>
                                                <td>
                                                  $
                                                  {payment.CreditAmt.toFixed(2)}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>

                                <td colSpan="8">
                                  <table
                                    style={{
                                      margin: "0 auto",
                                      width: "65vw",
                                      border: "1px solid #e7d2b7",


                                    }}
                                  >
                                   
                                    <tbody>
                                      {/* <tr>
                                        <td colSpan="3"></td>
                                      </tr> */}
                                      {successMessage === null ? (
                                        <tr>
                                          <td>
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handlePrintReceipt}
                                            >
                                              Print Receipt 
                                            </button>
                                          </td>
                                          <td>
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handleEmailReceipt}
                                            >
                                              Email Receipt
                                            </button>
                                          </td>
                                          {/* <td>{singleCustomer?.NameLast}</td> */}
                                          <td>
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "200px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handlelabelCheckedFront}
                                            >
                                              Print Label and Pick Up
                                            </button>
                                          </td>
                                          <td>
                                            {/* <input
                                            type="checkbox"
                                            style={{ marginRight: "8px" }}
                                            id="label"
                                            name="label"
                                            checked={labelCheckedBack}
                                            onChange={(e) =>
                                              setLabelCheckedBack(
                                                e.target.checked
                                              )
                                            }
                                          /> */}
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "170px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handlelabelCheckedBack}
                                            >
                                              Print Label Back
                                            </button>
                                          </td>
                                          {/* <td>
                                          <button
                                            type="button"
                                            disabled={
                                              !labelChecked &&
                                              !emailChecked &&
                                              !labelCheckedBack &&
                                              !printChecked
                                            }
                                            className="btn btn-secondary "
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "white",
                                              padding: "3px 7px",
                                              fontSize: "12px",
                                              border: "2px solid #2E8B57",
                                              width: "100px",
                                              height: "30px",
                                              borderRadius: "3px",
                                              cursor: "pointer",
                                              boxShadow:
                                                "0 4px 8px rgba(0,0,0,0.1)",
                                              transition: "all 0.3s ease",
                                            }}
                                            onMouseOver={(e) => {
                                              e.currentTarget.style.transform =
                                                "scale(1.05)";
                                              e.currentTarget.style.boxShadow =
                                                "0 6px 12px rgba(0,0,0,0.2)";
                                            }}
                                            onMouseOut={(e) => {
                                              e.currentTarget.style.transform =
                                                "scale(1)";
                                              e.currentTarget.style.boxShadow =
                                                "0 4px 8px rgba(0,0,0,0.1)";
                                            }}
                                            onClick={handleFinish}
                                          >
                                            OK
                                          </button>
                                        </td> */}
                                        </tr>
                                      ) : (
                                        <tr>
                                          <h3 style={{ textAlign: "center" }}>
                                            {successMessage}
                                          </h3>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="1"></td>
                                <td colSpan="6">
                                  <hr />
                                </td>
                                <td colSpan="1"></td>
                              </tr>
                            </>
                          ) : loadingIndex === index && isLoading ? (
                            <tr>
                              <td colSpan="5">
                                <LoadingSpinner />
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                ) : isLoading ? (
                  <div style={{ marginLeft: "25vw" }}>
                    <LoadingSpinner />
                  </div>
                ) : null}
              </div>
              {order.length > 0 && (
                <div className="center-container">
                  <button
                    className="btn transparent-button mt-3"
                    onClick={loadMore}
                  >
                    Load More
                  </button>
                </div>
              )}
              {noData && order.length === 0 && !isLoading && (
                <p>No Order History Found</p>
              )}
            </div>
            <div
              style={{
                width: "40%",
                position: "fixed",
                right: "0",
                top: "5px",
                zIndex: 10,
              }}
            >
              <Cart oDeliveryDate={deliveryDate} />
            </div>
          </>
        )}
      </CartContext.Consumer>
    </>
  );
};

export default ShowOrders;
