import React, { useState } from "react";
import { OrderStatusEnum } from "../data/OrderStatusEnum";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StatusPopup = ({ onClose,  currentStatus , invoiceNo,refetch }) => {
  const [newStatus, setNewStatus] = useState(null); 
  const history = useHistory()
  const handleClick =async () => {
    try {
      const token = localStorage.getItem("authToken");
      if(token == null){
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/UpdateStatus",
        {
          method: "PUT",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify( {
            invoiceNo,
            orderStatus: newStatus
          }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        onClose()
        refetch()
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
  };
  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };
  const getStatus =(orderstatus)=>{
 
if(orderstatus){

  const status = OrderStatusEnum.find(orderenum=>orderenum.id == orderstatus)
  return status.name
}
   
  }
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        border: "2px solid rgb(103, 63, 54)",
        borderRadius: "12px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#252836",
        borderRadius: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      <div style={{ textAlign: "right" }}>
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
          style={{
            border: "none",
            background: "none",
            color: "white",
            cursor: "pointer",
            outline: "none",
          }}
        >
          X
        </button>
      </div>

      <div>
        <div>
          <h6 style={{ textAlign: "left" }}>Current Status: {currentStatus == -1 ? "No info":getStatus(currentStatus)}</h6>
        </div>
        <br />
        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
          <label>Change Status</label>
          <select onChange={handleStatusChange}>
            <option selected disabled>
              Select Order Status
            </option>
            {OrderStatusEnum.map((orderenum) => {
              return (
                <option key={orderenum.id} value={orderenum.id}>
                  {orderenum.name}
                </option>
              );
            })}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            gap: "1rem",
          }}
        >
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClick}

            style={{
              backgroundColor: "#E8C48A",
              color: "black",
              border: "none",
              borderRadius: "6px",
              padding: "4px 10px",
              fontSize: "18px",
              cursor: "pointer",
             
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor =
                "#d6c3a2"; // Slightly darker shade on hover
              e.currentTarget.style.transform =
                "scale(1.05)"; // Slight scale for a "pop" effect
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor =
                "#E8C48A";
              e.currentTarget.style.transform =
                "scale(1)";
            }}
          >
            Update
          </button>
        </div>
        <br />
      </div>
    </div>
  );
};

export default StatusPopup;
