import React, { useState } from 'react'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CarTypePopup =({ onClose,setSearchTerm,setSelectedCarList }) => {

    const [newCarNo,setNewCarNo]= useState(null)
    const [newCarDesc,setNewCarDesc]= useState("")
    const history = useHistory()
    const handleClick =async () => {
        
      try {
        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "ListCar",
          {
            method: "POST",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify( {
              CarDesc:newCarDesc,
              CarNo: Number(newCarNo)
            }),
          }
        );
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error(`HTTP error! status: ${response?.status}`);
        } else {
          let data = await response.json();
          setSearchTerm(`${newCarNo}: ${newCarDesc}`)
         setSelectedCarList(Number(newCarNo))
          onClose()
          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
    };
   
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          border: "2px solid rgb(103, 63, 54)",
          borderRadius: "12px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#252836",
          borderRadius: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
            aria-label="Close"
            style={{
              border: "none",
              background: "none",
              color: "white",
              cursor: "pointer",
              outline: "none",
            }}
          >
            X
          </button>
        </div>
  
        <div>
         
          <br />
          <div style={{ display: "flex", gap: "2rem", alignItems: "center", justifyContent:"center" }}>
            <h3>Add Details</h3>
           
          </div>
          <div style={{ display: "flex", gap: "2rem", alignItems: "center" }} >
            <label style={{width:"90px"}}>
                Car No
            </label>
<input type="number" onChange={(e)=>setNewCarNo(e.target.value)}/>
          </div>
          <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>


            <label style={{width:"90px"}}>
                Car Desc
            </label>
<input type='text' onChange={(e)=>setNewCarDesc(e.target.value)}/>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              gap: "1rem",
            }}
          >
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClick}
  
              style={{
                backgroundColor: "#E8C48A",
                color: "black",
                border: "none",
                borderRadius: "6px",
                padding: "4px 10px",
                fontSize: "18px",
                cursor: "pointer",
               
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor =
                  "#d6c3a2"; // Slightly darker shade on hover
                e.currentTarget.style.transform =
                  "scale(1.05)"; // Slight scale for a "pop" effect
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor =
                  "#E8C48A";
                e.currentTarget.style.transform =
                  "scale(1)";
              }}
            >
              Add
            </button>
          </div>
          <br />
        </div>
      </div>
  )
}

export default CarTypePopup