import React, { useState, useEffect, useContext } from "react";
import "../styles/App.css";
import { CustomerContext } from "../context/CustomerContext";

import { IoLocationOutline } from "react-icons/io5";
import { BsBox } from "react-icons/bs";
import { BsTelephone } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa6";
import GeneralAlert from "../components/GeneralAlert";
import { useHistory } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const Customer = (props) => {
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showAlert,setShowAlert] = useState(false);
  const [alertText,setAlertText] = useState("")
  const { customerId } = useContext(CustomerContext);
  const [customerData, setCustomerData] = useState([]);
  const [editing, setEditing] = useState(false);
  const GOOGLE_API_KEY= "AIzaSyB62k0-8YzdEYoR_9Y68c9eWFHKSHxHtl4"
  const [state, setState] = useState({
    modalTitle: "",
    CustomerID: 0,
    Name: "",
    NameLast: "",
    NameFirst: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    ZipCode: "",
    Phone1: "",
    Phone2:"",
    EmailAdrs: "",
    IsValidPhoneNumber: true,
    IsEmailValid: true,
  });

  const history = useHistory()
  const fetchCustomerDetails = async () => {
    try {
      console.log("CusID", customerId);
      const token = localStorage.getItem("authToken");
      if(token == null){
        history.replace("/login/");
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        setCustomerData(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
  };
  useEffect(() => {
    fetchCustomerDetails();
  }, [customerId]);

  const handleCloseAlert =() => {
    setShowAlert(false);
  }
  const handleChange = (key, value) => {
    if (key === "Phone1") {
      const phoneRegex = /^\d{10}$/;
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));

      setIsValidPhoneNumber(() => {
        return phoneRegex.test(value);
      });
    } else if (key === "EmailAdrs") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));

      setIsEmailValid(() => {
        return emailRegex.test(value);
      });
    } else if (key === "ZipCode") {
      const sanitizedValue = value.replace(/\D/g, "");
      const zipCode = sanitizedValue.slice(0, 5);
      setState((prevState) => ({
        ...prevState,
        [key]: zipCode,
      }));
    } else if (key === "Address1") {
      if(value.value.terms.length >=3){
        const addressSlice = value.value.terms.slice(0, value.value.terms.length - 3);
        console.log(addressSlice);
        setState((prevState) => ({ ...prevState,Address1: addressSlice.map(term => term.value).join(', ') }));
  
      setState((prevState) => ({ ...prevState,State: value.value.terms[value.value.terms.length-2].value}));
        setState((prevState) => ({...prevState, City: value.value.terms[value.value.terms.length-3].value}));
        
        // const element = document.querySelector('. css-1qrxvr1-singleValue');
        // if (element) {
        //     element.innerHTML = ''; // Set inner HTML to empty string
        // }
        // const other = document.querySelector('. css-1jqq78o-placeholder');
        // if (other) {
        //     other.innerHTML = this.state.Address1; // Set inner HTML to empty string
        // }
      }else {
      setState((prevState) => ({ ...prevState,Address1: value.label }));
  
      }
    } else{

      
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleEdit = (item) => {
    setState(item);
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      if (state.ZipCode.length < 5 && state.ZipCode.length > 0) {
        setAlertText("Please Enter 5 digit Zipcode")
        setShowAlert(true);
        // alert("Please Enter 5 digit Zipcode");
      } else if (!isEmailValid) {
        setAlertText("Please Enter Valid Email Address!")
        setShowAlert(true);
        // alert("Please Enter Valid Email Address!");
      } else if (!isValidPhoneNumber) {
        setAlertText("Please Enter Valid Phone Number!, Phone Number should be 10 digits long")
        setShowAlert(true);
        // alert(
        //   "Please Enter Valid Phone Number!, Phone Number should be 10 digits long"
        // );
      } else {
        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}customer`,
          {
            method: "PUT",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(state),
          }
        );
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (response?.ok) {
          setEditing(false);

          // re-fetch to update the UI
          fetchCustomerDetails();
        } else {
          console.error(`Failed to save changes: ${response?.statusText}`);
        }
      }
    } catch (error) {
      console.error(`Failed to save changes: ${error.message}`);
    }
  };

  //========================================
  const [editCustomerInfo, setEditCustomerInfo] = useState(false);

  const handeEditInfo = () => {
    setEditCustomerInfo(true);
  };

  return (
    // <div style={{ display: 'flex', paddingTop: '50px', paddingLeft: '100px', flexWrap: 'wrap', gap: '20px' }}>
    <>
     {showAlert && <GeneralAlert popupText={alertText} onClose={handleCloseAlert}/>}
      <div
        style={{
          display: "flex",
          paddingLeft: "100px",
          marginTop: "50px",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {editing ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span className="">First Name:</span>
            <input
              style={{ width: "400px" }}
              value={state.NameFirst}
              onChange={(e) => handleChange("NameFirst", e.target.value)}
              placeholder="First Name"
            />
            <span className="">Last Name:</span>
            <input
              style={{ width: "400px" }}
              value={state.NameLast}
              onChange={(e) => handleChange("NameLast", e.target.value)}
              placeholder="Last Name"
            />
            <span className="">Address 1:</span>
            <GooglePlacesAutocomplete
      apiKey={GOOGLE_API_KEY}
      
      selectProps={{
        
        onChange:  value => handleChange("Address1", value),
        styles: {
          input: (provided) => ({
            ...provided,
            color: 'black',
            cursor: 'text',
            height: "30px",
            minWidth: "300px",
            width: "300px"
          }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'black',
          }),
        },
      }}/>

<div style={{display:"flex", gap:"2rem", alignItems:"center"}}>

<span className="">Current Address 1:</span>
             
             <label>{state.Address1}</label>
  </div>
            <span className="">Address 2:</span>
            <input
              style={{ width: "400px" }}
              value={state.Address2}
              onChange={(e) => handleChange("Address2", e.target.value)}
              placeholder="Address 2"
            />
            <span className="">City:</span>
            <input
              style={{ width: "400px" }}
              value={state.City}
              onChange={(e) => handleChange("City", e.target.value)}
              placeholder="City"
            />
            <span className="">State:</span>
            <input
              style={{ width: "400px" }}
              value={state.State}
              onChange={(e) => handleChange("State", e.target.value)}
              placeholder="State"
            />
            <span className="">Zip:</span>
            <input
              style={{ width: "400px" }}
              value={state.ZipCode}
              onChange={(e) => handleChange("ZipCode", e.target.value)}
              placeholder="ZipCode"
            />
            <span className="">Phone1:</span>
            <input
              style={{ width: "400px" }}
              value={state.Phone1}
              onChange={(e) => handleChange("Phone1", e.target.value)}
              placeholder="Phone"
            />
            <span className="">Phone2:</span>
            <input
              style={{ width: "400px" }}
              value={state.Phone2}
              onChange={(e) => handleChange("Phone2", e.target.value)}
              placeholder="Phone"
            />
            <span className="">Email:</span>
            <input
              style={{ width: "400px" }}
              value={state.EmailAdrs}
              onChange={(e) => handleChange("EmailAdrs", e.target.value)}
              placeholder="EmailAdrs"
            />
            <div style={{display:"flex",gap:"1rem"}}>

            <button style={{ width: "100px", color:"black" }} onClick={handleSave}>
              Save
            </button>
            <button
              style={{ width: "100px", color:"white",  background:"none", textDecoration:"underline" }}
              onClick={() => setEditing(false)}
            >
              Cancel
            </button>
            </div>

          </div>
        ) : (
          customerData.map((item, index) => (
            <div
              key={index}
              style={{
                cursor: "pointer",
                border: "1px solid #e7d2b7",
                borderRadius: "10px",
                padding: "15px",
                width: "250px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3
                style={{
                  color: "#e7d2b7",
                  borderBottom: "1px solid #e7d2b7",
                  paddingBottom: "10px",
                }}
              >
                {item.NameFirst} {item.NameLast}
              </h3>
              <p>
                <strong>Address:</strong> {item.Address1}
                {item.Address1 && ", "}
                {item.Address2}
                {item.Address2 && ", "}
                {item.City}
                {item.City && ", "}
                {item.State}
                {item.State && ", "}
                {item.ZipCode}
              </p>
              <p>
                <strong>Phone1:</strong> {item.Phone1}
              </p>
              <p>
                <strong>Phone2:</strong> {item.Phone2}
              </p>
              <p>
                <strong>Email:</strong> {item.EmailAdrs}
              </p>
              <button style={{color:"black"}} onClick={() => handleEdit(item)}>Edit</button>
            </div>
          ))
        )}
      </div>

      {/* Add Customer Info Card */}
      {/* {editCustomerInfo === false ?
        <>
        
        <div className='customerInfo_Main'>
                        <div className='customerInfo_mainCard'>
                            <h2>Avrom Yide Weinfeld (Austrailia)</h2>
                            <div className='customerInfo_mainCardText'>
                                <div>
                                    <span><IoLocationOutline /></span>
                                    <p> 108 Arthur Avenuc Frccport, llinois 61032</p>
                                </div>
                                <div>
                                    <span><BsBox /></span>
                                    <p> Ship Via:</p>
                                </div>
                                <div>
                                    <span><BsTelephone /></span>
                                    <p> +1815-803-4372</p>
                                </div>
                                <div>
                                    <span><FaRegEnvelope /></span>
                                    <p> example@gmail.com</p>
                                </div>
                            </div>
                            <div className='customerInfo_mainCardbtn'>
                                <button onClick={handeEditInfo}>Edit</button>
                            </div>
                        </div>
                    </div>
        </>
        :
        <>
            
            <div className='editCustomerInfo_Main'>
                        <div className='editCustomerInfo_MainInner'>
                            <div className='editCustomerInfo_heading'>
                                <h5>Edit Customer Info</h5>
                            </div>
                            <div className='editCustomerInfo_body'>
                                <div className='editCustomerInfo_form'>
                                    <div>
                                        <label htmlFor="firstName">First Name</label>
                                        <input type="text" id='firstName' placeholder='Enter your first name' />
                                    </div>
                                    <div>
                                        <label htmlFor="lastName">Last Name</label>
                                        <input type="text" id='lastName' placeholder='Enter your first name' />
                                    </div>
                                </div>
                                <div className='editCustomerInfo_form'>
                                    <div>
                                        <label htmlFor="address1">Address 1</label>
                                        <input type="text" id='address1' placeholder='Address 1' />
                                    </div>
                                    <div>
                                        <label htmlFor="address2">Address 2</label>
                                        <input type="text" id='address2' placeholder='Address 2' />
                                    </div>
                                </div>
                                <div className='editCustomerInfo_form'>
                                    <div>
                                        <label htmlFor="city">City</label>
                                        <input type="text" id='city' placeholder='City' />
                                    </div>
                                    <div>
                                        <label htmlFor="state">State</label>
                                        <input type="text" id='state' placeholder='state' />
                                    </div>
                                </div>
                                <div className='editCustomerInfo_form'>
                                    <div>
                                        <label htmlFor="zip">ZIP</label>
                                        <input type="text" id='zip' placeholder='ZIP' />
                                    </div>
                                    <div>
                                        <label htmlFor="country">Country</label>
                                        <input type="text" id='country' placeholder='Country' />
                                    </div>
                                </div>
                                <div className='editCustomerInfo_fullInput'>
                                    <label htmlFor="shipVia">Ship Via</label>
                                    <input type="text" id='shipVia' placeholder='Ship Via' />
                                </div>
                                <div className='editCustomerInfo_form'>
                                    <div>
                                        <label htmlFor="phoneNumber">Phone</label>
                                        <input type="text" id='phoneNumber' placeholder='Phone Number' />
                                    </div>
                                    <div>
                                        <label htmlFor="emailAddress">Email</label>
                                        <input type="email" id='emailAddress' placeholder='Email Address' />
                                    </div>
                                </div>
                            </div>
                            <div className='editCustomerInfo_formbtn'>
                                <button className='editCustomerInfo_cancelbtn'>Cancel</button>
                                <button className='editCustomerInfo_savebtn'>Save</button>
                            </div>
                        </div>
                    </div>
        </>
        } */}
      {/* <div className='customerInfo_Main'>
            <div className='customerInfo_mainCard'>
                <h2>Avrom Yide Weinfeld (Austrailia)</h2>
                <div className='customerInfo_mainCardText'>
                    <div>
                        <span><IoLocationOutline/></span>
                        <p> 108 Arthur Avenuc Frccport, llinois 61032</p>
                    </div>
                    <div>
                        <span><BsBox/></span>
                        <p> Ship Via:</p>
                    </div>
                    <div>
                        <span><BsTelephone/></span>
                        <p> +1815-803-4372</p>
                    </div>
                    <div>
                        <span><FaRegEnvelope/></span>
                        <p> example@gmail.com</p>
                    </div>
                </div>
                <div className='customerInfo_mainCardbtn'>
                <button>Edit</button>
                </div>
            </div>
        </div> */}
    </>
  );
};

export default Customer;
