import "../styles/DeliveryZipCodes.css";
import React, { useState, useEffect, useRef } from "react";
import MultiDatePicker from "react-multi-date-picker";
import { addDays } from "date-fns";
import GeneralAlert from "../components/GeneralAlert";
import { useHistory } from "react-router-dom";
//import 'react-multi-date-picker/styles/clean.css';

const DeliveryZipCodes = () => {
  const [zipCodeList, setZipCodeList] = useState([]);
  const [existingArea, setExistingArea] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState(0);
  const [newZipCode, setNewZipCode] = useState("");
  const [newArea, setNewArea] = useState("");
  const [newAreaPrice, setNewAreaPrice] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [bulkZipCodes, setBulkZipCodes] = useState("");
  const fileInputRef = useRef(null);
  const [showAlert,setShowAlert] = useState(false);
  const [alertText,setAlertText] = useState("")
  const [selectedDates, setSelectedDates] = useState([]);
  const [deliveryDateInfo, setDeliveryDateInfo] = useState([
    { Id: 0, Date: "", Description: "" },
  ]);
  const [updatePrice, setUpdatePrice] = useState(false);
  const [updatedDeliveryPrice, setUpdatedDeliveryPrice] = useState("");

  const handleDeliveryDateChange = (index, date) => {
    //alert('changed');
    const currentDate = addDays(new Date(), -1);
    const selectedDate = new Date(date);
    if (!isNaN(selectedDate) && selectedDate >= currentDate) {
      const updatedDateInfo = [...deliveryDateInfo];
      updatedDateInfo[index].Date = date;
      setDeliveryDateInfo((prevDateInfo) => {
        return updatedDateInfo;
      });
    }
  };

  const handleDeliveryDescriptionChange = (index, description) => {
    const updatedDateInfo = [...deliveryDateInfo];
    updatedDateInfo[index].Description = description;
    setDeliveryDateInfo((prevDateInfo) => {
      return updatedDateInfo;
    });
  };
  const handleCloseAlert =() => {
    setShowAlert(false);
  }
  const handleAddEntry = () => {
    setAlertText("Please Remember to Save Dates");
    // alert('Please Remember to Save Dates');
    setDeliveryDateInfo((prevDateInfo) => [
      ...prevDateInfo,
      { Id: 0, Date: "", Description: "" },
    ]);
    console.log(deliveryDateInfo);
    setShowAlert(true);
  };

  const handleDateChange = (date) => {
    setSelectedDates((prevDates) => {
      return date;
    });
    setSelectedDates((dates) => {
      let filteredDates = dates.filter(
        (date) => date >= addDays(new Date(), -1)
      );
      return filteredDates.map((date) => new Date(date).toLocaleDateString());
    });
  };

  const history= useHistory()

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryArea`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setExistingArea(data))
      .catch((error) =>
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        )
      );
  }, []);

  const formatDate = (date) => {
    let localDate = new Date(date);
    localDate.setMinutes(
      localDate.getMinutes() - localDate.getTimezoneOffset()
    ); // Adjust for timezone
    return localDate.toJSON().slice(0, 19).replace("T", " ");
  };

  const saveZipCodeToBackend = (zipCodeData) => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryZipCode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(zipCodeData),
    })
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        // Re-fetch ZIP codes after successful addition
        fetch(
          `${process.env.REACT_APP_API_ENDPOINT}DeliveryZipCode/${selectedAreaId}`,
          {
            method: "GET",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          }
        )
          .then((response) => {
            if(response?.status === 401){
              history.replace("/login/");
            }
            return response.json()})
          .then((data) => setZipCodeList(data));
      })
      .catch((error) => {
        console.error("There was an error saving the ZIP code:", error);
      });
  };

  const saveDeliveryArea = (deliveryArea) => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryArea`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(deliveryArea),
    })
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        setNewArea((prevName) => "");
        setNewAreaPrice((prevPrice) => "");
        return response.json();
      })
      .then((data) => {
        setNewArea((prevName) => "");
        setNewAreaPrice((prevPrice) => "");
        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        // Re-fetch ZIP codes after successful addition
        fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryArea`, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        })
          .then((response) => { 
            if(response?.status === 401){
              history.replace("/login/");
            }
            return response.json()})
          .then((data) => setExistingArea(data));
      })
      .catch((error) => {
        console.error("There was an error saving the ZIP code:", error);
      });
  };

  const handleUpdatePriceChange = (event) => {
    setNewAreaPrice((price) => event.target.value);
  };

  const handleUpdateDeliveryPriceChange = (event) => {
    setUpdatedDeliveryPrice((price) => event.target.value);
  };

  const updateAreaPrice = () => {
    setUpdatePrice((val) => true);
  };

  const handleCloseUpdatePrice = () => {
    setUpdatePrice((val) => false);
  };

  const handleUpdateDeliveryPrice = () => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    const area = {
      Id: selectedAreaId,
      Name: "",
      DeliveryPrice: updatedDeliveryPrice,
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryArea`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(area),
    })
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // alert(data);
        setAlertText(data);
        setUpdatePrice((val) => false);
        setShowAlert(true);
      })
      .catch((error) => {
        console.error("There was an error saving the ZIP code:", error);
      });
  };

  const addDeliveryArea = () => {
    if (zipCodeList.some((zip) => zip.Name === newArea)) {
      // alert('This area already exists in the list.');
      setAlertText("This area already exists in the list.");
      setShowAlert(true);

      return;
    }
    if (!newArea.trim()) {
      setAlertText("Please provide a list name.");
      setShowAlert(true);
      // alert("Please provide a list name.");
      return;
    }

    const areanew = { Name: newArea, DeliveryPrice: newAreaPrice };
    setExistingArea((prevAreas) => [...prevAreas, areanew]);
    saveDeliveryArea(areanew);
  };

  const uniqueAreaNames = Array.from(
    new Set(
      existingArea.map((x) => ({
        Id: x.Id,
        Name: x.Name,
        Price: x.DeliveryPrice,
      }))
    )
  );

  const addZipCodeToArea = () => {
    if (newZipCode.length < 5) {
      setAlertText("Please Enter 5 digit Zipcode");
      setShowAlert(true);
      // alert('Please Enter 5 digit Zipcode');
      return;
    }
    //if (zipCodeList.some(zip => zip.Code === newZipCode && zip.ListName === selectedArea)) {
    if (zipCodeList.some((zip) => zip.ZipCode === newZipCode)) {
      setAlertText("This zip code already exists in some list.");
      setShowAlert(true);
      // alert('This zip code already exists in some list.');
      return;
    }

    if (!newZipCode.trim()) {
      setAlertText("Please enter a Zip Code.");
      setShowAlert(true);
      // alert("Please enter a Zip Code.");
      return;
    }
    const zipCodeObj = { ZipCode: newZipCode, DeliveryAreaId: selectedAreaId };
    setZipCodeList((prevList) => [...prevList, zipCodeObj]);
    saveZipCodeToBackend(zipCodeObj);
    setNewZipCode("");
  };

  const uploadZipCodesFromText = () => {
    const zips = bulkZipCodes.split(/\s*,\s*|\s+/); // Split by comma or newline
    zips.forEach((zipCode) => {
      const updatedZip = { Code: zipCode, ListName: selectedArea };
      setZipCodeList((prevList) => [...prevList, updatedZip]);
      saveZipCodeToBackend(updatedZip);
    });
    setBulkZipCodes(""); // Clear after upload
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBulkZipCodes(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  // const formatDateDisplay = dateStr => {
  //   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //   const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  //   const date = new Date(dateStr);

  //   const month = months[date.getMonth()];
  //   const day = date.getDate();
  //   const dayName = days[date.getDay()];

  //   let suffix;
  //   if (day % 10 === 1 && day !== 11) suffix = 'st';
  //   else if (day % 10 === 2 && day !== 12) suffix = 'nd';
  //   else if (day % 10 === 3 && day !== 13) suffix = 'rd';
  //   else suffix = 'th';

  //   return `${month} ${day}${suffix} ${dayName}`;
  // };
  const handleDeleteDeliveryArea = () => {
    if (selectedAreaId == 0) {
      setAlertText("Please select a delivery area")
setShowAlert(true);
      // alert("Please select a delivery area");
      return;
    }
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    if (window.confirm("Are you sure you want to delete this Zip Area?")) {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}DeliveryArea/DeleteDeliveryArea/${selectedAreaId}`,
        {
          method: "DELETE",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if(response?.status === 401){
            history.replace("/login/");
          }
          if (!response?.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setAlertText(data)
setShowAlert(true);
          // alert(data);
          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error deleting the ZIP code:", error);
        });
    }
  };

  const deleteZipCode = (id) => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    if (window.confirm("Are you sure you want to delete this Zip Code?")) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryZipCode?id=${id}`, {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
        .then((response) => {
          if(response?.status === 401){
            history.replace("/login/");
          }
          if (!response?.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setZipCodeList(zipCodeList.filter((zip) => zip.Id !== id)); // Removing the deleted zipcode from the state
        })
        .catch((error) => {
          console.error("There was an error deleting the ZIP code:", error);
        });
    }
  };

  const removeDateInfo = (entry) => {
    //console.log(payment);
    if (window.confirm("Are you sure you want to delete this Date Info?")) {
      setDeliveryDateInfo((prevInfos) => {
        return prevInfos.filter((info) => info.Id !== entry.Id);
      });
    }
  };

  // useEffect(() => {
  //   console.log(selectedArea);
  //   console.log(selectedAreaId);
  //   console.log(zipCodeList);
  // }, [selectedArea, selectedAreaId, zipCodeList]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}DeliveryZipCode/${selectedAreaId}`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) =>
        setZipCodeList((prevZipCodes) => {
          return data;
        })
      )
      .catch((error) =>
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        )
      );

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}DeliveryDates/${selectedAreaId}`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        const futureDatesData = data.filter((item) => {
          const currentDate = new Date();
          const itemDate = new Date(item.Date);

          return itemDate >= currentDate;
        });
        setDeliveryDateInfo((prevDateInfo) => {
          return futureDatesData;
        });
      })
      .catch((error) =>
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        )
      );
  }, [selectedAreaId]);

  const saveDeliveryDatesInfo = () => {
    const datesObj = deliveryDateInfo.map((info) => ({
      Date: info.Date.toString(),
      Description: info.Description,
      DeliveryAreaId: selectedAreaId,
    }));
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryDates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(datesObj),
    })
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        setAlertText("Delivery Date Saved Successfully")
setShowAlert(true);
        // alert("Delivery Date Saved Successfully");
        return response.json();
      })
      .then((data) => {
        // Re-fetch ZIP codes after successful addition
        fetch(`${process.env.REACT_APP_API_ENDPOINT}DeliveryArea`, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        })
          .then((response) => {
            if(response?.status === 401){
              history.replace("/login/");
            }
            return response.json()})
          .then((data) => setExistingArea(data));
      })
      .catch((error) => {
        console.error("There was an error saving the ZIP code:", error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}DeliveryArea/${selectedAreaId}`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) =>
        setUpdatedDeliveryPrice((price) =>
          data.length > 0 ? data[0].DeliveryPrice : ""
        )
      )
      .catch((error) =>
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        )
      );
  }, [selectedAreaId]);

  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
    setSelectedAreaId((prevId) => {
      return e.target.value;
    });

    //console.log(deliveryDateInfo);
    // const selectedIndex = e.target.selectedIndex;
    // const selectedOptionText = e.target.options[selectedIndex].text;
    // setSelectedArea((prevArea) => {
    //   return selectedOptionText;
    // });
    //getZipCodesByAreaId(e.target.value);
  };

  const zipCodeChangeHandler = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, "");
    const zipCode = sanitizedValue.slice(0, 5);
    setNewZipCode((prevZipCode) => zipCode);
  };

  return (
    <>
     {showAlert && <GeneralAlert popupText={alertText} onClose={handleCloseAlert}/>}
    <div className="container">

      <h2>Zip Codes for Delivery</h2>
      <div className="create-new-list-section">
        <h2>Create New Area</h2>
        <div className="input-group mb-3">
          <span className="input-group-text">Area Name</span>
          <input
            style={{ maxWidth: "250px" }}
            value={newArea}
            type="text"
            className="form-control"
            onChange={(e) => setNewArea(e.target.value)}
            placeholder="Area Name"
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Area Price</span>
          <input
            style={{ maxWidth: "250px" }}
            value={newAreaPrice}
            className="form-control"
            onChange={(e) => setNewAreaPrice(e.target.value)}
            placeholder="Delivery Price"
          />
        </div>

        <button style={{ color: "black" }} onClick={addDeliveryArea}>
          Create
        </button>
      </div>
      <div className="add-zipcode-section">
        <h2>Add ZIP Code to List</h2>
        <select value={selectedArea || ""} onChange={handleAreaChange}>
          <option value="" disabled>
            Select a list
          </option>
          {uniqueAreaNames.map((area) => (
            <option key={area.Id} value={area.Id}>
              {area.Name}
            </option>
          ))}
        </select>
        {selectedAreaId != 0 && (
          <button
            style={{ marginLeft: "20px", color: "black" }}
            onClick={updateAreaPrice}
          >
            Update Price
          </button>
        )}
        {selectedAreaId != 0 && (
          <button
            style={{ marginLeft: "20px", color: "black" }}
            onClick={handleDeleteDeliveryArea}
          >
            Delete Area
          </button>
        )}
      </div>
      {updatePrice && (
        <div
          style={{
            backgroundColor: "#1f1d2b",
            color: "white",
            padding: "30px",
            //height: '40%',
            width: "400px",
            borderRadius: "10px",
            marginRight: "400px",
            marginTop: "-300px",
            //zIndex: 2
          }}
        >
          <h3
            style={{
              color: "#e7d2b7",
              borderBottom: "1px solid #e7d2b7",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            Update Delivery Price
          </h3>
          <div className="input-group mb-3">
            <span className="input-group-text" style={{ width: "170px" }}>
              New Delivery Price:
            </span>
            <input
              type="text"
              value={updatedDeliveryPrice}
              onChange={handleUpdateDeliveryPriceChange}
              className="form-control"
              placeholder="Delivery Price"
              autoFocus
            />
          </div>
          <button
            style={{
              backgroundColor: "#e7d2b7",
              color: "black",
              border: "none",
              borderRadius: "10px",
              padding: "5px",
              margin: "5px",
              fontSize: "18px",
              cursor: "pointer",
              width: "40%", // Add this line
            }}
            onClick={handleUpdateDeliveryPrice}
          >
            Update
          </button>
          <button
            style={{
              backgroundColor: "#e7d2b7",
              color: "black",
              border: "none",
              borderRadius: "10px",
              padding: "5px",
              margin: "5px",
              fontSize: "18px",
              cursor: "pointer",
              width: "40%", // Add this line
            }}
            onClick={handleCloseUpdatePrice}
          >
            Cancel
          </button>
        </div>
      )}
      {selectedAreaId !== 0 && (
        <div className="add-zipcode-section">
          <h2>Delivery Dates Info</h2>
          {deliveryDateInfo.length > 0 && (
            <table style={{ width: "60%", border: "1px solid black" }}>
              <thead>
                <tr
                  style={{ backgroundColor: "transparent", color: "#e7d2b7" }}
                >
                  <th>Delivery Date</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deliveryDateInfo.map((entry, index) => (
                  <tr key={index}>
                    <td>
                      <div className="input-group mb-3">
                        <input
                          style={{ maxWidth: "350px" }}
                          type="date"
                          value={
                            entry.Date === ""
                              ? new Date().toISOString().split("T")[0]
                              : entry.Date.split("T")[0]
                          }
                          min={new Date().toISOString().split("T")[0]}
                          onChange={(e) =>
                            handleDeliveryDateChange(index, e.target.value)
                          }
                          placeholder="Select Date"
                        />
                      </div>
                    </td>
                    <td style={{ width: "500px" }}>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          value={entry.Description}
                          onChange={(e) =>
                            handleDeliveryDescriptionChange(
                              index,
                              e.target.value
                            )
                          }
                          placeholder="Add Description"
                        />
                      </div>
                    </td>
                    <td
                      onClick={() => removeDateInfo(entry)}
                      style={{ cursor: "pointer", color: "red" }}
                    >
                      X
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <button
            onClick={handleAddEntry}
            style={{ margin: "10px", width: "100px", color: "black" }}
          >
            Add Date
          </button>
          {/* <MultiDatePicker
            value={selectedDates}
            onChange={handleDateChange}
            multiple
            format="DD/MM/YYYY"
          //maxDate={addDays(new Date(), 365)} // Set maxDate to allow selection up to one year in the future
          // Add other props as needed
          />*/}
          <button
            onClick={saveDeliveryDatesInfo}
            style={{ margin: "10px", width: "100px", color: "black" }}
          >
            Save{" "}
          </button>
        </div>
      )}
      {selectedAreaId !== 0 && (
        <div className="add-zipcode-section">
          <input
            value={newZipCode}
            onChange={zipCodeChangeHandler}
            maxLength={5}
            placeholder="Enter Zip Code"
          />

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept=".txt"
          />
          <button style={{ color: "black" }} onClick={addZipCodeToArea}>
            Add Zip Code to {selectedArea}
          </button>
        </div>
      )}
      {selectedArea !== null && zipCodeList.length > 0 && (
        <div>
          <h3>Existing ZIP Codes for {selectedArea}:</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="table-header">S. No.</th>
                <th className="table-header">Zip Code</th>
                <th className="table-header">Action</th>
              </tr>
            </thead>
            <tbody>
              {zipCodeList.map((zip, index) => (
                <tr key={index} style={{ paddingLeft: "5%" }}>
                  <td
                    className="my-table-cell fixed-width-cell"
                    style={{ width: "20%" }}
                  >
                    {++index}
                  </td>
                  <td className="my-table-cell fixed-width-cell">
                    {zip.ZipCode}
                  </td>
                  <td className="my-table-cell fixed-width-cell">
                    <button
                      style={{ color: "black" }}
                      onClick={() => deleteZipCode(zip.Id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </>
  );
};

export default DeliveryZipCodes;
