import React, { Component } from "react";
import "../styles/Homepage.css";
import OrderDetails from "./OrderDetails";
import { Inventory } from "./Inventory.js";
import Customer from "./Customer.js";
import { CustomerContext } from "../context/CustomerContext";
import Invoices from "./Invoices";
import GeneralAlert from "../components/GeneralAlert.js";

export class Order extends Component {
  static contextType = CustomerContext;

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "bakery", // Added a state variable to track the selected tab
      orders: [],
      customers: [],
      customerData: [],
      modalTitle: "",
      NameFirst: "",
      CustomerID: 0,
      InvoiceNo: "",
      Balance: "",
      PriceCode: "",
      DateOrd: "",
      showAlert: false,
      alertText: "",
      passwordPrompt: false,
      passwordIncorrect: false,
      allowCustomers: true,
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  changeTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  handleClosePasswordPrompt =()=>{
    this.setState({ passwordPrompt: false });
    this.setState({ allowCustomers: false });

  }
  handlePasswordSubmit = (event) => {
    event.preventDefault();
    // Replace this with your actual password validation logic
    const enteredPassword = event.target.password.value;
    if (enteredPassword === "3486") {
      this.setState({ passwordPrompt: false });
    this.setState({ allowCustomers: true });

    }else {
      this.setState({ passwordIncorrect: true });
    this.setState({ allowCustomers: false });

    }}
  
  closeAlert() {
    this.setState({ showAlert: false });
    this.setState({ passwordPrompt: true });
    this.setState({ allowCustomers: false });
  }

  getCustomerBalance = () => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      window.location.replace("/login/");
    }
    let id = localStorage.getItem("CustomerID").trim();
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "SavePaymentData/GetCustomerBalance/" +
        id,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
                window.location.replace("/login/");

        }
       return response.json()})
      .then((data) => {
        this.setState({ Balance: data });
        if(data > 0) {
          this.setState({ alertText: "You have a balance do you still want to place an order ?", showAlert: true });
        } else {
          this.setState({ allowCustomers: true}) 
        }
      });
  };

  getCustomerData = () => {
    const token = localStorage.getItem("authToken");
    if(token == null){
      window.location.replace("/login/");
    }
    let id = localStorage.getItem("CustomerID").trim();
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
                window.location.replace("/login/");

        }
        return response.json()})
      .then((data) => {
        this.setState({ customerData: data });
      });
  };

  getCustomerPriceCode = () => {
    let id = localStorage.getItem("CustomerID").trim();
    const token = localStorage.getItem("authToken");
    if(token == null){
      window.location.replace("/login/");
    }
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "Customer/GetPriceCodeByCustomerId/" +
        id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) =>{
        if(response?.status === 401){
          window.location.replace("/login/");

        }
      return  response.json()
      }
      )
      .then((data) => {
        this.setState({
          PriceCode: data.Price + ", Description: " + data.PriceDesc,
        });
      });
  };

  handleEdit(item) {
    alert("clicked");
  }

  getTabTitle = (customerNameFirst) => {
    // Add a parameter to your function to accept customerNameFirst
    console.log(customerNameFirst);
    const { selectedTab } = this.state;
    switch (selectedTab) {
      case "bakery":
        if (customerNameFirst == "Select Customer") {
          return `Select Customer`;
        } else {
          return `Order For ${customerNameFirst}`; // Use customerNameFirst in the return string
        }
      case "orderdetails":
        if (customerNameFirst == "Select Customer") {
          return `Select Customer`;
        } else {
          return `Order History For ${customerNameFirst}`; // Use customerNameFirst in the return string
        }
      case "customer":
        if (customerNameFirst == "Select Customer") {
          return `Select Customer`;
        } else {
          return `Customer Info For ${customerNameFirst}`; // Use customerNameFirst in the return string
        }
      default:
        return "";
    }
  };

  componentDidMount() {
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
    document.body.style.overflow = "auto";
    this.getCustomerData();
    this.getCustomerBalance();
    this.getCustomerPriceCode();
    const { customerContextValue } = this.context;
    // Retrieve the NameFirst and NameLast from local storage
    let NameFirst =
      localStorage.getItem("NameFirst") == "null"
        ? ""
        : localStorage.getItem("NameFirst");
    let NameLast =
      localStorage.getItem("NameLast") == "null"
        ? ""
        : localStorage.getItem("NameLast");

    // Combine NameFirst and NameLast
    //let FullName = NameFirst && NameLast ? `${NameFirst} ${NameLast}` : NameFirst;
    let FullName = `${NameFirst} ${NameLast}`;

    // Check if the customerContextValue is empty (customerContextValue is undefined or null)
    if (!customerContextValue) {
      // If FullName from local storage is also empty, set CustomerID to 0 and FullName to "Select Customer"
      if (!FullName) {
        this.setState({ CustomerID: 0, NameFirst: "Select Customer" });
      } else {
        // If FullName has a value, set CustomerID to 0 (since we don't have a customer ID in the context) and NameFirst to the value from local storage
        this.setState({ CustomerID: 0, NameFirst: FullName });
      }
    } else {
      // If customerContextValue is not empty, set CustomerID and NameFirst accordingly
      this.setState({
        CustomerID: customerContextValue.customerId,
        NameFirst: customerContextValue.customerNameFirst,
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "initial";
  }

  render() {
    const {
      selectedTab, // Use the selectedTab state variable
      customers,
      modalTitle,
      NameFirst,
      CustomerID,
    } = this.state;

    const displayedName = NameFirst == "null" ? "" : NameFirst;

    return (
      <>
       {this.state.showAlert && (
          <GeneralAlert
            popupText={this.state.alertText}
            onClose={this.closeAlert}
          />
        )}
        {!this.state.allowCustomers && (

  <div
  style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Change opacity as needed
    zIndex: 9998, // Set a lower z-index than your popup
  }}
></div>
        )}

{this.state.passwordPrompt && (

                    <div
                      style={{
                        backgroundColor: "#1f1d2b",
                        color: "white",
                        padding: "15px",
                        height: "30%",
                        width: "35%",
                        borderRadius: "10px",
                        border: "solid 2px #673F36",
                        position: "fixed",
                        top: "50%",
                        right: "5%",
                        // transform: "translate(-50%, -50%)",
                        backgroundColor: "#1f1d2b",
                        zIndex: 9999,
                      }}
                      className="password-prompt"
                    >
                      <form onSubmit={this.handlePasswordSubmit}>
                        <h5>Enter Password</h5>
                        <hr
                          style={{
                            border: "1px solid #673F36",
                            opacity: "1",
                          }}
                        />
                        <label>
                          Password:
                          <input
                            style={{ marginLeft: "15px" }}
                            autoFocus
                            type="password"
                            name="password"
                          />
                        </label>
                        <button
                          style={{
                            backgroundColor: "#e7d2b7",
                            color: "black",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px 5px 5px 15px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          style={{
                            background:"none",
                            color: "white",
                            textDecoration:"underline",
                            border: "none",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: "5px",
                            fontSize: "18px",
                            cursor: "pointer",
                            width: "40%", // Add this line
                          }}
                          onClick={this.handleClosePasswordPrompt}
                        >
                          Cancel
                        </button>
                      </form>
                      {/* Display a message if the password is incorrect */}
                      {this.state.passwordIncorrect && (
                        <p>Wrong password. Please try again.</p>
                      )}
                    </div>
  
                  )}
      <div className="outer-container">
        <div
          style={{
            paddingLeft: "0",
            position: "sticky",
            top: 69,
            zIndex: 9,
            background: "#252836",
          }}
        >
          <ul
            className="nav nav-tabs"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "95%",
              marginLeft: "12px",
              marginRight: "12px",
              paddingLeft: "140px",
              cursor:"pointer"
            }}
          >
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedTab === "bakery" ? "active" : ""
                }`}
                onClick={() => this.changeTab("bakery")}
              >
                Items
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedTab === "orderdetails" ? "active" : ""
                }`}
                onClick={() => this.changeTab("orderdetails")}
              >
                Order History
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedTab === "customer" ? "active" : ""
                }`}
                onClick={() => this.changeTab("customer")}
              >
                Customer Info
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedTab === "invoices" ? "active" : ""
                }`}
                onClick={() => this.changeTab("invoices")}
              >
                Payments
              </a>
            </li>
          </ul>
        </div>

        {selectedTab === "bakery" && (
          <Inventory
            balance={this.state.Balance}
            displayedName={displayedName}
            getTabTitle={this.getTabTitle}
            PriceCode={this.state.PriceCode}
            customerData={this.state.customerData}
          />
        )}
        {selectedTab === "orderdetails" && (
          <OrderDetails
            balance={this.state.Balance}
            displayedName={displayedName}
            getTabTitle={this.getTabTitle}
            PriceCode={this.state.PriceCode}
            customerData={this.state.customerData}
          />
        )}
        {selectedTab === "customer" && (
          <Customer
            balance={this.state.Balance}
            displayedName={displayedName}
            getTabTitle={this.getTabTitle}
            PriceCode={this.state.PriceCode}
            customerData={this.state.customerData}
          />
        )}
        {selectedTab === "invoices" && (
          <Invoices
            balance={this.state.Balance}
            displayedName={displayedName}
            getTabTitle={this.getTabTitle}
            PriceCode={this.state.PriceCode}
            customerData={this.state.customerData}
          />
        )}
      </div>
      </>
    );
  }
}
