import React, { Component } from 'react';
import '../styles/Homepage.css';
import DeliveryZipCodes from './DeliveryZipCodes';
import BulkUpdater from './BulkUpdater';
import Categories from './Categories';

class Settings extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "deliveryzipcodes", // Keep the initial state in sync with the nav tabs
    };
  }

  changeTab = (tab) => {
    this.setState({ selectedTab: tab });
  }

  render() {
    const { selectedTab } = this.state;

    return (
      <div className="outer-container">
        <div className="title-date-container">
          <div>
            <h1>Settings</h1>
            <span>
              {new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
              {'  -  '}
              {new Date().toLocaleDateString('he-IL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </span>
          </div>
        </div>

        <ul className="nav nav-tabs" style={{ paddingTop: '10px' }}>
          <li className="nav-item">
            <a className={`nav-link ${selectedTab === 'deliveryzipcodes' ? 'active' : ''}`} onClick={() => this.changeTab("deliveryzipcodes")}>Zip Codes</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${selectedTab === 'bulkupdater' ? 'active' : ''}`} onClick={() => this.changeTab("bulkupdater")}>Bulk Updater</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${selectedTab === 'categories' ? 'active' : ''}`} onClick={() => this.changeTab("categories")}>Categories</a>
          </li>
          
        </ul>
        
        {selectedTab === 'deliveryzipcodes' && <DeliveryZipCodes />}
        {selectedTab === 'bulkupdater' && <BulkUpdater />}
        {selectedTab === 'categories' && <Categories />}
      </div>
    )
  }
}

export default Settings;