import { Home } from './pages/Home';
import { Inventory } from './pages/Inventory';
import { Order } from './pages/Order';
import React, { useState, useContext, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import logoLoginPage from './images/logoLoginPage.png';
import LoginPage from './pages/LoginPage';
import PlaceOrder from './pages/PlaceOrder'
import CartContext from "./context/CartContext";
import PriceCodeDataContext from "./context/PriceCodeDataContext";
import PaymentsContext from "./context/PaymentsContext";
import { CustomerProvider } from './context/CustomerContext';
import Cart from "./pages/Cart";
import './styles/App.css';
import Customer from './pages/Customer';
import ShowOrders from './pages/ShowOrders';
import { BsGear } from "react-icons/bs";
import Settings from './pages/Settings';
import { TotalPriceProvider } from './context/TotalPriceContext';

// UPDATED ICONS
import { CiLogout } from "react-icons/ci";



export const AuthContext = createContext();


// PrivateRoute component
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

function App() {

  const [cart, setCart] = useState([]);
  const [payments, setPayments] = useState([]);
  const [priceCodeData, setPriceCodeData] = useState([]);
  const [loggedIn, setLoggedIn] = useState(true);
  const [logInID, setLogInID] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [priceCode, setPriceCode] = useState('1');

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = async () => {
    localStorage.clear();
    setLoggedIn(false);
  };

  useEffect(() => {
    setLogInID(localStorage.getItem('logInID'));
  }, [loggedIn]); // update when loggedIn state changes

  const logoClickHandler = () => {
    setPayments([]);
  };

  return (
    <TotalPriceProvider>
      <AuthContext.Provider value={{ loggedIn, setLoggedIn, handleLogin, handleLogout }}>
        <CustomerProvider>
          <PriceCodeDataContext.Provider value={[priceCodeData, setPriceCodeData]}>
            <PaymentsContext.Provider value={[payments, setPayments]}>
              <CartContext.Provider value={[cart, setCart]}>
                <Router>
                  <div className="App container">
                    <div className="sidebar updated-sidebar" style={{position:"sticky", top:0, zIndex:10 , background:"#51535E", marginTop:0}}>
                      <ul className="navbar-nav navbar-updated">
                        {/* <li className="nav-item m-1" style={{ paddingLeft: '13px', paddingBottom: '30px', paddingTop: '-70' }}>
                          <NavLink to="/home" onClick = {logoClickHandler} style={{ backgroundColor: 'transparent', border: 'none', padding: 0, display: 'inline-block' }}>
                            <img
                              className="card-img-top rounded"
                              src={logoLoginPage}
                              alt="Product"
                              style={{ width: '70px', height: '70px' }}                              
                            />
                          </NavLink>
                        </li>

                        <li className="nav-item m-1">
                          <NavLink className="btn " activeClassName="active" to="/settings">
                            <BsGear />
                          </NavLink>
                        </li> */}

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <li className="nav-item m-1" style={{ paddingLeft: '13px', paddingBottom: '30px', paddingTop: '-70' }}>
                            <NavLink to="/home" onClick={logoClickHandler} style={{ backgroundColor: 'transparent', border: 'none', padding: 0, display: 'inline-block' }}>
                              <img
                                className="card-img-top rounded navbar-logo"
                                src={logoLoginPage}
                                alt="Product"
                                style={{ width: '70px', height: '70px' }}
                              />
                            </NavLink>
                          </li>

                          <li className="nav-item m-1">
                            <NavLink className="btn settings-logo-div" activeClassName="active" to="/settings">
                              <BsGear className='settings-logo' />
                            </NavLink>
                          </li>
                        </div>



                        {loggedIn && (
                          <li className="nav-item m-1">
                            <div className="btn logout-container" onClick={toggleDropdown}>
                              <div className='navbar-admin' style={{ color: 'white' }}>{logInID}</div>
                              {isDropdownOpen && (
                                <>
                                  <div className='dropdown-content-main'>
                                    <div className="dropdown-content logout-dropdown">
                                      <button
                                        className="btn btn-primary mt-2"
                                        onClick={handleLogout}
                                        style={{ background: 'transparent', border: 'none' }}>
                                        {/* <CiLogout className='logout-logo'/> */}
                                        Logout
                                      </button>
                                    </div>
                                  </div>

                                </>

                              )}
                            </div>
                          </li>
                        )}
                      </ul>
                      <div>
                      </div>
                    </div>
                    <div className="layout">
                      <div className="main-content">
                        <div >
                        {/* <hr style={{position:"sticky", top:96, margin:"0px !important"}}/> */}
                          {/* <Redirect to="/home" /> */}
                          <Switch>
                            <Route path="/login">
                              <LoginPage handleLogin={handleLogin} handleLogout={handleLogout} />
                            </Route>
                            <PrivateRoute path="/home" component={Home} />
                            <PrivateRoute path="/bakery" component={Inventory} />
                            <PrivateRoute path="/customer" component={Customer} />
                            <PrivateRoute path="/order" component={Order} />
                            <PrivateRoute path="/showorders" component={ShowOrders} />
                            <PrivateRoute path="/placeorder" component={PlaceOrder} />
                            <PrivateRoute path="/cart" component={Cart} />
                            <PrivateRoute path="/settings" component={Settings} />
                            <Redirect to="/login" />
                          </Switch>
                        </div>



                      </div>
                    </div>
                  </div>
                </Router>
              </CartContext.Provider>
            </PaymentsContext.Provider>
          </PriceCodeDataContext.Provider>
        </CustomerProvider>
      </AuthContext.Provider>
    </TotalPriceProvider>
  );
}

export default App;
