import React, { useState, useEffect, useContext } from 'react';  // Added useContext
import { CustomerContext } from '../context/CustomerContext';
import { useHistory } from 'react-router-dom';

function Invoices() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { customerId } = useContext(CustomerContext);
  const history = useHistory()
  let count = 1;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}SavePaymentData/GetAllPayments/${customerId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if(response?.status === 401){
          history.replace("/login/");
        }
        // Check if response is OK
        if (!response?.ok) {
          const text = await response.text();
          throw new Error(text || 'Network response was not ok');
        }

        const data = await response.json();
        setData(data);
        setLoading(false);

      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchOrderDetails();  // This invokes the function to fetch the order details

  }, [customerId]);  // Added customerId as a dependency

  function extractDate(datetimeString) {
    const timestamp = Date.parse(datetimeString);

    if (!isNaN(timestamp)) {
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Month is 0-based, so add 1 to get the correct month
      const day = date.getDate();

      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div style={{ paddingLeft: '100px', marginTop:"50px", paddingTop: '10px' }}>
        <table style={{ width: '80%', border: '1px solid black' }}>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Invoice No</th>
              <th>Credit Date</th>
              <th>Credit Amount</th>
              <th>Discount</th>
              <th>Check No</th>
              <th>Payment Type</th>
              <th>Check Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map(payment => (
              <React.Fragment key={count}>
                <tr key={payment.CustomerID}>
                  <td>{(count++).toString()}</td>
                  <td>{payment.InvoiceNo}</td>
                  <td>{extractDate(payment.CreditDate)}</td>
                  <td>{payment.CreditAmt}</td>
                  <td>{payment.Discount}</td>
                  <td>{payment.CheckNo}</td>
                  <td>{payment.PayType}</td>
                  <td>{extractDate(payment.CheckDate)}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Invoices;
