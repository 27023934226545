import React, { useEffect, useState, useContext } from "react";
import { CustomerContext } from "../context/CustomerContext";
import CartContext from "../context/CartContext";
import PaymentsContext from "../context/PaymentsContext";
import { useTotalPrice } from "../context/TotalPriceContext";
import Cart from "./Cart";
import PlaceOrder from "./PlaceOrder";
import LoadingSpinner from "./LoadingSpinner";
import AlertPopup from "../components/AlertPopup";
import GeneralAlert from "../components/GeneralAlert";
import { OrderStatusEnum } from "../data/OrderStatusEnum";
import { useHistory } from "react-router-dom";
import StatusPopup from "../components/StatusPopup";
import PriceCodeDataContext from "../context/PriceCodeDataContext";
import PopupModal from "../components/PopupModal";

const OrderDetails = () => {
  const [order, setOrder] = useState([]);
  const [orderPayments, setOrderPayments] = useState([]);
  const [orderDelete, setOrderDelete] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { customerId } = useContext(CustomerContext);
  const [payments, setPayments] = useContext(PaymentsContext);
  const [isEditOrder, setIsEditOrder] = useState(false);
  const [cart, setCart] = useContext(CartContext);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [singleCustomer, setSingleCustomer] = useState(null);

  const savedTotalPrice = localStorage.getItem("savedTotalPrice");
  const { priceCode: PriceCodeFromContext, setPriceCode } =
    useContext(CustomerContext);
  const [payBalance, setPayBalance] = useState(false);
  const { totalPrice, setTotalPrice } = useTotalPrice(); // use the custom hook
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [quantityMultiple, setQuantityMulitple] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [priceCodeData, setPriceCodeData] = useContext(PriceCodeDataContext);
  const [popupShown, setPopupShown] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [singlePopupInvoice, setSinglePopupInvoice] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [generalAlert, setGeneralAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showChangePopup, setShowChangePopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/New_York",
  };

  const handleActivePopup = (invoiceNumber, alertShow, discount) => {
    if (alertShow) {
      setAlertText(`Please write order # ${invoiceNumber} on all checks`);
      setShowAlert(true);
      // this.setState({ alertText: `Please write order # ${invoiceNumber} on all checks`, showAlert: true });
    }
    setPopupShown(true);
    setTotalDiscount(discount);
    setSinglePopupInvoice(invoiceNumber);
    // this.setState({ popupShown: true });
    // this.setState({ totalDiscount: discount });
    // this.setState({ singlePopupInvoice: invoiceNumber });
  };
  const getTotalCartQuantity = (cartItems) => {
    let TotalQuantity = 0;
    cartItems.forEach((cartItem) => (TotalQuantity += cartItem.Qty));
    return TotalQuantity;
  };

  const handleCloseAlert = () => {
    setGeneralAlert(false);
  };
  const handleCloseStatusPopup = () => {
    setShowChangePopup(false);
  };
  const getEstTime = (date) => {
    if(date!==null){

      const londonTime = new Date(date); // Assuming item.Date is your London time
      const estTime = new Date(londonTime.getTime()); // Subtracting 5 hours for EST
      const estFormattedTime = estTime.toLocaleString("en-US", options);
      return estFormattedTime;
    }else {
      return null
    }
  };
  const removePayment = (payment) => {
    //console.log(payment);
    if (window.confirm("Are you sure you want to delete this payment?")) {
      setPayments((prevPayments) => {
        return prevPayments.filter((item) => item.id !== payment.id);
      });
      setTotalPrice((prevTotalPrice) => {
        let num1 = parseFloat(prevTotalPrice).toFixed(2);
        let num2 = parseFloat(payment.amount).toFixed(2);
        return parseFloat(num1) + parseFloat(num2);
      });
    }
  };
  const history = useHistory();
  const handlelabelCheckedBack = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/PrintLabelStoreBackById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if (response?.status === 401) {
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    console.log("Printing label...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const handlelabelCheckedFront = async () => {
    // Call label API
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/PrintLabelStoreFrontById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if (response?.status === 401) {
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    console.log("Printing label...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };
  const handleEmailReceipt = async () => {
    // Call label API
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/EmailOrderById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if (response?.status === 401) {
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    console.log("Emailing receipt...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };
  const handlePrintReceipt = async () => {
    // Call label API
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "invoice/PrintReceiptById",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ invoiceNo: selectedOrder }),
        }
      );
      if (response?.status === 401) {
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
    }
    // Call print API
    console.log("Printing receipt...");

    setSuccessMessage("Done successfully");
    // Reset successMessage after 15 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 1500);
  };

  const handleHideAlert = () => {
    setShowAlert(false);
  };

  const getPriceCodeDescription = (priceCode) => {
    const codeData = priceCodeData.find((data) => data.Price == priceCode);
    if (codeData) {
      return codeData.PriceDesc;
    } else {
      return null;
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      setIsLoading(true);
      setNoData(false);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}invoices/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 401) {
        history.replace("/login/");
      }
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      } else {
        let data = await response.json();
        // Sort the data by Date in descending order
        // data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        // Calculate the total price for each order
        data.forEach((order) => {
          order.totalPrice = order.InvoiceDetails.reduce(
            (total, detail) => total + detail.PriceTot,
            0
          );
        });
        //console.log(data);
        setNoData((val) => true);
        setIsLoading((val) => false);
        console.log(data);
        setOrder((prevOrder) => data);
        setCart((prevCart) => []);
      }

      const cust_response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${customerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (cust_response?.status === 401) {
        history.replace("/login/");
      }
      if (cust_response?.ok) {
        let cust_data = await cust_response.json();
        setSingleCustomer(cust_data[0]);
      }
    } catch (error) {
      console.error("Error fetching order details", error);
      setIsLoading((val) => false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [customerId]);

  useEffect(() => {
    fetchOrderDetails();
  }, [customerId]);

  useEffect(() => {
    if (noData && order.length === 0) {
      setIsLoading((val) => false);
    }
  }, [noData, isLoading, order]);

  const handleOrderSelect = async (item) => {
    console.log(item);
    setDeliveryDate(item.DeliveryDate);
    let totalQuantity = item.InvoiceDetails.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.Qty;
      },
      0
    );

    const isMultipleOf20WithRemainder = totalQuantity % 20 !== 0;
    setQuantityMulitple((val) => {
      if (isMultipleOf20WithRemainder) {
        return parseInt(totalQuantity / 20) + 1;
      } else {
        return parseInt(totalQuantity / 20);
      }
    });
    let invoiceNo = item.InvoiceNo;
    if (!orderDelete) {
      if (selectedOrder === invoiceNo) {
        setSelectedOrder(null);
      } else {
        const token = localStorage.getItem("authToken");
        if (token == null) {
          history.replace("/login/");
        }
        const items = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}SavePaymentData/GetPaymentsByInvoiceNo/${invoiceNo}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (items?.status === 401) {
          history.replace("/login/");
        }
        if (!items?.ok) {
          throw new Error(`HTTP error! status: ${items?.status}`);
        } else {
          let data = await items.json();
          setOrderPayments((prevPayments) => data);
        }
        setSelectedOrder((val) => invoiceNo);
      }
    } else {
      setOrderDelete(false);
    }
  };

  const removeOrder = (invoiceNo) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      const token = localStorage.getItem("authToken");
      if (token == null) {
        history.replace("/login/");
      }
      setOrderDelete((val) => true);
      let id = invoiceNo;
      fetch(process.env.REACT_APP_API_ENDPOINT + "invoice/" + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res?.status === 401) {
            history.replace("/login/");
          }
          return res.json();
        })
        .then(
          (result) => {
            setAlertText(result);
            setGeneralAlert(true);
            // alert(result);
            fetchOrderDetails();
          },
          (error) => {
            setAlertText("Failed");
            setGeneralAlert(true);
            // alert("Failed");
          }
        );
    }
  };

  useEffect(() => {
    console.log(cart);
  }, [cart]);

  const addAllToCart = (invoiceDetails, setCart) => {
    let newCart = [];
    let tempPriceCode;
    console.log(invoiceDetails);
    invoiceDetails.forEach((detail) => {
      const newItem = {
        StyleNo: `${detail.StyleNo}`,
        StyleDesc: detail.StyleDesc,
        PriceList: detail.Price,
        Price: detail.Price,
        PriceCode: detail.PriceCode,
        name: detail.StyleDesc,
        discount: detail.Qty * detail.OriginalPrice - detail.Qty * detail.Price,
        price: detail.OriginalPrice,
        quantity: detail.Qty,
      };
      tempPriceCode = newItem.PriceCode;
      newCart = [...newCart, newItem];
    });

    setCart(newCart);
    setPriceCode(tempPriceCode);
  };

  const addAllToCartForEdit = (
    invoiceDetails,
    subaccount,
    balance,
    shipVia,
    orderDate,
    comment,
    orderStatus,
    Freight,
    ShipAdrs1,
    ShipAdrs2,
    ShipCity,
    ShipState,
    ShipZip,
    cart,
    setCart
  ) => {
    console.log(orderStatus);
    console.log(ShipAdrs1, ShipAdrs2, ShipZip);
    if (orderStatus !== undefined) {
      localStorage.setItem("orderStatus", orderStatus);
    }
    let newCart = [];
    let tempPriceCode;
    invoiceDetails.forEach((detail) => {
      const newItem = {
        StyleNo: `${detail.StyleNo}`,
        StyleDesc: detail.StyleDesc,
        IDTno: detail.IDTno,
        Date: orderDate,
        InvoiceNo: detail.InvoiceNo,
        PriceCode: detail.PriceCode,
        OriginalPrice: detail.OriginalPrice,
        discount: detail.Qty * detail.OriginalPrice - detail.Qty * detail.Price,
        Price: detail.Price,
        Freight: Freight,
        ShipAdrs1: ShipAdrs1,
        ShipAdrs2: ShipAdrs2,
        ShipCity: ShipCity,
        ShipState: ShipState,
        ShipZip: ShipZip,
        SubAccount: subaccount,
        Comment: comment,
        PriceList: detail.Price,
        name: detail.StyleDesc,
        price: detail.Price,
        Balance: balance,
        quantity: detail.Qty,
      };
      tempPriceCode = newItem.PriceCode;
      newCart = [...newCart, newItem];
    });
    setCart((prevCart) => newCart);
    setPriceCode((prevPriceCode) => tempPriceCode);
    localStorage.setItem("balance", balance);
    localStorage.setItem("shipvia", shipVia);
    setIsEditOrder((val) => true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getOrderEnum = (order) => {
    const orderEnum = OrderStatusEnum.find(
      (singleEnum) => singleEnum.id == order
    );
    return orderEnum.name;
  };

  const addToCart = (inventoryItem, setCart) => {
    let tempPriceCode;
    const newItem = {
      ...inventoryItem,
      StyleNo: `${inventoryItem.StyleNo}`,
      name: inventoryItem.StyleDesc,
      price: inventoryItem.Price,
      PriceList: inventoryItem.Price,
      PriceCode: inventoryItem.PriceCode,
      originalPrice: inventoryItem.OriginalPrice,
      discount:
        inventoryItem.Qty * inventoryItem.OriginalPrice -
        inventoryItem.Qty * inventoryItem.Price,
      quantity: inventoryItem.Qty,
    };
    tempPriceCode = newItem.PriceCode;
    const existingItem = cart.find((item) => item.StyleNo === newItem.StyleNo);
    if (existingItem) {
      const updatedCart = cart.map((item) =>
        item.StyleNo === existingItem.StyleNo
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      setCart(updatedCart);
    } else {
      setCart((currentCart) => [...currentCart, newItem]);
    }
    setPriceCode(tempPriceCode);
  };

  const payBalanceHandler = (balance) => {
    let totalBalance = 0;

    setSelectedData((prevSelectedData) => {
      // Create a new array containing objects with just the InvoiceNo and Balance
      const newSelectedData = selectedRows.map((selectedInvoiceNo) => {
        const selectedInvoice = order.find(
          (invoice) => invoice.InvoiceNo === selectedInvoiceNo
        );
        return {
          invoiceNo: selectedInvoice.InvoiceNo,
          balance: parseFloat(selectedInvoice.Balance).toFixed(2),
        };
      });
      return newSelectedData;
    });
    // Iterate through invoices array
    order.forEach((invoice) => {
      // Check if the InvoiceNo exists in selectedRows
      if (selectedRows.includes(invoice.InvoiceNo)) {
        // Add the balance to the totalBalance
        totalBalance += invoice.Balance;
      }
    });
    localStorage.setItem(
      "savedTotalPrice",
      parseFloat(totalBalance).toFixed(2)
    );
    //console.log(balance);
    setTotalPrice(parseFloat(totalBalance).toFixed(2));
    setPayBalance((val) => true);
  };

  const handleStatusPopup = (o_status, invoice_no) => {
    console.log(o_status);
    setShowChangePopup(true);
    setSelectedOrder(invoice_no);
    if (o_status === null) {
      setCurrentStatus(-1);
    } else {
      setCurrentStatus(o_status);
    }
  };

  const getDiscountOfInvoiceDetails = (items) => {
    let discount = 0;
    items.forEach(
      (invoiceItem) =>
        (discount +=
          (invoiceItem.DiscountPercent / 100) *
          invoiceItem.OriginalPrice *
          invoiceItem.Qty)
    );
    return discount;
  };

  useEffect(() => {
    if (currentStatus !== null) {
      setShowChangePopup(true);
    }
  }, [currentStatus]);

  const handleCheckboxChange = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(row.InvoiceNo)) {
        // If the row is already selected, remove it (uncheck the checkbox)
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow !== row.InvoiceNo
        );
      } else {
        // If the row is not selected, add it (check the checkbox)
        return [...prevSelectedRows, row.InvoiceNo];
      }
    });

    // The rest of your logic remains unchanged
    const totalBalance = selectedRows.reduce(
      (sum, selectedRow) => sum + selectedRow.Balance,
      0
    );

    console.log("Total Balance:", totalBalance);
    setTotalPrice((balance) => totalBalance);
  };

  const handleShowAlert = (discount) => {
    setTotalDiscount(discount);
    setShowAlert(true);
  };
  const handleCancelBalancePayment = () => {
    setCart((prev) => []);
    setPayBalance((val) => false);
  };
  const hasRows = order.length > 0;
  const handleInActivePopup = () => {
    setPopupShown(false);
  };
  return (
    <>
      {showAlert ? (
        <AlertPopup
          onClose={handleHideAlert}
          popupText={`Order Updated Successfully with a total discount of $${totalDiscount} on complete order`}
          discount={totalDiscount}
        />
      ) : null}
      {generalAlert && (
        <GeneralAlert popupText={alertText} onClose={handleCloseAlert} />
      )}
      {popupShown && singlePopupInvoice ? (
        <PopupModal
          onClose={handleInActivePopup}
          invoiceNo={singlePopupInvoice}
          discount={totalDiscount}
        />
      ) : null}

      {showChangePopup && (
        <StatusPopup
          onClose={handleCloseStatusPopup}
          currentStatus={currentStatus}
          invoiceNo={selectedOrder}
          refetch={fetchOrderDetails}
        />
      )}
      <CartContext.Consumer>
        {([cart, setCart]) => (
          <>
            {selectedRows.length > 0 && (
              <div style={{ position: "fixed" }}>
                <button
                  onClick={payBalanceHandler}
                  style={{
                    position: "fixed",
                    right: "10vw",
                    backgroundColor: "#252836",
                    color: "white",
                    padding: "3px 7px", // Reduced padding
                    fontSize: "16px", // Reduced font size
                    border: "2px solid #2E8B57", // Added a slightly darker border
                    borderRadius: "3px",
                    cursor: "pointer",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                    transition: "all 0.3s ease", // Smooth transition for hover effects
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)"; // Slightly enlarge button on hover
                    e.currentTarget.style.boxShadow =
                      "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)"; // Reset size on mouse out
                    e.currentTarget.style.boxShadow =
                      "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                  }}
                >
                  Pay Selected Balance
                </button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                marginLeft: "80px",
                marginTop: "50px",
                paddingLeft: "10px",
                paddingTop: "10px",
              }}
            >
              {order.length > 0 ? (
                <table style={{ width: "90%", border: "1px solid black" }}>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "transparent",
                        color: "#e7d2b7",
                      }}
                    >
                      <th></th>
                      <th>Order No</th>
                      <th>CustomerID</th>
                      <th>Order Date</th>
                      <th>Delivery Date</th>
                      <th>Type</th>
                      <th>Price Code Desc</th>
                      <th>Sub Account</th>
                      <th>Total Price</th>
                      <th>Order Status</th>
                      <th>Total Discount</th>
                      <th>Car No</th>
                      <th>Balance</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr
                          onClick={() => handleOrderSelect(item)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.Balance.toFixed(2) <= 0.0 ? (
                            <td></td>
                          ) : (
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.find(
                                  (selectedRow) =>
                                    selectedRow.InvoiceNo === item.InvoiceNo
                                )}
                                onChange={() => handleCheckboxChange(item)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </td>
                          )}
                          <td>{item.InvoiceNo}</td>
                          <td>{item.CustomerID}</td>
                          {/* <td>{(new Date(item.Date)).toLocaleString('en-US', options)}</td> */}

                          {/* <td>{getEstTime(item.Date)}</td> */}
                          <td>{getEstTime(item.TimeDT)}</td>
                          {/* <td>{formatDate(item.Date)}</td> */}
                          <td>
                            {item.DeliveryDate
                              ? formatDate(item.DeliveryDate)
                              : ""}
                          </td>
                          <td>{item.ShipVia}</td>
                          <td>{getPriceCodeDescription(item.PriceCode)}</td>
                          <td>{item.SubAccount}</td>
                          {/* <td>${item.ShipVia === 'Delivery' ? (parseFloat(item.InvoiceDetails[0]?.PriceTot.toFixed(2)) + (item.Freight == null ? parseFloat(0.00) : parseFloat(item.Freight.toFixed(2) * quantityMultiple))) : parseFloat(item.InvoiceDetails[0]?.PriceTot).toFixed(2)}</td> */}
                          <td>
                            ${parseFloat(item.OrderTotalPrice).toFixed(2)}
                          </td>

                          <td>
                            {item.OrderStatus
                              ? getOrderEnum(item.OrderStatus)
                              : ""}
                          </td>
                          <td>
                            $
                            {parseFloat(
                              getDiscountOfInvoiceDetails(item.InvoiceDetails)
                            ).toFixed(2)}
                          </td>
                          <td>{item.CarNo}</td>
                          <td>
                            $
                            {item.Balance >= 0
                              ? item.Balance.toFixed(2)
                              : "0.00"}
                          </td>
                          {/* {item.Balance == 0 && (<td onClick={() => removeOrder(item.InvoiceNo)} style={{ cursor: 'pointer', color: 'red' }}>X</td>)} */}
                          <td>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Stop propagation here
                                handleStatusPopup(
                                  item.OrderStatus,
                                  item.InvoiceNo
                                );

                                // Add your button click logic here
                              }}
                              style={{
                                width: "130px",
                                padding: "5px",
                                color: "black",
                              }}
                            >
                              Change Status
                            </button>
                          </td>
                        </tr>
                        {!orderDelete &&
                          selectedOrder === item.InvoiceNo &&
                          item.InvoiceDetails && (
                            <>
                              <tr>
                                <td colSpan="10">
                                  <div
                                    style={{
                                      minHeight: "200px",
                                      cursor: "pointer",
                                      border: "1px solid #e7d2b7",

                                      width: "fit-content",
                                      boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <table
                                      style={{
                                        width: "40vw",
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>Qty</th>
                                          <th>DisPct</th>
                                          <th>PriceList</th>
                                          <th>Price</th>
                                          <th>PriceTot</th>
                                          <th>Actions</th>{" "}
                                          {/* New Cell Header */}
                                        </tr>
                                        <td
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                          colSpan="10"
                                        >
                                          <hr
                                            style={{
                                              marginTop: 0,
                                              marginBottom: 0,
                                            }}
                                          />
                                        </td>
                                      </thead>
                                      <tbody>
                                        {item.InvoiceDetails.map(
                                          (detail, detailIndex) => (
                                            <tr key={detailIndex}>
                                              <td
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {detail.StyleDesc}
                                              </td>
                                              <td>{detail.Qty}</td>
                                              <td>{detail.DiscountPercent}%</td>
                                              <td>
                                                $
                                                {detail.PriceList &&
                                                  detail.PriceList.toFixed(2)}
                                              </td>
                                              <td>
                                                $
                                                {detail.Price &&
                                                  detail.Price.toFixed(2)}
                                              </td>
                                              <td>
                                                $
                                                {detail.PriceTot &&
                                                  detail.PriceTot.toFixed(2)}
                                              </td>
                                              <td>
                                                <button
                                                  onClick={() =>
                                                    addToCart(detail, setCart)
                                                  }
                                                  style={{
                                                    width: "70px",
                                                    margin: "0",
                                                    backgroundColor:
                                                      "transparent",
                                                    color: "white",
                                                    padding: "3px 7px", // Reduced padding
                                                    fontSize: "10px", // Reduced font size
                                                    border: "2px solid #2E8B57", // Added a slightly darker border
                                                    borderRadius: "3px",
                                                    cursor: "pointer",
                                                    boxShadow:
                                                      "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                                    transition: "all 0.3s ease", // Smooth transition for hover effects
                                                  }}
                                                  onMouseOver={(e) => {
                                                    e.currentTarget.style.transform =
                                                      "scale(1.05)"; // Slightly enlarge button on hover
                                                    e.currentTarget.style.boxShadow =
                                                      "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                                                  }}
                                                  onMouseOut={(e) => {
                                                    e.currentTarget.style.transform =
                                                      "scale(1)"; // Reset size on mouse out
                                                    e.currentTarget.style.boxShadow =
                                                      "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                                                  }}
                                                >
                                                  Add to Cart
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                        {item.ShipVia === "Delivery" && (
                                          <>
                                            <tr>
                                              <td>
                                                <hr />
                                              </td>
                                              <td>
                                                <hr />
                                              </td>
                                              <td>
                                                <hr />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Delivery Charges</td>
                                              <td></td>
                                              <td>
                                                $
                                                {(
                                                  item?.Freight
                                                )?.toFixed(2)}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                        <tr>
                                          <td colSpan="2">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <label
                                                htmlFor="total"
                                                style={{ fontSize: "16px" }}
                                              >
                                                Total Quantity:
                                              </label>
                                              <span
                                                style={{
                                                  fontSize: "20px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {getTotalCartQuantity(
                                                  item.InvoiceDetails
                                                )}
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan="1"></td>
                                          <td>
                                            <button
                                              onClick={() =>{

                                                localStorage.setItem('oDelivery', item.DeliveryDate);
                                                addAllToCartForEdit(
                                                  item.InvoiceDetails,
                                                  item.SubAccount,
                                                  item.Balance,
                                                  item.ShipVia,
                                                  item.DeliveryDate,
                                                  item.Comment,
                                                  item.OrderStatus,
                                                  item.Freight,
                                                  item.ShipAdrs1,
                                                  item.ShipAdrs2,
                                                  item.ShipCity,
                                                  item.ShipState,
                                                  item.ShipZip,
                                                  cart,
                                                  setCart
                                                  )
                                                }
                                              }
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                margin: "0",

                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              // disabled={
                                              //   item.OrderStatus === 8 ||
                                              //   item.OrderStatus === 10
                                              // }
                                            >
                                              Edit Order
                                            </button>
                                          </td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                addAllToCart(
                                                  item.InvoiceDetails,
                                                  setCart
                                                )
                                              }
                                              style={{
                                                backgroundColor: "transparent",
                                                margin: "0",

                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                width: "100px",
                                                height: "30px",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                            >
                                              Add All to Cart
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <hr></hr>
                                    <div>
                                      <table
                                        style={{
                                          width: "40vw",
                                        }}
                                      >
                                        <thead>
                                          <tr style={{ fontSize: "14px" }}>
                                            <td
                                              style={{
                                                paddingBottom: "0",
                                                paddingTop: "0",
                                              }}
                                            >
                                              <strong>Payments</strong>
                                            </td>
                                          </tr>

                                          <tr>
                                            <th>Id</th>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Amount</th>{" "}
                                            {/* New Cell Header */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orderPayments.map(
                                            (payment, index) => (
                                              <tr key={index}>
                                                <td>{++index}</td>
                                                <td>
                                                  {formatDate(
                                                    payment.CreditDate
                                                  )}
                                                </td>
                                                <td>{payment.PayType}</td>
                                                <td>
                                                  $
                                                  {payment.CreditAmt.toFixed(2)}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>

                                <td colSpan="2">
                                  <div
                                    style={{
                                      minHeight: "200px",
                                      cursor: "pointer",
                                      border: "1px solid #e7d2b7",

                                      padding: "15px",
                                      width: "300px",
                                      boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <p>
                                      <h3
                                        style={{
                                          color: "#e7d2b7",
                                          borderBottom: "1px solid #e7d2b7",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        Shipping Info
                                      </h3>
                                    </p>
                                    {singleCustomer ? (
                                      <>
                                        <p>
                                          <strong>Via:</strong> {item?.ShipVia}
                                        </p>
                                        <p>
                                          <strong>Type:</strong>{" "}
                                          {item?.ShipType}
                                        </p>
                                        <p>
                                          <strong>City:</strong>{" "}
                                          {item?.ShipCity
                                            ? item?.ShipCity
                                            : singleCustomer.City}
                                        </p>
                                        <p>
                                          <strong>State:</strong>{" "}
                                          {item?.ShipState
                                            ? item?.ShipState
                                            : singleCustomer.State}
                                        </p>
                                        <p>
                                          <strong>Address1:</strong>{" "}
                                          {item?.ShipAdrs1
                                            ? item?.ShipAdrs1
                                            : singleCustomer.Address1}
                                        </p>
                                        <p>
                                          <strong>Address2:</strong>{" "}
                                          {item?.ShipAdrs2
                                            ? item?.ShipAdrs2
                                            : singleCustomer.Address2}
                                        </p>
                                        <p>
                                          <strong>Zip:</strong>{" "}
                                          {item?.ShipZip
                                            ? item?.ShipZip
                                            : singleCustomer.ZipCode}
                                        </p>
                                        <p>
                                          <strong>Total Price:</strong>{" "}
                                          {item?.totalPrice}
                                        </p>
                                      </>
                                    ) : (
                                      <p>
                                        <strong>No Shipping Info</strong>
                                      </p>
                                    )}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="7">
                                  <table
                                    style={{
                                      width: "42vw",

                                      border: "1px solid #e7d2b7",
                                      borderRadius: "12px",
                                    }}
                                  >
                                    <tbody>
                                      {/* <tr>
                                        <td colSpan="3"></td>
                                      </tr> */}

                                      {successMessage === null ? (
                                        <tr>
                                          <td>
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handlePrintReceipt}
                                            >
                                              Print Receipt
                                            </button>
                                          </td>
                                          <td>
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handleEmailReceipt}
                                            >
                                              Email Receipt
                                            </button>
                                          </td>
                                          {/* <td>{singleCustomer?.NameLast}</td> */}
                                          <td>
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "160px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handlelabelCheckedFront}
                                            >
                                              Print Label and Pick Up
                                            </button>
                                          </td>
                                          <td>
                                            {/* <input
                                            type="checkbox"
                                            style={{ marginRight: "8px" }}
                                            id="label"
                                            name="label"
                                            checked={labelCheckedBack}
                                            onChange={(e) =>
                                              setLabelCheckedBack(
                                                e.target.checked
                                              )
                                            }
                                          /> */}
                                            <button
                                              type="button"
                                              // disabled={
                                              //   !labelChecked &&
                                              //   !emailChecked &&
                                              //   !labelCheckedBack &&
                                              //   !printChecked
                                              // }
                                              className="btn btn-secondary "
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px",
                                                fontSize: "12px",
                                                border: "2px solid #2E8B57",
                                                width: "115px",
                                                height: "30px",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)",
                                                transition: "all 0.3s ease",
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)";
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)";
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)";
                                              }}
                                              onClick={handlelabelCheckedBack}
                                            >
                                              Print Label Back
                                            </button>
                                          </td>
                                          {/* <td>
                                          <button
                                            type="button"
                                            disabled={
                                              !labelChecked &&
                                              !emailChecked &&
                                              !labelCheckedBack &&
                                              !printChecked
                                            }
                                            className="btn btn-secondary "
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "white",
                                              padding: "3px 7px",
                                              fontSize: "12px",
                                              border: "2px solid #2E8B57",
                                              width: "100px",
                                              height: "30px",
                                              borderRadius: "3px",
                                              cursor: "pointer",
                                              boxShadow:
                                                "0 4px 8px rgba(0,0,0,0.1)",
                                              transition: "all 0.3s ease",
                                            }}
                                            onMouseOver={(e) => {
                                              e.currentTarget.style.transform =
                                                "scale(1.05)";
                                              e.currentTarget.style.boxShadow =
                                                "0 6px 12px rgba(0,0,0,0.2)";
                                            }}
                                            onMouseOut={(e) => {
                                              e.currentTarget.style.transform =
                                                "scale(1)";
                                              e.currentTarget.style.boxShadow =
                                                "0 4px 8px rgba(0,0,0,0.1)";
                                            }}
                                            onClick={handleFinish}
                                          >
                                            OK
                                          </button>
                                        </td> */}
                                        </tr>
                                      ) : (
                                        <tr>
                                          <h3 style={{ textAlign: "center" }}>
                                            {successMessage}
                                          </h3>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan="4">
                                  <hr />
                                  <td colSpan="2"></td>
                                </td>
                              </tr>
                            </>
                          )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : isLoading ? (
                <div style={{ marginLeft: "25vw" }}>
                  <LoadingSpinner />
                </div>
              ) : null}
              {noData && order.length === 0 && !isLoading && (
                <p>No Order History Found</p>
              )}
              {payBalance && (
                <div
                  style={{
                    display: "flex",
                    position: "fixed",
                    top: "20px",
                    zIndex: 20,
                    right: "5px",
                    flex: 1,
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      // marginLeft: '-105%',
                      width: "",
                      backgroundColor: "#1f1d2b",
                      color: "white",
                      padding: "30px",
                      border: "2px solid rgb(103, 63, 54)",
                      borderRadius: "12px",
                      marginRight: "10px",
                      height: "fit-content",
                      zIndex: 5,
                    }}
                  >
                    <h4
                      style={{
                        borderBottom: "2px solid rgb(103, 63, 54)",
                        paddingBottom: "18px",
                      }}
                    >
                      Payment Confirmation
                    </h4>
                    {/* <hr
      style={{
        color: "white",
        backgroundColor: "rgb(103, 63, 54)",
        height: 3,
        border: "none",
        marginBottom: "20px",
      }}
    /> */}

                    {payments.length > 0 && (
                      <div
                        style={{
                          //maxHeight: "400px",
                          //overflow: "auto",
                          border: "1px solid #343245",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                      >
                        <table style={{ width: "100%", borderSpacing: "0" }}>
                          <thead>
                            <tr style={{ fontWeight: "bold" }}>
                              <th>Type</th>
                              <th>Info</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#272635" : "transparent",
                                }}
                              >
                                <td>{item.method}</td>
                                <td>{item.info}</td>
                                <td>
                                  $
                                  {item.amount == null
                                    ? 0.0
                                    : parseFloat(item.amount).toFixed(2)}
                                </td>
                                <td>
                                  {
                                    new Date(item.date)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </td>
                                <td
                                  onClick={() => removePayment(item)}
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  X
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div
                      className="OrderConfirmationPrice"
                      style={{
                        // marginTop: "130px",
                        marginTop: "14px",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                        justifyContent: "flex-end",
                        width: "93%",
                      }}
                    >
                      <label htmlFor="total" style={{ fontSize: "14px" }}>
                        Total
                      </label>
                      <span
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >{`$${savedTotalPrice}`}</span>
                    </div>
                    <div
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label htmlFor="total" style={{ fontSize: "24px" }}>
                        Total Outstanding:
                      </label>
                      <span
                        style={{ fontSize: "24px", fontWeight: "bold" }}
                      >{`$${totalPrice}`}</span>
                    </div>
                  </div>
                  <PlaceOrder
                    setPayBalance={setPayBalance}
                    selectedData={selectedData}
                    payingBalance={true}
                    onCancel={handleCancelBalancePayment}
                    isToggleActive={true}
                    onToggleChange={false}
                    getTotalPrice={totalPrice}
                    style={{
                      flexBasis: "10%",
                      marginLeft: "20px",
                      backgroundColor: "#1f1d2b",
                      color: "white",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                    comment={""}
                    deliveryOption={""}
                    total={totalPrice}
                    deliveryDate={""}
                  />
                </div>
              )}

              <div
                style={{
                  width: "40%",
                  position: "fixed",
                  // position: "absolute",
                  top: "0",
                  right: "1rem",
                  zIndex: 10,
                }}
              >
                <Cart
                  popupHandler={handleActivePopup}
                  handleShowAlert={handleShowAlert}
                  oDeliveryDate={deliveryDate}
                  setGeneralAlertText={setAlertText}
                  setGeneralAlert={setGeneralAlert}
                />
              </div>
            </div>
          </>
        )}
      </CartContext.Consumer>
    </>
  );
};

export default OrderDetails;
