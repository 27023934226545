import React, { useState, useEffect } from "react";
import GeneralAlert from "../components/GeneralAlert";
import { useHistory } from "react-router-dom";

const Categories = () => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedPriceList, setSelectedPriceList] = useState("1");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [showAlert,setShowAlert] = useState(false);
  const [alertText,setAlertText] = useState("")

  const handleCloseAlert =() => {
    setShowAlert(false);
  }
  const history = useHistory();

  const fetchItems = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if(token == null){
        history.replace("/login/");
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}inventory`,
        {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      const data = await response.json();
      setInventoryItems(data);
    } catch (error) {
      setAlertText("Failed to fetch items.");
      setShowAlert(true);
      // alert("Failed to fetch items.");
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleUpdateClick = async () => {
    if (!selectedItemId) {
      setAlertText("Please select an item.");
      setShowAlert(true);
      // alert("Please select an item.");
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      if(token == null){
        history.replace("/login/");
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}inventory/UpdatePriceList/${selectedItemId}`,
        {
          method: "PUT",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({
            PriceList: selectedPriceList,
          }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }

      const data = await response.json();

      if (data && data.message) {
        setAlertText(data.message);
        setShowAlert(true);
        // alert(data.message);
        fetchItems();
      }
    } catch (error) {
      setAlertText("Failed to add item to Category.");
      setShowAlert(true);
      // alert("Failed to add item to Category.");
    }
  };

  const [isMatzaVisible, setIsMatzaVisible] = useState(false);
  const [isFlourVisible, setIsFlourVisible] = useState(false);
  const [isETCVisible, setIsETCVisible] = useState(false);
  const [isKimchaVisible, setIsKimchaVisible] = useState(false);
  const [isErevPesachVisible, setIsErevPesachVisible] = useState(false);
  const [isHiddenVisible, setIsHiddenVisible] = useState(false);

  return (
    <div>
         {showAlert && <GeneralAlert popupText={alertText} onClose={handleCloseAlert}/>}
      <select onChange={(e) => setSelectedItemId(e.target.value)}>
        <option value="">Select an item</option>
        {inventoryItems
          .filter((item) => item.StyleDesc)
          .map((item) => (
            <option key={item.StyleNo} value={item.StyleNo}>
              {item.StyleDesc}
            </option>
          ))}
      </select>

      <select
        value={selectedPriceList}
        onChange={(e) => setSelectedPriceList(e.target.value)}
      >
        <option value="3">Kimcha</option>
        <option value="1">Matza</option>
        <option value="2">Flour</option>
        <option value="0">ETC</option>
        <option value="4">Hidden</option>
        <option value="4">Erev Pesach</option>
      </select>

      <button onClick={handleUpdateClick}>Add Item To Category</button>
      <div
        style={{
          display: "fixed",
          paddingTop: "50px",
          paddingLeft: "100px",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {/* Matza Section */}
        <div>
          <h2 onClick={() => setIsMatzaVisible((prev) => !prev)}>Matza</h2>
          {isMatzaVisible &&
            inventoryItems
              .filter((item) => item.PriceList === 1)
              .map((item) => <div key={item.StyleNo}>{item.StyleDesc}</div>)}
        </div>

        {/* Flour Section */}
        <div>
          <h2 onClick={() => setIsFlourVisible((prev) => !prev)}>Flour</h2>
          {isFlourVisible &&
            inventoryItems
              .filter((item) => item.PriceList === 2)
              .map((item) => <div key={item.StyleNo}>{item.StyleDesc}</div>)}
        </div>

        {/* ETC Section */}
        <div>
          <h2 onClick={() => setIsETCVisible((prev) => !prev)}>ETC</h2>
          {isETCVisible &&
            inventoryItems
              .filter(
                (item) =>
                  parseInt(item.PriceList) !== 1 &&
                  parseInt(item.PriceList) !== 2
              )
              .map((item) => <div key={item.StyleNo}>{item.StyleDesc}</div>)}
        </div>
        {/* Kimcha Section */}
        <div>
          <h2 onClick={() => setIsKimchaVisible((prev) => !prev)}>Kimcha</h2>
          {isKimchaVisible &&
            inventoryItems
              .filter(
                (item) =>
                  parseInt(item.PriceList) !== 1 &&
                  parseInt(item.PriceList) !== 2
              )
              .map((item) => <div key={item.StyleNo}>{item.StyleDesc}</div>)}
        </div>
        {/* Hidden Section */}
        <div>
          <h2 onClick={() => setIsHiddenVisible((prev) => !prev)}>Hidden</h2>
          {isHiddenVisible &&
            inventoryItems
              .filter(
                (item) =>
                  parseInt(item.PriceList) !== 1 &&
                  parseInt(item.PriceList) !== 2
              )
              .map((item) => <div key={item.StyleNo}>{item.StyleDesc}</div>)}
        </div>

        {/* Erev Pesach Section */}
        <div>
          <h2 onClick={() => setIsHiddenVisible((prev) => !prev)}>Erev Pesach</h2>
          {/* {isErevPesachVisible &&
            inventoryItems
              .filter(
                (item) =>
                  parseInt(item.PriceList) !== 1 &&
                  parseInt(item.PriceList) !== 2
              )
              .map((item) => <div key={item.StyleNo}>{item.StyleDesc}</div>)} */}
        </div>
        </div>

    </div>
  );
};

export default Categories;
