export const OrderStatusEnum = [
    {
        id:0,
        name: 'Init'
    },
    {
        id:1,
        name: 'Ready to Pack'
    },
    {
        id:2,
        name: 'Started Packing'
    },
    {
        id:3,
        name: 'Completed Packing'
    },
    {
        id:4,
        name: 'Needs Change After Packing'
    },
    {
        id:5,
        name: 'Ready in Zone'
    },
    {
        id:6,
        name: 'Ready for Pickup'
    },
    {
        id:7,
        name: 'Ready for Delivery'
    },
    {
        id:8,
        name: 'Loaded on Truck'
    },
    {
        id:9,
        name: 'Duplicate'
    },
    {  
        id:10,
        name: 'Completed'
    }

]
