import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logoLoginPage from "../images/logoLoginPage.png";
import "../styles/login.css";
import GeneralAlert from "../components/GeneralAlert";

const LoginPage = ({ handleLogin, handleLogout }) => {
  const [password, setPassword] = useState("");
  const [showAlert,setShowAlert] = useState(false);
  const [alertText,setAlertText] = useState("")
  const history = useHistory();
  const handleCloseAlert =() => {
    setShowAlert(false);
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}login/checkPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ providedPassword: password }),
        }
      );
      if(response?.status === 401){
        history.replace("/login/");
      }
      console.log(password);
      // const isPasswordValid = await response.json();
      const data = await response.json();
      if (data.success) {
        localStorage.setItem("authToken", data.Token);
        localStorage.setItem("employeeID", data.employeeID);
        localStorage.setItem("logInID", data.logInID);
        console.log(data.logInID);
        handleLogin();
        history.push("/home");
      } else {
        // Handle invalid login credentials
        setAlertText("Invalid password");
        setShowAlert(true);
        // alert('Invalid password');
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertText("Error logging in. Please try again.");
      setShowAlert(true);
      // alert('Error logging in. Please try again.');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  // const handleLogoutClick = () => {
  //   handleLogout(); // Call the handleLogout function
  //   // Additional logic for logging out, such as redirecting to the login page
  //   history.push('/login');
  // };

  useEffect(()=>{
    setTimeout(() => {
      const iframe = document.getElementsByTagName("iframe")[0];
      if (iframe) {
        iframe.remove();
      }
    }, 1000);
  },[])

  return (
    <>
     {showAlert && <GeneralAlert popupText={alertText} onClose={handleCloseAlert}/>}
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        marginTop: "-50px",
      }}
    >
      <div
        style={{
          width: "400px",
          textAlign: "center",
          height: "500px",
          padding: "20px",
          borderRadius: "20px",
          backgroundColor: "#1f1d2b",
        }}
      >
        <div style={{ marginBottom: "70px" }}>
          <img
            src={logoLoginPage}
            alt="Logo"
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        <form onSubmit={handleFormSubmit}>
          {/* Add your login form fields here */}
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={handlePasswordChange}
            className="password-input" // Add a class name for the input field
            autoFocus
          />
          <br /> {/* Add a line break */}
          <button
            type="submit"
            className="login-button" // Add a class name for the login button
          >
            Login
          </button>
        </form>
      </div>
    </div>
    </>
  );
};

export default LoginPage;
