import React, {  useEffect, useState } from "react";
import * as XLSX from "xlsx";
import GeneralAlert from "../components/GeneralAlert";
import { useHistory } from "react-router-dom";
import { nanoid } from "nanoid";
import LoadingSpinner from "./LoadingSpinner";

function BulkUpdater() {
  const [data, setData] = useState([]);
  const [selectedPriceCode, setSelectedPriceCode] = useState("");
  const [foundCustomerIds, setFoundCustomerIds] = useState([]);
  const [newCustomer,setNewCustomer] = useState([])
  const [priceCodeData, setPriceCodeData] = useState([]);
  const [searchComplete, setSearchComplete] = useState(false);
  const [toUpdateCustomer,setToUpdateCustomer] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading,setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
const history = useHistory()
  const handlePriceCodeChange = (e) => {
    setSelectedPriceCode((code) => e.target.value);
  };



  const getPriceCodes =async () =>{

    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }    
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}PriceCode/GetPriceCodes`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    );
    if(response?.status === 401){
      history.replace("/login/");
    }
    if (!response?.ok) {
      const text = await response.text();
      throw new Error(text || "Network response was not ok");
    }
    const data = await response.json();
    console.log(data)
    setPriceCodeData(data);
    // setSelectedPriceCode(val => (data[0].ID + ':' + data[0].PriceDesc));



  }


  useEffect(()=>{
getPriceCodes()
  },[])

  const updatePriceCodeForAll = async () => {
    console.log("IDS", foundCustomerIds);

    try {
      const token = localStorage.getItem("authToken");
      if(token == null){
        history.replace("/login/");
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}customer/updatePriceCode`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({
            PriceCode: selectedPriceCode,
            CustomerIds: foundCustomerIds,
          }),
        }
      );

      if (!response?.ok) {
        const errorDetails = await response.text();
        console.error(
          `Error occurred while updating PriceCode: ${response?.statusText} Details: ${errorDetails}`
        );
      } else {
        console.log("PriceCode updated successfully for selected customers");
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Message will disappear after 3 seconds (3000ms)
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };


  



  const updateCustomersData = async()=>{
    setLoading(true)
    console.log("IDS", foundCustomerIds);
    console.log(newCustomer)
    console.log(toUpdateCustomer)
    console.log(selectedPriceCode)
    const selectedFoundCustomerIds = foundCustomerIds
    .filter(foundCustomer => foundCustomer.priceCode < selectedPriceCode)
    .map(foundCustomer => foundCustomer.custID);

    const token = localStorage.getItem("authToken");
    if(token == null){
      history.replace("/login/");
    }    


    const customersData = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}customer`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        }}) 
        if(customersData?.status === 401){
          history.replace("/login/");
        }
        const allCustomers = await customersData.json();
        const flatFoundCustomerIds = foundCustomerIds.flatMap(obj => obj.custID);
        // const flatFoundCustomerIds = foundCustomerIds.flat()
        // console.log(flatFoundCustomerIds)
        const filteredallCustomers = allCustomers.filter(obj => !flatFoundCustomerIds.includes(obj.CustomerID) && obj.PriceCode == selectedPriceCode);
        
// Extract CustomerIDs from the filtered array
let filteredCustomerIDs = filteredallCustomers.map(obj => obj.CustomerID );
for (let i = 0; i < filteredCustomerIDs.length; i++) {
  // If the element is not in the desired format, convert it
  if (!Array.isArray(filteredCustomerIDs[i])) {
    filteredCustomerIDs[i] = [filteredCustomerIDs[i]];
  }
}



const priceCodeToOne = await fetch(
  `${process.env.REACT_APP_API_ENDPOINT}customer/updatePriceCode`,
  {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      PriceCode: "1",
      CustomerIds: filteredCustomerIDs ,
    }),
  }
);
if(priceCodeToOne?.status === 401){
  history.replace("/login/");
}
if (!priceCodeToOne?.ok) {
  const errorDetails = await priceCodeToOne.text();
  console.error(
    `Error occurred while updating PriceCode: ${priceCodeToOne.statusText} Details: ${errorDetails}`
  );
} else {
  console.log("PriceCode updated successfully for selected customers");


  console.log("IDS", selectedFoundCustomerIds);
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}customer/updatePriceCode`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        PriceCode: selectedPriceCode,
        CustomerIds: selectedFoundCustomerIds,
      }),
    }
  );
  if(response?.status === 401){
    history.replace("/login/");
  }
  if (!response?.ok) {
    const errorDetails = await response.text();
    console.error(
      `Error occurred while updating PriceCode: ${response?.statusText} Details: ${errorDetails}`
    );
  } else {
    console.log("PriceCode updated successfully for selected customers");
  }

  
  
  
  for (let cust of toUpdateCustomer){
    console.log(cust)
    let custToUpdate = cust
    if(selectedPriceCode && custToUpdate.PriceCode < selectedPriceCode){
        custToUpdate.PriceCode = selectedPriceCode
      }
    const updateCustomer = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}customer`,
      {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization":`Bearer ${token}`,
        },
        body: JSON.stringify(custToUpdate),
      }
    );
    if(updateCustomer?.status === 401){
      history.replace("/login/");
    }
    if(!updateCustomer?.ok){
      console.log("error: Cannot create" )
    } else{
      console.log(" Customers Updated")
      ;
    }
  }
  
  
  for (let cust of newCustomer) {
    const uniqueID = nanoid(7);
    console.log(uniqueID)
    console.count()
    let transformedArray = [[uniqueID]];
    console.log(transformedArray);
    const newCustomer  = await fetch(process.env.REACT_APP_API_ENDPOINT + "customer", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        CustomerID: uniqueID,
        Name: cust["First Name"]?cust["First Name"]: ""+ " "+ cust["Last Name"]?cust["Last Name"]: "", // Use the combined name here
        NameLast: cust["First Name"] ? cust["First Name"] : "",
        NameFirst: cust["Last Name"] ? cust["Last Name"] : "",
        Address1: cust.Address1 ? cust.Address1 : "",
        Address2: cust.Address2? cust.Address2: "",
        City: cust.City? cust.City: "",
        State: cust.State ? cust.State : "",
        ZipCode: cust.Zip? cust.Zip : "",
        Phone1: cust.Phone1,
        Phone2: cust.Phone2 ? cust.Phone2:"",
        EmailAdrs: cust.Email? cust.Email :"",
        PriceCode: selectedPriceCode,
        Comment: cust.Comment? cust.Comment: "" ,
      }),
    })
    if(newCustomer?.status === 401){
      history.replace("/login/");
    }
    if(!newCustomer?.ok){
      console.log("error: Cannot create" )
    } else{
      console.log(" New Customer Created")
      const newCustPriceCodeUpdate = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}customer/updatePriceCode`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({
            PriceCode: selectedPriceCode,
            CustomerIds: transformedArray,
          }),
        }
      );
      if(newCustPriceCodeUpdate?.status === 401){
        history.replace("/login/");
      }
      if(!newCustPriceCodeUpdate?.ok){
        console.log("error: Cannot create" )
      } else{
        console.log("New Customer Price Code Updated")
        
      }
    }
  }


}
setLoading(false)
setAlertText("Operations Done Successfully")
setShowAlert(true);





   

 
   

  }

  const handleFile = (e) => {
    setData([]);
    
    setFoundCustomerIds([]);
    setSearchComplete(false); // No need to use a function here

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
        const bstr = evt.target.result;
        const workbook = XLSX.read(bstr, { type: "binary" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        let rows = XLSX.utils.sheet_to_json(worksheet);
        rows = rows.map((row) => {
            for (const key in row) {
                // Check if the column header matches "phone1" or "phone2"
                if (key.toLowerCase() === "phone1" || key.toLowerCase() === "phone2") {
                    if (typeof row[key] === "string") {
                        row[key] = row[key].replace(/[^\d]/g, ""); // Remove non-digit characters
                    }
                }
            }
            return row;
        });
        setData(rows);
    };
    reader.readAsBinaryString(file);
};
  

  const handleUpload = async () => {
    setLoading(true);
    console.log(data);


    
    let tempFoundCustomerIds = [];
    let customertoUpdate= {}
    for (let row of data) {
      const requestBody = {};
      if (row.Phone1) requestBody.Phone1 = row.Phone1;
      if (row.Phone2) requestBody.Phone2 = row.Phone2;

      try {
        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        // const test = await fetch(
        //   `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${"GicsQ8L"}`,
        //   {
        //     method: "GET",
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Authorization": `Bearer ${token}`,
        //     },
        //   }
        // );
        // const testp = await test.json();
        // console.log(testp)
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}customer/searchByPhone`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        )
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
         
          const errorDetails = await response.text();
          console.error(
            `Error occurred while fetching: ${response?.statusText}. Details: ${errorDetails}`
          );
          continue;
        }

        const customer = await response.json();
        
        if (customer && customer.length > 0) {
          console.log(
            "Found Customer:",
            customer.map((item) => item.CustomerID)
          );
          const customerCheck = customer.map((item) => item.CustomerID)
          const getCustomerCheckData = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerInfoById/${customerCheck[0]}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            }
          );
          if(getCustomerCheckData?.status === 401){
            history.replace("/login/");
          }
          if (!getCustomerCheckData?.ok) {
            throw new Error(`HTTP error! status: ${response?.status}`);
          } else {
            let data = await getCustomerCheckData.json();
            console.log(data)
            console.log(row)
             customertoUpdate = {
              CustomerID: data[0].CustomerID,
              Name: row["First Name"]?row["First Name"]: data[0].NameFirst+ " "+ row["Last Name"]? row["Last Name"]: data[0].NameLast, // Use the combined name here,
              NameLast: row["Last Name"]? row["Last Name"]:data[0].NameLast, //
              NameFirst: row["First Name"]? row["First Name"]:data[0].NameFirst,
              Address1:  row.Address1 ? row.Address1 : data[0].Address1,
              Address2: row.Address2 ? row.Address2 : data[0].Address2,
              City: row.City ? row.City : data[0].City,
              Country: row.Country ? row.Country : data[0].Country,
              State:  row.State ? row.State : data[0].State,
              ZipCode: row.Zip? row.Zip : data[0].ZipCode,
              Phone1: data[0].Phone1,
              Phone2: data[0].Phone2,
              Phone3: data[0].Phone3,
              Comment: data[0].Comment,
              EmailAdrs: row.Email? row.Email :data[0].EmailAdrs,   
              Orders: data[0].Orders,
              ShipVia: data[0].ShipVia,
              PriceCode:data[0].PriceCode

            }
            console.log(customertoUpdate)
            setToUpdateCustomer((prev)=>[...prev, customertoUpdate])
          }
          tempFoundCustomerIds = [
            ...tempFoundCustomerIds,{
              custID : customer.map((item) => item.CustomerID),
              priceCode: customertoUpdate.PriceCode
            }
          ]; // Push to the temporary array
        } else {
          setNewCustomer((prev)=>[...prev,row])
          console.log("No customer found for:", row);
        }
       
      } catch (error) {
        console.error("Error during API call:", error);
      } 
    }
    console.log("CustIDS", tempFoundCustomerIds);
    setFoundCustomerIds((prev) => [...prev, ...tempFoundCustomerIds]);
    setSearchComplete((val) => true);
    setLoading(false)
  };

  const handleDownload = async () => {
    try {
      const fileUrl = `${process.env.PUBLIC_URL}/samplefile.xlsx`;
      const response = await fetch(fileUrl, { method: "HEAD" });
      if(response?.status === 401){
        history.replace("/login/");
      }
      if (!response?.ok) {
        // If the response status is not ok, the file doesn't exist
        setAlertText("File does not exist!");
        setShowAlert(true);
        // alert('File does not exist!');
        return;
      }
      // If the file exists, proceed with the download
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "samplefile.xlsx";
      link.click();

      // const token = localStorage.getItem('authToken');
      // const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}customer/downloadsamplefile`, {
      //   method: 'GET',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${token}`
      //   }
      // });

      // // Check if the request was successful (status code 200)
      // if (response??.ok) {
      //   const blob = await response.blob();

      //   // Create a link element and trigger a click to start the download
      //   const link = document.createElement('a');
      //   link.href = window.URL.createObjectURL(blob);
      //   link.download = 'samplefile.xlsx';
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } else {
      //   console.error('Error downloading file:', response?.statusText);
      // }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      {showAlert && (
        <GeneralAlert popupText={alertText} onClose={handleCloseAlert} />
      )}
      <div>
    
        <button
          style={{
            backgroundColor: "#E8C48A",
            color: "black",
            border: "none",
            borderRadius: "6px",
            padding: "4px 10px",
            fontSize: "18px",
          }}
          onClick={handleDownload}
        >
          Download Sample File
        </button>
      </div>
      <div>
        <input type="file" onChange={handleFile} />
        <button
          style={{
            backgroundColor: "#E8C48A",
            color: "black",
            border: "none",
            height: "50px",
            //borderRadius: "6px",
            padding: "4px 10px",
            fontSize: "18px",
          }}
          onClick={handleUpload}
        >
          Upload & Process
        </button>
        {loading &&
            <div style={{marginLeft:"-235px"}}>
              <LoadingSpinner/>
            </div>
            }
        {searchComplete && (
          <>
            {/* Display a warning message if the number of found customer IDs exceeds 2100 */}
            {foundCustomerIds.length > 2100 && (
              <div
                style={{
                  color: "red",
                  paddingLeft: "100px",
                  paddingTop: "30px",
                }}
              >
                Max Number Customers To Change PriceCode 2100
              </div>
            )}

            {/* Display the number of found customer IDs */}
            <div
              style={{
                display: "flex",
                paddingTop: "50px",
                paddingLeft: "100px",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "1.2em", // Increase font size for larger text
                  letterSpacing: "0.05em", // Optional: Increase letter spacing for better readability
                  margin: "0 10px 0 0", // Optional: Add some spacing on the right side
                }}
              >
                Number of Customers Found:
              </span>
              {foundCustomerIds
                .length}
            </div>
            <br />
            
            
            {data.length > 0 && (
              <div
                style={{
                  display: "flex",
                  paddingTop: "50px",
                  paddingLeft: "100px",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <label>Select Price Code: </label>
                <select
                  value={selectedPriceCode}
                  onChange={handlePriceCodeChange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {priceCodeData.map((singleCode) => (
                    <option key={singleCode.Price} value={singleCode.Price}>
                      {singleCode.Price}. {singleCode.PriceDesc}
                    </option>
                  ))}
              
                </select>
                <button onClick={updateCustomersData}>
                  Update Price Code for Selected Customers
                </button>
              </div>
            )}
          </>
        )}
        {showSuccessMessage && (
          <div
            style={{ color: "green", paddingLeft: "100px", paddingTop: "30px" }}
          >
            PriceCode updated successfully for selected customers.
          </div>
        )}
      </div>
    </>
  );
}

export default BulkUpdater;
