import React, { createContext, useState } from 'react';

const CustomerContext = createContext();

const CustomerProvider = ({ children }) => {
  const [customerId, setCustomerId] = useState(localStorage.getItem('CustomerID'));
  const [customerNameFirst, setCustomerNameFirst] = useState(null); 
  const [priceCode, setPriceCode] = useState(null); 
  const [zipcode, setZipcode] = useState(null); 

  // 2. Modify the setCustomer function
  const setCustomer = (id, nameFirst, price, zipcode) => { 
    setCustomerId(val => id);
    setCustomerNameFirst(val => nameFirst);
    setPriceCode(val => price); 
    setZipcode(val => zipcode);
  };

  const clearCustomerData = () => {
    // setCustomerId(null);
    // setCustomerNameFirst(null);
    // setPriceCode(null); // Clear the PriceCode
    // setZipcode(null);
  }

  return (
    <CustomerContext.Provider
      value={{ 
        customerId, 
        customerNameFirst, 
        priceCode, 
        setCustomer, 
        clearCustomerData,
        setPriceCode 
      }} 
    >
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext, CustomerProvider };
