import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const AlertPopup = ({invoiceNo, popupText, onClose ,discount}) => {
  console.log(discount)
  const [printChecked, setPrintChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);
  const [labelChecked, setLabelChecked] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const deliveryOption = localStorage.getItem('deliveryOption');
  const deliveryDate = localStorage.getItem('deliveryDateToday');
  const history = useHistory();

  const handleFinish = async () => {
    setSuccessMessage("Done successfully");
    setTimeout(() => {
      onClose();
    }, 1500);
    // Call APIs based on checkbox state
    if (printChecked) {
      try {
        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "invoice/PrintReceiptById",
          {
            method: "PUT",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ invoiceNo }),
          }
        );
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error(`HTTP error! status: ${response?.status}`);
        } else {
          let data = await response.json();
          setTimeout(() => {console.log(data)},20000)
          
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
      // Call print API
      console.log("Printing receipt...");
    }
    if (emailChecked) {
      // Call email API
      try {
        await new Promise(resolve => setTimeout(resolve, 5000));

        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "invoice/EmailOrderById",
          {
            method: "PUT",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ invoiceNo }),
          }
        );
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error(`HTTP error! status: ${response?.status}`);
        } else {
          let data = await response.json();
          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
      console.log("Emailing receipt...");
    }
    if (labelChecked &&deliveryOption !== "Delivery" && deliveryDate == "ok") {      // Call label API
      try {
        await new Promise(resolve => setTimeout(resolve, 5000));

        const token = localStorage.getItem("authToken");
        if(token == null){
          history.replace("/login/");
        }
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT +
            "invoice/PrintLabelStoreFrontById",
          {
            method: "PUT",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ invoiceNo }),
          }
        );
        if(response?.status === 401){
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error(`HTTP error! status: ${response?.status}`);
        } else {
          let data = await response.json();
          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
      console.log("Printing label...");
    }
   
  };

  const handleFinishAndGoToHome = () => {
    handleFinish(); // Call the same function as Finish
    history.push("/home"); // Change route to /home
  };
  
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        border: "2px solid rgb(103, 63, 54)",
        borderRadius: "12px",
        width: "50%",
        top:"30%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#252836",
        borderRadius: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      <div style={{ textAlign: "right"}}>
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
          style={{
            border: "none",
            background: "none",
            color: "white",
            cursor: "pointer",
            outline: "none",
          }}
        >
          X
        </button>
      <h3
        style={{
          textAlign: "center",
        }}
      >
        Thank you
      </h3>
      </div>

      <br />
      <h5
        style={{
          paddingBottom: "8px",
          textAlign: "center",
          borderBottom: "2px solid rgb(103, 63, 54)",
        }}
      >
        {popupText}
      </h5>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          gap: "1rem",
        }}
      >
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          OK
        </button>
      </div> */}
      {successMessage === null && (
        <>
          <br />
          {/* <h5
            style={{
              paddingBottom: "8px",
              textAlign: "center",
              borderBottom: "2px solid rgb(103, 63, 54)",
            }}
          >
            Order # {invoiceNo} has been created successfully
          </h5> */}
          <div
            style={{
              textAlign: "left",
              backgroundColor: "#252836",
              marginTop: "10px",
            }}
          >
            <div
              style={{ display: "flex", gap: "2rem" ,alignItems: "center" }}
            >
              <div>
                <input
                  type="checkbox"
                  style={{ marginRight: "8px" }}
                  id="print"
                  name="print"
                  checked={printChecked}
                  onChange={(e) => setPrintChecked(e.target.checked)}
                />
                <label htmlFor="print">Print Receipt</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  style={{ marginRight: "8px" }}
                  id="email"
                  name="email"
                  checked={emailChecked}
                  onChange={(e) => setEmailChecked(e.target.checked)}
                />
                <label htmlFor="email">Email Receipt</label>
              </div>
              {deliveryOption !== "Delivery" && deliveryDate == "ok" &&
              <div>
                <input
                  type="checkbox"
                  style={{ marginRight: "8px" }}
                  id="label"
                  name="label"
                  checked={labelChecked}
                  onChange={(e) => setLabelChecked(e.target.checked)}
                />
                <label htmlFor="label">Print Label and Pick Up</label>
              </div>
              }
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              gap: "1rem",
            }}
          >
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleFinish}
            >
              Finish
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleFinishAndGoToHome}
            >
              Finish & Go to Home
            </button>
          </div>
        </>
      )}
      {successMessage && (
        <div style={{ textAlign: "center" }}>
          <h3>{successMessage}</h3>
        </div>
      )}
    </div>
  );
};

export default AlertPopup;
