import React, { useContext, useEffect, useState, useRef } from "react";
import CartContext from "../context/CartContext";
import PaymentsContext from "../context/PaymentsContext";
import { CustomerContext } from "../context/CustomerContext";
import { CiCreditCard2 } from "react-icons/ci";
import { AiOutlineDollar } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { BsCreditCard } from "react-icons/bs";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import { LiaWalletSolid } from "react-icons/lia";
import { FaMoneyCheckAlt } from "react-icons/fa";
import "../styles/PlaceOrder.css";
import axios from "axios";
import { useTotalPrice } from "../context/TotalPriceContext";
import AlertPopup from "../components/AlertPopup";
import GeneralAlert from "../components/GeneralAlert";
import { useHistory } from "react-router-dom";
import PaymentPopup from "../components/PaymentPopup";
import LoadingSpinner from "./LoadingSpinner";

const PlaceOrder = ({
  payingBalance,
  isToggleActive,
  onToggleChange,
  comment,
  subaccount,
  setSubAccount,
  setDeliveryOption,
  setDeliveryDate,
  setSelectedDateOption,
  deliveryOption,
  deliveryDate,
  onCancel,
  newAddressDetails,
  deliveryFee,
  popupHandler,
  alertHandler,
  totalDiscount,
  handleShowAlert,
  existingBalance,
  selectedData,
  setPayBalance,
  setZeroOutStanding,
}) => {
  const { customerId } = useContext(CustomerContext);
  const [customerData, setCustomerData] = useState([]);
  const [cart, setCart] = useContext(CartContext);
  const [payments, setPayments] = useContext(PaymentsContext);
  const ZipCode =
    localStorage.getItem("ZipCode") !== null
      ? localStorage.getItem("ZipCode")
      : "";
  const [zipCode, setZipCode] = useState(ZipCode);
  const [selectedDate, setSelectedDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("credit_card");
  const [cardPaymentDetails, setCardPaymentDetails] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
  const [isPasswordBalancePromptVisible, setIsPasswordBalancePromptVisible] =
    useState(false);
  const [
    isFutureDatesPasswordPromptVisible,
    setIsFutureDatesPasswordPromptVisible,
  ] = useState(false);
  const [isPasswordCorrect, setPasswordCorrect] = useState(false);
  const [isPasswordCorrectFutureDates, setPasswordCorrectFutureDates] =
    useState(false);
  const [isDisplayPasswordMsg, setDisplayPasswordMsg] = useState(false);
  const { totalPrice, setTotalPrice, tempTotalPrice, setTempTotalPrice } =
    useTotalPrice(); // use the custom hook
  const [checkAmount, setCheckAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [checks, setChecks] = useState([]);
  const [tempCheckNumber, setTempCheckNumber] = useState("");
  const [tempDate, setTempDate] = useState("");
  const [tempCheckAmount, setTempCheckAmount] = useState("");
  const [cardPayments, setCardPayments] = useState([]);
  const [cardNumber, setCardNumber] = useState("");
  const [cardPaymentAmount, setCardPaymentAmount] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [newCashAmount, setNewCashAmount] = useState("");
  const { priceCode: PriceCodeFromContext } = useContext(CustomerContext);
  const [accountPaymentAmount, setAccountPaymentAmount] = useState("");
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [enteredPasscode, setEnteredPasscode] = useState(""); // <-- New state for holding entered passcode
  const savedTotalPrice = localStorage.getItem("savedTotalPrice");
  const [toggledTotalPrice, setToggledTotalPrice] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [paymentAlert, setPaymentAlert] = useState(false);
  const [paymentAlertText, setPaymentAlertText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const cardholdernameRef = useRef(null);
  const cardNumberRef = useRef(null);
  const cardCvvRef = useRef(null);
  const cardExpiryMonthRef = useRef(null);
  const cardExpiryYearRef = useRef(null);

  const handleCloseAlert = () => {
    setShowAlert(false);
    if (setPayBalance) {
      setPayBalance(false);
    }
    setIsPasswordBalancePromptVisible(true);
  };

  const handleClosePaymentAlert = () => {
    setPaymentAlert(false);
  };
  //const Subaccount = subaccount;

  const [isSwipeCheckboxChecked, setIsSwipeCheckboxChecked] = useState(true);
  const [swipeData, setSwipeData] = useState("");
  const inputRef = useRef(null);
  const history = useHistory();
  useEffect(() => {
    if (isSwipeCheckboxChecked) {
      // Focus on the input field when the checkbox is checked
      inputRef.current.focus();
    }
  }, [isSwipeCheckboxChecked]);

  const handleSwipeCheckboxChange = () => {
    setIsSwipeCheckboxChecked((val) => !isSwipeCheckboxChecked);
  };

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (token == null) {
          history.replace("/login/");
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}customer/GetCustomerById/${customerId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 401) {
          history.replace("/login/");
        }
        if (!response?.ok) {
          throw new Error(`HTTP error! status: ${response?.status}`);
        } else {
          let data = await response.json();
          setCustomerData((prevData) => data);
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
    };
    fetchCustomerDetails();
  }, [customerId]);

  useEffect(() => {
    if (checks.length > 0) {
      const lastCheck = checks.length - 1;
      const totalCheckAmount = parseFloat(checks[lastCheck].amount || 0);

      let updatedTotalPrice = parseFloat(totalPrice - totalCheckAmount).toFixed(
        2
      );
      updatedTotalPrice = updatedTotalPrice;
      console.log(updatedTotalPrice);
      setTotalPrice(updatedTotalPrice);
      if (totalPrice >= 0) {
        setTempCheckAmount(totalPrice);
      }
    }
  }, [checks]);

  useEffect(() => {
    setBalance(localStorage.getItem("balance"));
  }, [balance]);

  const addCheck = () => {
    if (!tempCheckNumber || !tempDate || !tempCheckAmount) {
      setAlertText("All fields are required.");
      setShowAlert(true);
      // alert("All fields are required.");
      return;
    }

    const newCheck = {
      checkNumber: tempCheckNumber,
      date: tempDate,
      amount: tempCheckAmount,
    };
    setChecks((prevChecks) => [...prevChecks, newCheck]);

    const newPayment = {
      id: Math.floor(Math.random() * (500 - 1 + 1)) + 1,
      method: paymentMethod,
      amount: tempCheckAmount,
      info:
        paymentMethod === "cash"
          ? ""
          : paymentMethod === "credit_card"
          ? cardPaymentDetails.cardnum
          : paymentMethod === "check"
          ? tempCheckNumber
          : "",
      date: new Date().toISOString().slice(0, 19).replace("T", " "),
    };
    setPayments((prevPayments) => [...prevPayments, newPayment]);
    // Clear temporary states
    setTempCheckNumber("");
    setTempDate("");
    // setTempCheckAmount("");
  };

  const addCardPayment = () => {
    setTimeout(() => {
      const iframe = document.getElementsByTagName("iframe")[0];
      if (iframe) {
        iframe.remove();
      }
    }, 1000);

    console.log(cardPaymentDetails);
    if (swipeData === "" && isSwipeCheckboxChecked) {
      setAlertText("Please Swipe First !");
      setShowAlert(true);
      // alert("Please Swipe First !");
    } else if (
      (cardPaymentDetails.cardnum === "" ||
        cardPaymentDetails.cardnum === undefined ||
        cardPaymentDetails.expMonth === undefined ||
        cardPaymentDetails.expYear === undefined) &&
      !isSwipeCheckboxChecked
    ) {
      setAlertText("Please Enter Complete Card Details !");
      setShowAlert(true);
      // alert("Please Enter Complete Card Details !");
    } else {
      console.log(cardPaymentDetails);
      //const cardAmount = parseFloat(cardPaymentDetails.amount) || 0;
      const cardAmount = parseFloat(cardPaymentAmount) || 0;
      let updatedTotalPrice = totalPrice - cardAmount;
      updatedTotalPrice = updatedTotalPrice.toFixed(2);
      setTotalPrice(updatedTotalPrice);

      const newCardPayment = {
        cardNumber: cardPaymentDetails.cardnum,
        amount: cardAmount,
        zipcode: cardPaymentDetails.zipcode,
        cvvpass: cardPaymentDetails.cvvpass,
        expMonth: `${cardPaymentDetails.expMonth}`.padStart(2, "0"),
        expYear: `${cardPaymentDetails.expYear}`.slice(-2),
      };
      console.log(newCardPayment);

      setCardPayments((prevCardPayments) => [
        ...prevCardPayments,
        newCardPayment,
      ]);

      console.log("newCardPayment: ");
      console.log("cardpayments card: ", cardPayments);
      const newPayment = {
        id: Math.floor(Math.random() * (500 - 1 + 1)) + 1,
        method: paymentMethod,
        amount: newCardPayment.amount,
        info:
          paymentMethod === "cash"
            ? ""
            : paymentMethod === "credit_card"
            ? cardPaymentDetails.cardnum
            : paymentMethod === "check"
            ? tempCheckNumber
            : enteredPasscode,
        date: new Date().toISOString().slice(0, 19).replace("T", " "),
        swipeData: isSwipeCheckboxChecked ? swipeData : "",
      };
      setPayments((prevPayments) => [...prevPayments, newPayment]);
      setCardPaymentAmount("");
      setZipCode("");
      // Resetting date control values
      setCardPaymentDetails((prevDetails) => ({
        ...prevDetails,
        expMonth: undefined,
        expYear: undefined,
      }));
      setSwipeData("");
      cardNumberRef.current.value = "";
      cardCvvRef.current.value = "";
      cardExpiryMonthRef.current.value = "";
      cardExpiryYearRef.current.value = "";
    }
  };

  const resetCardPaymentValues = () => {
    setCardPaymentAmount("");
    setCardHolderName("");
    setCardNumber("");
  };

  const handleAccountPaymentAmountChange = () => {
    if (isToggleActive) {
      const newAccountPaymentAmount = parseFloat(accountPaymentAmount) || 0;
      let updatedTotalPrice = totalPrice - newAccountPaymentAmount;
      updatedTotalPrice = parseFloat(updatedTotalPrice.toFixed(2)); // Fix to two decimal places and then convert back to a number
      setTotalPrice(updatedTotalPrice);
    }
  };

  const handleToggleChange = () => {
    if (!isCheckboxEnabled) {
      // Moved passcode check logic to a new function (see below)
      checkPasscodeAndToggle();
    } else {
      // Disable the checkbox directly if it's already enabled
      onToggleChange();
    }
  };

  const checkPasscodeAndToggle = () => {
    if (enteredPasscode === "3486") {
      // Replace '1138' with the actual passcode
      setIsCheckboxEnabled(true); // Enable the subsequent fields
    } else {
      setAlertText("Incorrect passcode.");
      setShowAlert(true);
      // alert("Incorrect passcode.");
    }
  };

  const removeCheck = (index) => {
    const newChecks = checks.slice();
    newChecks.splice(index, 1);
    setChecks(newChecks);
  };

  const updateCheck = (index, key, value) => {
    const newChecks = checks.slice();
    newChecks[index][key] = value;
    setChecks(newChecks);
  };

  const handleCheckAmountChange = () => {
    const newCheckAmount = parseFloat(tempCheckAmount) || 0;
    setCheckAmount(newCheckAmount);

    let updatedTotalPrice = totalPrice - newCheckAmount;
    updatedTotalPrice = updatedTotalPrice.toFixed(2);
    setTotalPrice(updatedTotalPrice);
  };

  console.log(totalDiscount);

  useEffect(() => {
    console.log("New Context temp Total", tempTotalPrice);

    if (tempTotalPrice > 0) {
      setTotalPrice(tempTotalPrice);
      setTempTotalPrice(0);
    }
  }, [tempTotalPrice]);
  useEffect(() => {
    console.log("New Context Total", totalPrice);
    if (totalPrice >= 0) {
      setNewCashAmount((prevAmount) => totalPrice);
      setTempCheckAmount((prevAmount) => totalPrice);
      setCardPaymentAmount((prevAmount) => totalPrice);
    }
  }, [totalPrice, deliveryDate]);

  const handleCardMonthChange = (event) => {
    const monthValue = event.target.value;
    setCardPaymentDetails((prevDetails) => ({
      ...prevDetails,
      expMonth: monthValue,
    }));
  };

  const handleCardYearChange = (event) => {
    const yearValue = event.target.value;
    setCardPaymentDetails((prevDetails) => ({
      ...prevDetails,
      expYear: yearValue,
    }));
  };

  const handlePaymentMethodChange = (event) => {
    const selectedMethod = event.target.value;
    setPaymentMethod(selectedMethod);
  };

  const handleCardPaymentDetailsChange = (event) => {
    const { name, value } = event.target;

    if (name === "swipe") {
      setSwipeData((prevData) => value);
      return;
    }

    if (name === "zipcode") {
      //console.log(value);
      setZipCode(value);
    }

    // if (name === "amount") {
    //     //console.log(value);
    //     setCardPaymentAmount(val => value);
    // }

    setCardPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    //console.log("Updated Details:", cardPaymentDetails);
  };

  const cashPaymentHandler = () => {
    if (newCashAmount !== "") {
      const cashAmount = parseFloat(newCashAmount) || 0;
      console.log(cashAmount);
      let updatedTotalPrice = totalPrice - cashAmount;
      updatedTotalPrice = updatedTotalPrice.toFixed(2);
      setTotalPrice((prevPrice) => updatedTotalPrice);
      const newPayment = {
        id: Math.floor(Math.random() * (500 - 1 + 1)) + 1,
        method: paymentMethod,
        amount: newCashAmount,
        info:
          paymentMethod === "cash"
            ? ""
            : paymentMethod === "credit_card"
            ? cardPaymentDetails.cardnum
            : paymentMethod === "check"
            ? tempCheckNumber
            : enteredPasscode,
        date: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      setPayments((prevPayments) => [...prevPayments, newPayment]);
      console.log(payments);
      console.log("New Context Total", totalPrice);
      console.log("New Total", updatedTotalPrice);
      setNewCashAmount((prevValue) => totalPrice);
    } else {
      setAlertText("Please Enter Amount");
      setShowAlert(true);
      // alert("Please Enter Amount");
    }
  };

  useEffect(() => {
    console.log("CustomerID:", customerId);
    console.log("Cart Contents:", cart);
  }, [customerId]);

  let selectedOrderInvoice = 0;
  const handlePasswordBalanceSubmit = (event) => {
    event.preventDefault();
    const enteredPassword = event.target.password.value;
    if (enteredPassword === "3486") {
      setIsPasswordBalancePromptVisible(false);
      setDisplayPasswordMsg(false);
    } else {
      setPasswordCorrect(false); // Set password correctness to false
      setDisplayPasswordMsg(true);
    }
  };
  const handlePasswordSubmit =async (event) => {
    event.preventDefault();
    // Replace this with your actual password validation logic
    const enteredPassword = event.target.password.value;

    if (enteredPassword === "3486") {
      setIsPasswordPromptVisible(false);
      setDisplayPasswordMsg(false)
      if (payingBalance) {
    
        // debugger
      
        let tempTotalAmountPaid = 0; // to check after comparison with balance, whether there is any more balance or not
        for (const data of selectedData) {
          let invNo = data.invoiceNo;
          console.log(payments)
          if (payments.length > 0) {
            let ind = 0;
            const paymentDetails = payments.map((item) => {
              tempTotalAmountPaid =
                parseFloat(tempTotalAmountPaid) + parseFloat(item.amount);
               
                  if (item.method === "credit_card") {
      
      
                    if (item.swipeData !== "") {
                      return {
                        SwipeData: item.swipeData,
                        CustomerID: customerId,
                        EmployeeId: localStorage.getItem("employeeID"),
                        InvoiceNo: invNo,
                        CreditDate: new Date()
                          .toISOString()
                          .slice(0, 19)
                          .replace("T", " "),
                        Discount: 0,
                        CheckNo: "",
                        CreditAmt: item.amount,
                        PayType: item.method,
                        CardExpiry: "",
                        CardCvv: "",
                        CardZipcode: cardPayments[ind++].zipcode,
                        CheckDate: new Date()
                          .toISOString()
                          .slice(0, 19)
                          .replace("T", " "),
                        Description: "Payment",
                        DepositNo: "1",
                        ARType: "1",
                        IsEdit: true,
                        IsBalance: balance != 0,
                      };
                    } else {
                      return {
                        SwipeData: "",
                        CustomerID: customerId,
                        EmployeeId: localStorage.getItem("employeeID"),
                        InvoiceNo: invNo,
                        CreditDate: new Date()
                          .toISOString()
                          .slice(0, 19)
                          .replace("T", " "),
                        Discount: 0,
                        CheckNo: item.info,
                        CreditAmt:item.amount,
                        PayType: item.method,
                        CardExpiry: `${cardPayments[ind].expMonth}${cardPayments[ind].expYear}`,
                        CardCvv: cardPayments[ind].cvvpass,
                        CardZipcode: cardPayments[ind++].zipcode,
                        CheckDate: new Date()
                          .toISOString()
                          .slice(0, 19)
                          .replace("T", " "),
                        Description: "Payment",
                        DepositNo: "1",
                        ARType: "1",
                        IsEdit: true,
                        IsBalance: balance != 0,
                      };
                    }
                  } else {
                    // if(data.balance > newCashAmount){
                    //   setPaymentAlertText(
                    //     `Do you want $${totalPrice} (remaining amount) as balance for this order`
                    //   );
                    //   setPaymentAlert(true);
                    // }
                   
                    return {
                      CustomerID: customerId,
                      EmployeeId: localStorage.getItem("employeeID"),
                      InvoiceNo: invNo,
                      CreditDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Discount: 0,
                      CheckNo: item.info,
                      CreditAmt: item.amount,
                      PayType: item.method,
                      CardExpiry: "",
                      CardCvv: "",
                      CardZipcode: "",
                      CheckDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Description: "Payment",
                      DepositNo: "1",
                      ARType: "1",
                      IsEdit: true,
                      IsBalance:
                        balance != 0 ||
                        localStorage.getItem("increasedPrice") != null,
                    };
                  }
                
            });
            const val = localStorage.getItem("balance");
            if (val != null) {
              if (parseFloat(val) == tempTotalAmountPaid) {
                localStorage.removeItem("balance");
              }
            }
            console.log("Payment Details:- ", paymentDetails);
        
  
              const token = localStorage.getItem("authToken");
             const response = await fetch(
                process.env.REACT_APP_API_ENDPOINT + "SavePaymentData/SavePayments",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify(paymentDetails),
                }
              )
              
                console.log(response);
                if (response?.status === 401) {
                  history.replace("/login/");
                }
    
                if (!response?.ok) {
                  const t = await response.text()
                  setAlertText(t||'error');
                  setShowAlert(true);
                  return;
                } else {
    
                  
                  setAlertText("Payment done successsfully");
                  setShowAlert(true);
                }
            
          } 
        }
        setPayments([]);
      }
      else{
       
        if (!customerId) {
          setAlertText("Please pick a Customer before placing an order.");
          setShowAlert(true);
          // alert("Please pick a Customer before placing an order.");
          return;
        }
        setCart([]);
        const totalAmountAfterPayment = totalPrice - paymentAmount;
        saveOrderToTable(customerId, totalAmountAfterPayment);
        setPayments([]);
      }
    } else {
      setPasswordCorrect(false); // Set password correctness to false
      setDisplayPasswordMsg(true);
    }
  };

  const handleClosePasswordBalancePrompt = () => {
    setIsPasswordBalancePromptVisible(false);
    setDisplayPasswordMsg(false);
  };

  const handleClosePasswordPrompt = () => {
    setIsPasswordPromptVisible(false);
    setDisplayPasswordMsg(false);
  };

  const handleExistingAmountSubmit = (event) => {
    event.preventDefault();
    if (parseFloat(newExistingCashAmount) > parseFloat(newCashAmount)) {
      setAlertText("You can't add value more than your order amount");
      setShowAlert(true);
      // alert("You can't add value more than your order amount");
    }

    if (parseFloat(newExistingCashAmount) <= -parseFloat(existingBalance)) {
      setAllowFutureDates(!allowFutureDates);
      const amount = parseFloat(newExistingCashAmount) || 0;
      let updatedTotalPrice = totalPrice - amount;
      updatedTotalPrice = updatedTotalPrice.toFixed(2);
      setTotalPrice((prevPrice) => updatedTotalPrice);
      const newPayment = {
        id: Math.floor(Math.random() * (500 - 1 + 1)) + 1,
        method: "existing_balance",
        amount: newExistingCashAmount,
        info: "",
        date: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      setPayments((prevPayments) => [...prevPayments, newPayment]);
      setNewCashAmount((prevValue) => totalPrice);
      setExistingAmountPopup(false);
      // setCart([]);
      // const totalAmountAfterPayment = totalPrice - newCashAmount;
      // saveOrderToTable(customerId, totalAmountAfterPayment);
      // setPayments([]);
      // setExistingAmountPopup(false);
    } else {
      setAlertText("You do not have enough negative balance");
      setShowAlert(true);
      // setExistingAmountPopup(false);
    }
  };

  const handleFutureDatesPasswordSubmit = (event) => {
    event.preventDefault();
    // Replace this with your actual password validation logic
    const enteredPassword = event.target.password.value;

    if (enteredPassword === "3486") {
      setAllowFutureDates(!allowFutureDates);
      setIsFutureDatesPasswordPromptVisible((val) => false);
      setDisplayPasswordMsg(false);
    } else {
      setPasswordCorrectFutureDates((val) => false); // Set password correctness to false
      setDisplayPasswordMsg(true);
    }
  };

  const handleCloseFutureDatesPasswordPrompt = () => {
    setIsFutureDatesPasswordPromptVisible(false);
    setDisplayPasswordMsg(false);
  };

  const paymentsHandler = async () => {
    if (payingBalance) {
    
      // debugger
    
      let tempTotalAmountPaid = 0; // to check after comparison with balance, whether there is any more balance or not
      let sumAmount = 0; // to check after comparison with balance, whether there is any more balance or not
      for (const data of selectedData) {
        let invNo = data.invoiceNo;
        console.log(payments)

        if (payments.length > 0) {
          let ind = 0;

          payments.map((item)=> sumAmount= parseFloat(sumAmount) + parseFloat(item.amount))
          console.log(sumAmount)
          const paymentDetails = payments.map((item) => {
            tempTotalAmountPaid =
              parseFloat(tempTotalAmountPaid) + parseFloat(item.amount);
              if(data.balance > sumAmount){
                setPaymentAlertText(
                  `Do you want $${(data.balance- sumAmount)} (remaining amount) as balance for this order`
                );
                setPaymentAlert(true);
                
              }
              else{
                if (item.method === "credit_card") {
    
    
                  if (item.swipeData !== "") {
                    return {
                      SwipeData: item.swipeData,
                      CustomerID: customerId,
                      EmployeeId: localStorage.getItem("employeeID"),
                      InvoiceNo: invNo,
                      CreditDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Discount: 0,
                      CheckNo: "",
                      CreditAmt: item.amount,
                      PayType: item.method,
                      CardExpiry: "",
                      CardCvv: "",
                      CardZipcode: cardPayments[ind++].zipcode,
                      CheckDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Description: "Payment",
                      DepositNo: "1",
                      ARType: "1",
                      IsEdit: true,
                      IsBalance: balance != 0,
                    };
                  } else {
                    return {
                      SwipeData: "",
                      CustomerID: customerId,
                      EmployeeId: localStorage.getItem("employeeID"),
                      InvoiceNo: invNo,
                      CreditDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Discount: 0,
                      CheckNo: item.info,
                      CreditAmt:item.amount,
                      PayType: item.method,
                      CardExpiry: `${cardPayments[ind].expMonth}${cardPayments[ind].expYear}`,
                      CardCvv: cardPayments[ind].cvvpass,
                      CardZipcode: cardPayments[ind++].zipcode,
                      CheckDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Description: "Payment",
                      DepositNo: "1",
                      ARType: "1",
                      IsEdit: true,
                      IsBalance: balance != 0,
                    };
                  }
                } else {
                  // if(data.balance > newCashAmount){
                  //   setPaymentAlertText(
                  //     `Do you want $${totalPrice} (remaining amount) as balance for this order`
                  //   );
                  //   setPaymentAlert(true);
                  // }
                 
                  return {
                    CustomerID: customerId,
                    EmployeeId: localStorage.getItem("employeeID"),
                    InvoiceNo: invNo,
                    CreditDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Discount: 0,
                    CheckNo: item.info,
                    CreditAmt: item.amount,
                    PayType: item.method,
                    CardExpiry: "",
                    CardCvv: "",
                    CardZipcode: "",
                    CheckDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Description: "Payment",
                    DepositNo: "1",
                    ARType: "1",
                    IsEdit: true,
                    IsBalance:
                      balance != 0 ||
                      localStorage.getItem("increasedPrice") != null,
                  };
                }
              }
          });
          const val = localStorage.getItem("balance");
          if (val != null) {
            if (parseFloat(val) == tempTotalAmountPaid) {
              localStorage.removeItem("balance");
            }
          }
          console.log("Payment Details:- ", paymentDetails);
          if(paymentDetails[0] !== undefined){

            const token = localStorage.getItem("authToken");
           const response = await fetch(
              process.env.REACT_APP_API_ENDPOINT + "SavePaymentData/SavePayments",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(paymentDetails),
              }
            )
            
              console.log(response);
              if (response?.status === 401) {
                history.replace("/login/");
              }
  
              if (!response?.ok) {
                const t = await response.text()
                setAlertText(t||'error');
                setShowAlert(true);
                return;
              } else {
  
                
                setAlertText("Payment done successsfully");
                setShowAlert(true);
              }
              setPayments([]);
          }
        } else {
          setAlertText("Please add payment first");
          setShowAlert(true);
          // alert("No Payments to Save");
        }
      }
     
    } else {
      if (parseInt(totalPrice) > 0) {
        setPaymentAlertText(
          `Do you want $${totalPrice} (remaining amount) as balance for this order`
        );
        setPaymentAlert(true);
        // setIsPasswordPromptVisible(true);
      } else {
        if (!customerId) {
          alert("Please pick a Customer before placing an order.");
          return;
        }

        const totalAmountAfterPayment = totalPrice - paymentAmount;
        saveOrderToTable(customerId, totalAmountAfterPayment);
      }
    }
  };

  const saveOrderToTable = async (customerId) => {
    setIsLoading(true);
    let paymentIsCheck = false;

    localStorage.removeItem("orderresult");
    const token = localStorage.getItem("authToken");
    if (token == null) {
      history.replace("/login/");
    }
    // Calculate total amount of order
    const totalAmount = cart.reduce(
      (sum, item) => sum + item.price * item.count,
      0
    );
    let orderNo;
    let invNo;
    // Check if comment is "Order Note ..." and change it
    if (comment === "Order Note...") {
      comment = "";
    }
    let tempTotalAmountPaid = 0; // to check after comparison with balance, whether there is any more balance or not
    const OnActOB = +(toggledTotalPrice !== null && toggledTotalPrice !== 0);
    const isEdit = cart[0].InvoiceNo !== undefined;
    if (isEdit) {
      // Edit order

      const orderStatus = localStorage.getItem("orderStatus");

      if (deliveryOption === "Delivery") {
        localStorage.setItem("deliveryOption", deliveryOption);
      } else {
        localStorage.removeItem("deliveryOption");
      }
      const order2 = {
        CustomerID: customerId,
        EmployeeId: localStorage.getItem("employeeID"),
        Comment: comment,
        Subaccount: subaccount,
        ShipVia: deliveryOption,
        InvMsg: deliveryOption,
        DeliveryDate: deliveryDate,
        PriceCode: PriceCodeFromContext,
        Date: new Date().toISOString().slice(0, 19).replace("T", " "),
        OnActOB: OnActOB,
        InvoiceNo: cart[0].InvoiceNo,
        OrderStatus: orderStatus !== 1 && 4,
      };

      localStorage.removeItem("orderStatus");

      

      // Post the order to the Order table and retrieve the InvoiceNo
      let res = await fetch(process.env.REACT_APP_API_ENDPOINT + "invoice", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(order2),
      })

          if (res?.status === 401) {
            history.replace("/login/");
          }

          if(!res?.ok){

            const t = await res?.text()
            setAlertText(t||"Not successsfull");
            setShowAlert(true);
            return 
          }
          
          // Map each cart item to an order detail
          const invoiceDetails = cart.map((item) => {
            if (item.InvoiceNo != undefined) {
              invNo = item.InvoiceNo;
            }

            return {
              InvoiceNo: item.InvoiceNo,
              StyleNo: item.StyleNo,
              StyleDesc: item.StyleDesc,
              IDTno: item.IDTno,
              Price: item.price,
              Qty: item.quantity,
              //PriceTot: savedTotalPrice,
              PriceTot: item.price * item.quantity,
              PriceList: item.OriginalPrice,

              DiscountPercent: (
                ((item.OriginalPrice - item.price) / item.OriginalPrice) *
                100
              ).toFixed(2),
              IsEdit: isEdit,
            };
          });
          // Post the order details to the OrderDetail table

          res = await fetch(process.env.REACT_APP_API_ENDPOINT + "InvoiceDetail", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(invoiceDetails),
          });

          if (res?.status === 401) {
            history.replace("/login/");
          }
          
          if(!res?.ok){

            const t = await res?.text()
            setAlertText(t||"Not successsfull");
            setShowAlert(true);
            return 
          }
          
       
          let result;
          if (payments.length > 0) {
            let ind = 0;
            const paymentDetails = payments.map((item) => {
              tempTotalAmountPaid =
                parseFloat(tempTotalAmountPaid) + parseFloat(item.amount);
              if (item.method === "credit_card") {
                if (item.swipeData !== "") {
                  return {
                    SwipeData: item.swipeData,
                    CustomerID: customerId,
                    EmployeeId: localStorage.getItem("employeeID"),
                    InvoiceNo: invNo,
                    CreditDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Discount: 0,
                    CheckNo: "",
                    CreditAmt: item.amount,
                    PayType: item.method,
                    CardExpiry: "",
                    CardCvv: "",
                    CardZipcode: cardPayments[ind++].zipcode,
                    CheckDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Description: "Payment",
                    DepositNo: "1",
                    ARType: "1",
                    IsEdit: isEdit,
                    IsBalance: balance != 0,
                  };
                } else {
                  return {
                    SwipeData: "",
                    CustomerID: customerId,
                    EmployeeId: localStorage.getItem("employeeID"),
                    InvoiceNo: invNo,
                    CreditDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Discount: 0,
                    CheckNo: item.info,
                    CreditAmt: item.amount,
                    PayType: item.method,
                    CardExpiry: `${cardPayments[ind].expMonth}${cardPayments[ind].expYear}`,
                    CardCvv: cardPayments[ind].cvvpass,
                    CardZipcode: cardPayments[ind++].zipcode,
                    CheckDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Description: "Payment",
                    DepositNo: "1",
                    ARType: "1",
                    IsEdit: isEdit,
                    IsBalance: balance != 0,
                  };
                }
              } else {
                if (item.method == "check") {
                  paymentIsCheck = true;
                }
                return {
                  CustomerID: customerId,
                  EmployeeId: localStorage.getItem("employeeID"),
                  InvoiceNo: invNo,
                  CreditDate: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                  Discount: 0,
                  CheckNo: item.info,
                  CreditAmt: item.amount,
                  PayType: item.method,
                  CardExpiry: "",
                  CardCvv: "",
                  CardZipcode: "",
                  CheckDate: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " "),
                  Description: "Payment",
                  DepositNo: "1",
                  ARType: "1",
                  IsEdit: isEdit,
                  IsBalance:
                    balance != 0 ||
                    localStorage.getItem("increasedPrice") != null,
                };
              }
            });

            console.log(invNo);
            console.log(paymentDetails);
            //console.log("Payment Details:- ", paymentDetails);
            const val = localStorage.getItem("balance");
            if (val != null) {
              if (parseFloat(val) == tempTotalAmountPaid) {
                localStorage.removeItem("balance");
              }
            }

            result = await fetch(
              process.env.REACT_APP_API_ENDPOINT +
                "SavePaymentData/SavePayments",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(paymentDetails),
              }
            );
          } else {
            if (paymentIsCheck) {
              setAlertText(``);
              setShowAlert(true);
            }
            alertHandler(true, selectedOrderInvoice);
                  
          setSubAccount("");
          setDeliveryOption("");
          setDeliveryDate("");
          localStorage.removeItem('oDelivery');
          setSelectedDateOption("")
            // alert("No Payments to Save");
          }
   
            if (result?.status === 401) {
              history.replace("/login/");
            }
            localStorage.removeItem("increasedPrice");
            localStorage.removeItem("currSelected");
            localStorage.removeItem("newDeliFee");
            setCart([]);
            setPayments([]);
            console.log(result);
            if (!result.ok) {
              console.log("here");
              const t = await result?.text()
              
              setAlertText(t||"Order Not Updated, Something went wrong!");
              setShowAlert(true);
              // setIsLoading(false);
              //localStorage.setItem("orderresult", '1');
            } else {
              
              setSubAccount("");
              setDeliveryOption("");
              // debugger;
              setDeliveryDate("");
              setSelectedDateOption("")
              setIsLoading(false);
              handleShowAlert(totalDiscount);

              // alert("Order Not Created, Something went wrong!");
              //localStorage.setItem("orderresult", '0');
            }

            // alert("Order Updated Successfully!");
          

    } else {
      // New Order
      let order2;
      if (newAddressDetails === "") {
        if (deliveryOption === "Delivery") {
          localStorage.setItem("deliveryOption", deliveryOption);
        } else {
          if (deliveryDate == new Date().toISOString().slice(0, 10)) {
            localStorage.setItem("deliveryDateToday", "ok");
          } else {
            localStorage.removeItem("deliveryDateToday");
          }
          localStorage.removeItem("deliveryOption");
        }
        order2 = {
          CustomerID: customerId,
          EmployeeId: localStorage.getItem("employeeID"),
          Comment: comment,
          Subaccount: subaccount,
          ShipVia: deliveryOption,
          InvMsg: deliveryOption,
          DeliveryDate: deliveryDate,
          PriceCode: PriceCodeFromContext,
          Date: new Date().toISOString().slice(0, 19).replace("T", " "),
          OnActOB: OnActOB,
          Freight:
            deliveryOption === "Delivery" ? parseFloat(deliveryFee) : null,
          ShipType: deliveryOption === "Delivery" ? "D" : "P",
          ShipAdrs1: customerData.Address1,
          ShipAdrs2: customerData.Address2,
          ShipCity: customerData.City,
          ShipState: customerData.State,
          ShipZip: customerData.ZipCode,
          OrderStatus: 1,
          NewPOS: true,
        };
      } else {
        if (deliveryOption === "Delivery") {
          localStorage.setItem("deliveryOption", deliveryOption);
        } else {
          localStorage.removeItem("deliveryOption");
        }
        order2 = {
          CustomerID: customerId,
          EmployeeId: localStorage.getItem("employeeID"),
          Comment: comment,
          Subaccount: subaccount,
          ShipVia: deliveryOption,
          InvMsg: deliveryOption,
          DeliveryDate: deliveryDate,
          PriceCode: PriceCodeFromContext,
          Date: new Date().toISOString().slice(0, 19).replace("T", " "),
          OnActOB: OnActOB,
          Freight:
            deliveryOption === "Delivery" ? parseFloat(deliveryFee) : null,
          ShipType: deliveryOption === "Delivery" ? "D" : "P",
          ShipAdrs1: newAddressDetails.address1,
          ShipAdrs2: newAddressDetails.address2,
          ShipCity: newAddressDetails.newaddresscity,
          ShipState: newAddressDetails.newaddressstate,
          ShipZip: newAddressDetails.newaddresszip,
          OrderStatus: 1,
          NewPOS: true,
        };
      }
      // console.log(order2)

      // debugger

      // Post the order to the Order table and retrieve the InvoiceNo
     let res= await fetch(process.env.REACT_APP_API_ENDPOINT + "invoice", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(order2),
      })
          if (res?.status === 401) {
            history.replace("/login/");
          }
          
          if(!res?.ok){

            const t = await res?.text()
            setAlertText(t||"Not successsfull");
            setShowAlert(true);
            return 
          }
          
         let data = await res.json()
          orderNo = data.InvoiceNo; // Extract InvoiceNo from response

          selectedOrderInvoice = orderNo;
          // Map each cart item to an order detail
          const orderDetails = cart.map((item) => {
            return {
              InvoiceNo: orderNo,
              StyleNo: item.StyleNo,
              StyleDesc: item.StyleDesc,
              Price: item.price,
              Qty: item.quantity,
              //PriceTot: savedTotalPrice,
              ExistingBalanceUsed: existingBalCheck,
              PriceTot: item.price * item.quantity,
              PriceList: item.OriginalPrice,
              DiscountPercent: (
                ((item.OriginalPrice - item.price) / item.OriginalPrice) *
                100
              ).toFixed(2),
              IsEdit: isEdit,
            };
          });
          // Post the order details to the OrderDetail table
         res = await fetch(process.env.REACT_APP_API_ENDPOINT + "InvoiceDetail", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(orderDetails),
          });
        
       
          if (res?.status === 401) {
            history.replace("/login/");
          }
          if(!res?.ok){

            const t = await res?.text()
            setAlertText(t||"Not successsfull");
            setShowAlert(true);
            return 
          }
          
          //alert(result);
          console.log(payments);
          let payment;
          if (
            payments.length > 0 &&
            payments.filter((payment) => payment.method !== "existing_balance")
              .length > 0
          ) {
            let ind = 0;
            const paymentDetails = payments
              .filter((payment) => payment.method !== "existing_balance")
              .map((item, index) => {
                const singlePayment = cardPayments.find(
                  (payment) =>
                    payment.amount == item.amount &&
                    payment.cardNumber == item.info
                );

                if (item.method === "credit_card") {
                  if (item.swipeData !== "") {
                    return {
                      SwipeData: item.swipeData,
                      CustomerID: customerId,
                      EmployeeId: localStorage.getItem("employeeID"),
                      InvoiceNo: orderNo,
                      CreditDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Discount: 0,
                      CheckNo: "",
                      CreditAmt: item.amount,
                      PayType: item.method,
                      CardExpiry: "",
                      CardCvv: "",
                      CardZipcode: singlePayment.zipcode,
                      CheckDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Description: "Payment",
                      DepositNo: "1",
                      ARType: "1",
                      IsEdit: isEdit,
                    };
                  } else {
                    console.log(singlePayment);
                    return {
                      SwipeData: "",
                      CustomerID: customerId,
                      EmployeeId: localStorage.getItem("employeeID"),
                      InvoiceNo: orderNo,
                      CreditDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Discount: 0,
                      CheckNo: item.info,
                      CreditAmt: item.amount,
                      PayType: item.method,
                      CardExpiry: `${singlePayment.expMonth}${singlePayment.expYear}`,
                      CardCvv: singlePayment.cvvpass,
                      CardZipcode: singlePayment.zipcode,
                      CheckDate: new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      Description: "Payment",
                      DepositNo: "1",
                      ARType: "1",
                      IsEdit: isEdit,
                    };
                  }
                } else {
                  if (item.method == "check") {
                    paymentIsCheck = true;
                  }
                  return {
                    CustomerID: customerId,
                    EmployeeId: localStorage.getItem("employeeID"),
                    InvoiceNo: orderNo,
                    CreditDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Discount: 0,
                    CheckNo: item.info,
                    CreditAmt: item.amount,
                    PayType: item.method,
                    CardExpiry: "",
                    CardCvv: "",
                    CardZipcode: "",
                    CheckDate: new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    Description: "Payment",
                    DepositNo: "1",
                    ARType: "1",
                    IsEdit: isEdit,
                  };
                }
              });
            console.log(paymentDetails);
            payment = await fetch(
              process.env.REACT_APP_API_ENDPOINT +
                "SavePaymentData/SavePayments",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(paymentDetails),
              }
            );

            console.log(payment?.error);
          } else {
            setIsLoading(false);
            alertHandler(true, selectedOrderInvoice);
            setPayments([]);
            setCart([]);

            setSubAccount("");
            setDeliveryOption("");
            // debugger;
            setDeliveryDate("");
            setSelectedDateOption("")
            // alert("No Payments to Save");
          }
        
  
            if (payment?.status === 401) {
              history.replace("/login/");
            }
            console.log(payment);
            if (payment?.ok) {
              // alert('Order Created Successfully!');
              setIsLoading(false);

              popupHandler(selectedOrderInvoice, paymentIsCheck, totalDiscount);
              setCart([]);
              setPayments([]);
              
              setSubAccount("");
              setDeliveryOption("");
              // debugger;
              setDeliveryDate("");
              setSelectedDateOption("")
              //localStorage.setItem("orderresult", '1');
            } else {
              setIsLoading(false);
              const t = await payment?.text()
              setAlertText(t||"Order Not Created, Something went wrong!");
              setShowAlert(true);
              // alert("Order Not Created, Something went wrong!");
              //localStorage.setItem("orderresult", '0');
            }
          
    }
    
  };

  // const today = new Date();
  // const fourMonthsAgo = new Date(today);
  // fourMonthsAgo.setMonth(today.getMonth() - 4);
  // const maxDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  // const minDate = `${fourMonthsAgo.getFullYear()}-${String(fourMonthsAgo.getMonth() + 1).padStart(2, '0')}-${String(fourMonthsAgo.getDate()).padStart(2, '0')}`;

  const today = new Date();
  const fourMonthsAgo = new Date(today);
  fourMonthsAgo.setMonth(today.getMonth() - 4);

  //const [tempDate, setTempDate] = useState('');
  const [allowFutureDates, setAllowFutureDates] = useState(false);
  const [existingBalCheck, setExistingBalCheck] = useState(false);
  const [existingAmountPopup, setExistingAmountPopup] = useState(false);

  // useEffect(() => {
  //   if (existingBalance < 0) {
  //     setExistingBalCheck(true);
  //   }
  // }, []);

  const maxDate = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  const minDate = `${fourMonthsAgo.getFullYear()}-${String(
    fourMonthsAgo.getMonth() + 1
  ).padStart(2, "0")}-${String(fourMonthsAgo.getDate()).padStart(2, "0")}`;

  const handleAllowFutureDatesCheckboxChange = () => {
    setIsFutureDatesPasswordPromptVisible((val) => true);
  };

  const handleRemoveFromCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (cartItem) => cartItem.StyleNo === item.StyleNo
      );

      if (existingItem.quantity > 1) {
        // Decrease quantity if more than one
        return prevCart.map((cartItem) =>
          cartItem.StyleNo === item.StyleNo
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        );
      } else {
        // Remove item if only one left
        return prevCart.filter((cartItem) => cartItem.StyleNo !== item.StyleNo);
      }
    });
  };

  const [newExistingCashAmount, setNewExistingCashAmount] = useState(0);
  const handleExistingInputChange = (e) => {
    setNewExistingCashAmount(e.target.value);
  };
  useEffect(() => {
    const hasCreditCardPayment = payments.some(
      (item) => item.method === "credit_card"
    );
    if (hasCreditCardPayment) {
      const sortedArray = payments.sort((a, b) => {
        if (a.method === "credit_card") {
          return -1; // 'credit_card' comes first
        } else if (b.method === "credit_card") {
          return 1; // 'credit_card' comes second
        } else {
          return 0; // No change in order for other cases
        }
      });

      setPayments((prevValues) => sortedArray);
    }
  }, [payments]);

  return (
    // <div style={{placeOrder}}> placeOrder is being removed for the time being
    <>
      {showAlert && (
        <GeneralAlert popupText={alertText} onClose={handleCloseAlert} />
      )}
      {paymentAlert && (
        <PaymentPopup
          popupText={paymentAlertText}
          onClose={handleClosePaymentAlert}
          setIsPasswordPromptVisible={setIsPasswordPromptVisible}
        />
      )}
      <div style={{ position: "relative" }}>
        <div style={{ width: "100%", height: "auto", boxSizing: "border-box" }}>
          <div
            style={{
              backgroundColor: "#1f1d2b",
              color: "white",
              padding: "10px",
              border: "solid 2px #673F36",
              borderRadius: "10px",
              // marginTop: "-75%",
            }}
          >
            <div>
              <h4
                style={{
                  borderBottom: "2px solid rgb(103, 63, 54)",
                  paddingBottom: "18px",
                }}
              >
                Payment Methods
              </h4>
              {/* <p style={{ color: "grey", fontSize: '15px', fontWeight: 'normal' }}>Available Payment Methods:</p> */}
              {/* <hr
                            style={{
                                border: "1px solid #673F36",
                                opacity: "1"
                            }}
                        /> */}
            </div>
            <div>
              {/* <label>Payment Method</label> */}
              {isLoading ? (
                <div style={{ position: "absolute", top: "50%", right: "40%" }}>
                  <LoadingSpinner />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  // flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1f1d2b",
                    padding: "5px 15px",
                    borderRadius: "15px",
                    display: "inline-block",
                    margin: "5px",
                    textAlign: "center",
                  }}
                >
                  {/* <CiCreditCard2 */}
                  <BsCreditCard
                    size={48}
                    onClick={() =>
                      handlePaymentMethodChange({
                        target: { value: "credit_card" },
                      })
                    }
                    // style={{ cursor: 'pointer', color: paymentMethod === 'credit_card' ? 'white' : 'grey', border: paymentMethod === 'credit_card' ? '2px solid white' : '2px solid grey', }}
                    style={{
                      cursor: "pointer",
                      color: paymentMethod === "credit_card" ? "white" : "grey",
                      border: paymentMethod === "credit_card",
                    }}
                  />
                  <p
                    style={{
                      color: paymentMethod === "credit_card" ? "white" : "grey",
                      marginTop: "5px",
                      marginBottom: 0,
                    }}
                  >
                    Card
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#1f1d2b",
                    padding: "5px 15px",
                    borderRadius: "15px",
                    display: "inline-block",
                    margin: "5px",
                    textAlign: "center",
                  }}
                >
                  {/* <AiOutlineDollar */}
                  <LiaWalletSolid
                    size={48}
                    onClick={() =>
                      handlePaymentMethodChange({ target: { value: "cash" } })
                    }
                    // style={{ cursor: 'pointer', color: paymentMethod === 'cash' ? 'white' : 'grey', border: paymentMethod === 'cash' ? '2px solid white' : '2px solid grey' }}
                    style={{
                      cursor: "pointer",
                      color: paymentMethod === "cash" ? "white" : "grey",
                      border: paymentMethod === "cash",
                    }}
                  />
                  <p
                    style={{
                      color: paymentMethod === "cash" ? "white" : "grey",
                      marginTop: "5px",
                      marginBottom: 0,
                    }}
                  >
                    Cash
                  </p>
                </div>
                {/* <div
                style={{
                  backgroundColor: "#1f1d2b",
                  padding: "5px 15px",
                  borderRadius: "15px",
                  display: "inline-block",
                  margin: "5px",
                  textAlign: "center",
                }}
              >
                <BsBoxArrowInUpRight
                  size={48}
                  onClick={() =>
                    handlePaymentMethodChange({ target: { value: "account" } })
                  }
                  // style={{ cursor: 'pointer', color: paymentMethod === 'account' ? 'white' : 'grey', border: paymentMethod === 'account' ? '2px solid white' : '2px solid grey' }}
                  style={{
                    cursor: "pointer",
                    color: paymentMethod === "account" ? "white" : "grey",
                    border: paymentMethod === "account",
                  }}
                />
                <p
                  style={{
                    color: paymentMethod === "account" ? "white" : "grey",
                    marginTop: "5px",
                    marginBottom:0,
                  }}
                >
                  Account
                </p>
              </div> */}
                <div
                  style={{
                    backgroundColor: "#1f1d2b",
                    padding: "5px 15px",
                    borderRadius: "15px",
                    display: "inline-block",
                    margin: "5px",
                    textAlign: "center",
                  }}
                >
                  <FaMoneyCheckAlt
                    size={48}
                    onClick={() =>
                      handlePaymentMethodChange({ target: { value: "check" } })
                    }
                    style={{
                      cursor: "pointer",
                      color: paymentMethod === "check" ? "white" : "grey",
                      border:
                        paymentMethod === "check"
                          ? "2px solid white"
                          : "2px solid grey",
                      padding: "3px",
                    }}
                  />
                  <p
                    style={{
                      color: paymentMethod === "acheck" ? "white" : "grey",
                      marginTop: "5px",
                      marginBottom: 0,
                    }}
                  >
                    Check
                  </p>
                </div>
              </div>

              {/* HERE PAYMENT OPTION ENDS */}

              {parseFloat(existingBalance) < 0 && (
                <div style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    name="negativeBalance"
                    id="negativeBalance"
                    checked={existingBalCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setExistingBalCheck(true);
                      } else {
                        setExistingBalCheck(false);
                      }
                    }}
                  />
                  <label for="negativeBalance" style={{ marginLeft: "10px" }}>
                    Use Existing Balance
                  </label>
                  <button
                    onClick={() => {
                      if (
                        parseFloat(newCashAmount) > -parseFloat(existingBalance)
                      ) {
                        setNewExistingCashAmount(-parseFloat(existingBalance));
                      } else {
                        setNewExistingCashAmount(newCashAmount);
                      }
                      setExistingAmountPopup(true);
                      // setZeroOutStanding(true);
                    }}
                    disabled={!existingBalCheck}
                    style={{
                      backgroundColor: "#e7d2b7",
                      color: "black",
                      marginLeft: "20px",
                      border: "none",
                      width: "60px",

                      borderRadius: "10px",
                      padding: "5px",
                      fontSize: "14px",
                      cursor: "pointer",
                      transition: "all 0.3s ease", // Ensure transitions apply on hover effects
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#d6c3a2"; // Slightly darker shade on hover
                      e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#e7d2b7";
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    OK
                  </button>
                </div>
              )}

              {existingAmountPopup && (
                <div
                  style={{
                    backgroundColor: "#1f1d2b",
                    color: "white",
                    padding: "15px",
                    height: "30%",
                    width: "35%",
                    borderRadius: "10px",
                    border: "solid 2px #673F36",
                    position: "fixed",
                    top: "50%",
                    right: "5%",
                    // transform: "translate(-50%, -50%)",
                    backgroundColor: "#1f1d2b",
                    zIndex: 4,
                  }}
                >
                  <form onSubmit={handleExistingAmountSubmit}>
                    <h5>Enter Amount</h5>
                    <hr
                      style={{
                        border: "1px solid #673F36",
                        opacity: "1",
                      }}
                    />
                    <label>
                      Amount:
                      <input
                        style={{
                          marginLeft: "15px",
                          backgroundColor: "white",
                          width: "320px",
                        }}
                        autoFocus
                        type="text"
                        name="existing"
                        value={newExistingCashAmount}
                        onChange={(e) => handleExistingInputChange(e)}
                      />
                    </label>
                    <button
                      style={{
                        backgroundColor: "#e7d2b7",
                        color: "black",
                        border: "none",
                        borderRadius: "10px",
                        padding: "5px",
                        margin: "5px",
                        fontSize: "18px",
                        cursor: "pointer",
                        width: "40%", // Add this line
                      }}
                      type="submit"
                    >
                      Confirm
                    </button>
                    <button
                      style={{
                        backgroundColor: "#e7d2b7",
                        color: "black",
                        border: "none",
                        borderRadius: "10px",
                        padding: "5px",
                        margin: "5px",
                        fontSize: "18px",
                        cursor: "pointer",
                        width: "40%", // Add this line
                      }}
                      onClick={() => {
                        setExistingAmountPopup(false);
                        // setZeroOutStanding(false)
                      }}
                    >
                      Cancel
                    </button>
                  </form>
                  {/* Display a message if the password is incorrect */}
                  {!isPasswordCorrectFutureDates && isDisplayPasswordMsg && (
                    <p>Wrong password. Please try again.</p>
                  )}
                </div>
              )}

              {paymentMethod === "credit_card" && (
                <>
                  <div
                    class="input-container"
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <div style={{ width: "25%" }}>
                          <label style={{ color: "white", marginTop: "5px" }}>
                            Amount
                          </label>
                          <input
                            type="text"
                            name="amount"
                            value={cardPaymentAmount}
                            disabled={existingBalCheck }
                            //onChange={handleCardPaymentDetailsChange}
                            onChange={(e) =>
                              setCardPaymentAmount(e.target.value)
                            }
                            autocomplete="off"
                            style={{
                              marginBottom: 0,
                              marginTop: 0,
                              backgroundColor: "#252836",
                              color: "white",
                              border: "2px solid #252836",
                              // Add a border
                              borderRadius: "10px",
                              // Make it rounded
                            }}
                          />
                        </div>
                        <div style={{ width: "75%" }}>
                          <div>
                            <input
                              style={{
                                width: "10%",
                                marginBottom: 0,
                              }}
                              id="swipe"
                              type="checkbox"
                              checked={isSwipeCheckboxChecked}
                              onChange={handleSwipeCheckboxChange}
                              disabled={existingBalCheck}
                            />
                            <label for="swipe">
                              <span>Swipe</span>
                            </label>
                          </div>
                          <input
                            type="text"
                            name="swipe"
                            value={swipeData}
                            onChange={handleCardPaymentDetailsChange}
                            disabled={
                              !isSwipeCheckboxChecked || existingBalCheck
                            }
                            ref={inputRef}
                            style={{
                              marginBottom: 0,
                              marginTop: 0,
                              backgroundColor: isSwipeCheckboxChecked
                                ? "#252836"
                                : "rgb(21, 23, 30)",
                              color: "white",
                              border: "2px solid #252836",
                              // Add a border
                              borderRadius: "10px",
                              // Make it rounded
                            }}
                          />
                        </div>
                      </div>
                      {/* <div >
                        <label style={{ color: "white", marginTop: "5px" }}>
                          Cardholder Name
                        </label>
                        <input
                          type="text"
                          name="cardname"
                          onChange={handleCardPaymentDetailsChange}
                          autocomplete="off"
                          ref={cardholdernameRef}
                          disabled={existingBalCheck}
                          style={{
                            marginBottom: 0,
                            marginTop: 0,
                            backgroundColor: "#252836",
                            color: "white",
                            border: "2px solid #252836",
                            // Add a border
                            borderRadius: "10px",
                            // Make it rounded
                          }}
                        />
                      </div>  */}

                      <label style={{ color: "white", marginTop: "5px" }}>
                        Card Number
                      </label>
                      <input
                        type="text"
                        name="cardnum"
                        disabled={isSwipeCheckboxChecked || existingBalCheck}
                        onChange={handleCardPaymentDetailsChange}
                        autocomplete="off"
                        ref={cardNumberRef}
                        style={{
                          marginBottom: 0,
                          marginTop: 0,
                          backgroundColor: !isSwipeCheckboxChecked
                            ? "#252836"
                            : "rgb(21, 23, 30)",
                          color: "white",
                          border: "2px solid #252836",
                          // Add a border
                          borderRadius: "10px",
                          //  Make it rounded
                        }}
                      />

                      <div>
                        <label style={{ color: "white", marginTop: "5px" }}>
                          ZIP Code
                        </label>
                        {/* ZIP Code field added */}
                        <input
                          type="text"
                          name="zipcode"
                          disabled={existingBalCheck}
                          onChange={handleCardPaymentDetailsChange}
                          autoComplete="off"
                          value={zipCode == "null" ? "" : zipCode}
                          style={{
                            marginBottom: 0,
                            marginTop: 0,
                            backgroundColor: "#252836",
                            color: "white",
                            border: "2px solid #252836",
                            borderRadius: "10px",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ paddingRight: "20px", marginTop: "5px" }}>
                          <label style={{ color: "white" }}>
                            Expiration Date
                          </label>
                          <div style={{ display: "flex" }}>
                            <select
                              disabled={isSwipeCheckboxChecked}
                              onChange={handleCardMonthChange}
                              ref={cardExpiryMonthRef}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                backgroundColor: !isSwipeCheckboxChecked
                                  ? "#252836"
                                  : "rgb(21, 23, 30)",
                                color: "white",
                                border: "2px solid #252836",
                                borderRadius: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <option value="">Month</option>
                              {[...Array(12).keys()].map((i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                            <select
                              disabled={isSwipeCheckboxChecked}
                              onChange={handleCardYearChange}
                              ref={cardExpiryYearRef}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                backgroundColor: !isSwipeCheckboxChecked
                                  ? "#252836"
                                  : "rgb(21, 23, 30)",
                                color: "white",
                                border: "2px solid #252836",
                                borderRadius: "10px",
                              }}
                            >
                              <option value="">Year</option>
                              {[...Array(20).keys()].map((i) => (
                                <option
                                  key={i + new Date().getFullYear()}
                                  value={i + new Date().getFullYear()}
                                >
                                  {i + new Date().getFullYear()}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                          <label style={{ color: "white" }}>CVV</label>
                          <input
                            disabled={isSwipeCheckboxChecked}
                            type="password"
                            name="cvvpass"
                            onChange={handleCardPaymentDetailsChange}
                            ref={cardCvvRef}
                            autocomplete="off"
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              backgroundColor: !isSwipeCheckboxChecked
                                ? "#252836"
                                : "rgb(21, 23, 30)",
                              color: "white",
                              border: "2px solid #252836",
                              // Add a border
                              borderRadius: "10px",
                              // Make it rounded
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={addCardPayment}
                      style={{
                        backgroundColor: "#e7d2b7",
                        color: "black",
                        border: "none",
                        borderRadius: "10px",
                        //padding: '2px 2px',
                        fontSize: "18px",
                        cursor: "pointer",
                        width: "25%",
                        marginBottom: "0px",
                        transition: "all 0.3s ease", // Ensure transitions apply on hover effects
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "#d6c3a2"; // Slightly darker shade on hover
                        e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "#e7d2b7";
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                      disabled={
                        ((cardPaymentDetails.cardnum === "" ||
                          cardPaymentDetails.cardnum === undefined ||
                          cardPaymentDetails.expMonth === undefined ||
                          cardPaymentDetails.expYear === undefined) &&
                          !isSwipeCheckboxChecked) ||
                        (swipeData === "" && isSwipeCheckboxChecked)
                      }
                    >
                      Apply
                    </button>
                  </div>
                </>
              )}
              {paymentMethod === "cash" && (
                <div className="input-container">
                  <label>Enter Cash Amount:</label>
                  <input
                    type="text"
                    value={newCashAmount} // <-- Controlled component
                    disabled={existingBalCheck }
                    onChange={(e) => setNewCashAmount(e.target.value)} // <-- Set state
                    style={{
                      backgroundColor: "#252836",
                      color: "white",
                      border: "2px solid #252836",
                      borderRadius: "10px",
                    }}
                  />
                  <button
                    onClick={cashPaymentHandler}
                    style={{
                      backgroundColor: "#e7d2b7",
                      color: "black",
                      border: "none",
                      borderRadius: "10px",
                      //padding: '2px 2px',
                      fontSize: "18px",
                      cursor: "pointer",
                      width: "25%",
                      marginTop: "20px",
                      transition: "all 0.3s ease", // Ensure transitions apply on hover effects
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#d6c3a2"; // Slightly darker shade on hover
                      e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#e7d2b7";
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    Apply
                  </button>{" "}
                  {/* <-- New Button */}
                  <p style={{ color: "white", marginTop: "10px" }}>
                    Total Amount: ${totalPrice}
                  </p>
                </div>
              )}
              {paymentMethod === "account" && (
                <div className="input-container">
                  <br />
                  <label>Use Account</label>
                  {!isCheckboxEnabled && ( // Show passcode field if checkbox is not enabled
                    <>
                      <input
                        type="text"
                        placeholder="Enter passcode"
                        value={enteredPasscode}
                        disabled={existingBalCheck}
                        onChange={(e) => setEnteredPasscode(e.target.value)}
                      />
                      <button
                        onClick={checkPasscodeAndToggle}
                        style={{
                          backgroundColor: "#e7d2b7",
                          color: "black",
                          border: "none",
                          borderRadius: "10px",
                          //padding: '2px 2px',
                          fontSize: "18px",
                          cursor: "pointer",
                          width: "25%",
                          marginTop: "20px",
                          transition: "all 0.3s ease", // Ensure transitions apply on hover effects
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#d6c3a2"; // Slightly darker shade on hover
                          e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#e7d2b7";
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      >
                        Apply
                      </button>{" "}
                      {/* <-- New Apply Button */}
                    </>
                  )}
                  {isCheckboxEnabled && (
                    <>
                      <div>
                        <input
                          type="checkbox"
                          checked={toggledTotalPrice !== null}
                          disabled={existingBalCheck}
                          onChange={() => {
                            setToggledTotalPrice((prevToggledTotalPrice) => {
                              if (prevToggledTotalPrice === null) {
                                console.log(
                                  "toggledTotalPrice Active",
                                  totalPrice
                                );
                                setTotalPrice("0"); // Setting totalPrice to "0"
                                return totalPrice;
                              } else {
                                console.log(
                                  "prevToggledTotalPrice:",
                                  prevToggledTotalPrice
                                );
                                setTotalPrice(prevToggledTotalPrice); // Restoring totalPrice
                                return null;
                              }
                            });
                          }}
                        />
                        <label>Toggle Total Price</label>
                      </div>
                    </>
                  )}
                </div>
              )}
              {paymentMethod === "check" && (
                <div>
                  <div className="input-container">
                    <label htmlFor="tempCheckAmount">
                      Enter Check Amount:{" "}
                    </label>
                    <input
                      type="number"
                      id="tempCheckAmount"
                      min={0}
                      value={tempCheckAmount}
                      // disabled={payingBalance}
                      onChange={(e) =>
                        setTempCheckAmount(parseFloat(e.target.value))
                      }
                      style={{
                        backgroundColor: "#252836",
                        color: "white",
                        border: "2px solid #252836",
                        borderRadius: "10px",
                      }}
                    />
                    <br />
                    <label htmlFor="tempCheckNumber">
                      Enter Check Number:{" "}
                    </label>
                    <input
                      type="text"
                      id="tempCheckNumber"
                      value={tempCheckNumber}
                      onChange={(e) => setTempCheckNumber(e.target.value)}
                      style={{
                        backgroundColor: "#252836",
                        color: "white",
                        border: "2px solid #252836",
                        borderRadius: "10px",
                      }}
                    />
                    <br />
                    <div>
                      <label htmlFor="tempDate">Date: </label>
                      <input
                        // style={{ width: "10%", margintLeft: "117px" }}
                        type="checkbox"
                        checked={allowFutureDates}
                        onChange={handleAllowFutureDatesCheckboxChange}
                        style={{
                          backgroundColor: "#252836",
                          color: "white",
                          border: "2px solid #252836",
                          borderRadius: "10px",
                          width: "10%",
                          margintLeft: "117px",
                        }}
                      />
                      <span>Allow Future Dates</span>
                    </div>

                    <input
                      type="date"
                      id="tempDate"
                      value={tempDate}
                      min={minDate}
                      max={allowFutureDates ? undefined : maxDate}
                      onChange={(e) => setTempDate(e.target.value)}
                      style={{
                        backgroundColor: "#252836",
                        color: "white",
                        border: "2px solid #252836",
                        borderRadius: "10px",
                      }}
                    />
                    <br />
                  </div>
                  <button
                    onClick={addCheck}
                    style={{
                      backgroundColor: "#e7d2b7",
                      color: "black",
                      border: "none",
                      borderRadius: "10px",
                      //padding: '2px 2px',
                      fontSize: "18px",
                      cursor: "pointer",
                      width: "30%",
                      marginTop: "20px",
                      transition: "all 0.3s ease", // Ensure transitions apply on hover effects
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#d6c3a2"; // Slightly darker shade on hover
                      e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#e7d2b7";
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    Add Check
                  </button>
                  <table border="1">
                    <thead></thead>
                    <tbody></tbody>
                  </table>
                </div>
              )}
              <div
                style={{
                  display: "flex", // Enable Flexbox
                  justifyContent: "space-between", // Center items along the horizontal line
                  alignItems: "center", // Center items along the vertical line
                  // flexDirection: "column", // Stack items vertically
                }}
              >
                <button
                  onClick={paymentsHandler}
                  style={{
                    backgroundColor: "#e7d2b7",
                    color: "black",
                    border: "none",
                    borderRadius: "10px",
                    padding: "5px",
                    // padding: '2px 5px',
                    fontSize: "16px",
                    cursor: "pointer",
                    width: "50%",
                    transition: "all 0.3s ease", // Ensure transitions apply on hover effects
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#d6c3a2"; // Slightly darker shade on hover
                    e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#e7d2b7";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  Charge All Payments
                </button>
                <button
                  onClick={onCancel}
                  style={{
                    background: "none",
                    color: "#9A6447",
                    textDecoration: "underline",
                    fontSize: "16px",
                    cursor: "pointer",
                    width: "50%", // Add this line
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = "white"; // Slightly darker shade on hover
                    e.currentTarget.style.transform = "scale(1.05)"; // Slight scale for a "pop" effect
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = "#9A6447";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  Cancel All Payments
                </button>
              </div>
            </div>
          </div>
        </div>

        {isFutureDatesPasswordPromptVisible && (
          <div
            style={{
              backgroundColor: "#1f1d2b",
              color: "white",
              padding: "15px",
              height: "30%",
              width: "35%",
              borderRadius: "10px",
              border: "solid 2px #673F36",
              position: "fixed",
              top: "50%",
              right: "5%",
              // transform: "translate(-50%, -50%)",
              backgroundColor: "#1f1d2b",
              zIndex: 4,
            }}
          >
            <form onSubmit={handleFutureDatesPasswordSubmit}>
              <h5>Enter Password</h5>
              <hr
                style={{
                  border: "1px solid #673F36",
                  opacity: "1",
                }}
              />
              <label>
                Password:
                <input
                  style={{ marginLeft: "15px" }}
                  autoFocus
                  type="password"
                  name="password"
                />
              </label>
              <button
                style={{
                  backgroundColor: "#e7d2b7",
                  color: "black",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                type="submit"
              >
                Submit
              </button>
              <button
                style={{
                  background: "none",
                  color: "white",
                  textDecoration: "underline",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                onClick={handleCloseFutureDatesPasswordPrompt}
              >
                Cancel
              </button>
            </form>
            {/* Display a message if the password is incorrect */}
            {!isPasswordCorrectFutureDates && isDisplayPasswordMsg && (
              <p>Wrong password. Please try again.</p>
            )}
          </div>
        )}

        {isPasswordPromptVisible && (
          <div
            style={{
              backgroundColor: "#1f1d2b",
              color: "white",
              padding: "15px",
              height: "30%",
              width: "35%",
              borderRadius: "10px",
              border: "solid 2px #673F36",
              position: "fixed",
              top: "50%",
              right: "5%",
              // transform: "translate(-50%, -50%)",
              backgroundColor: "#1f1d2b",
              zIndex: 4,
            }}
          >
            <form onSubmit={handlePasswordSubmit}>
              <h5>Enter Password</h5>
              <hr
                style={{
                  border: "1px solid #673F36",
                  opacity: "1",
                }}
              />
              <label>
                Password:
                <input
                  style={{ marginLeft: "15px" }}
                  autoFocus
                  type="password"
                  name="password"
                />
              </label>
              <br />
              <button
                style={{
                  backgroundColor: "#e7d2b7",
                  color: "black",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                type="submit"
              >
                Submit
              </button>
              <button
                style={{
                  background: "none",
                  color: "white",
                  textDecoration: "underline",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                onClick={handleClosePasswordPrompt}
              >
                Cancel
              </button>
            </form>
            {/* Display a message if the password is incorrect */}
            {!isPasswordCorrect && isDisplayPasswordMsg && (
              <p>Wrong password. Please try again.</p>
            )}
          </div>
        )}
        {isPasswordBalancePromptVisible && (
          <div
            style={{
              backgroundColor: "#1f1d2b",
              color: "white",
              padding: "15px",
              height: "30%",
              width: "35%",
              borderRadius: "10px",
              border: "solid 2px #673F36",
              position: "fixed",
              top: "50%",
              right: "5%",
              // transform: "translate(-50%, -50%)",
              backgroundColor: "#1f1d2b",
              zIndex: 4,
            }}
          >
            <form onSubmit={handlePasswordBalanceSubmit}>
              <h5>Enter Password</h5>
              <hr
                style={{
                  border: "1px solid #673F36",
                  opacity: "1",
                }}
              />
              <label>
                Password:
                <input
                  style={{ marginLeft: "15px" }}
                  autoFocus
                  type="password"
                  name="password"
                />
              </label>
              <br />
              <button
                style={{
                  backgroundColor: "#e7d2b7",
                  color: "black",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                type="submit"
              >
                Submit
              </button>
              <button
                style={{
                  background: "none",
                  color: "white",
                  textDecoration: "underline",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  width: "40%", // Add this line
                }}
                onClick={handleClosePasswordBalancePrompt}
              >
                Cancel
              </button>
            </form>
            {/* Display a message if the password is incorrect */}
            {!isPasswordCorrect && isDisplayPasswordMsg && (
              <p>Wrong password. Please try again.</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PlaceOrder;
