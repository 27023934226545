import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import "../styles/App.css";
import { CustomerContext } from "../context/CustomerContext";
import { nanoid } from "nanoid";
import GeneralAlert from "../components/GeneralAlert";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";


export class Home extends Component {
  static contextType = CustomerContext;
  constructor(props) {
    super(props);
    this.state = {
      adressRef:createRef(),
      customers: [],
      orders: [],
      modalTitle: "",
      CustomerID: 0,
      PriceCode: 0,
      Name: "",
      NameLast: "",
      NameFirst: "",
      Address1: "",
      Address2: "",
      //Key for autocomplete location
      GOOGLE_API_KEY: "AIzaSyB62k0-8YzdEYoR_9Y68c9eWFHKSHxHtl4",
      City: "",
      State: "",
      ZipCode: "",
      Phone1: "",
      IsValidPhoneNumber: true,
      EmailAdrs: "",
      IsEmailValid: true,
      showAlert: false,
      alertText: "",
      searchInput:
        localStorage.getItem("searchValue") == null
          ? ""
          : localStorage.getItem("searchValue").toString(),
      offset: 0,
      nameFirstError: "",
      nameLastError: "",
      phone1Error: "",
      //Variables to accecpt filter inputs
      CustomerFilter: "",
      CustomersWithoutFilter: [], //backup array to store data without filter
    };
    this.createClick = this.createClick.bind(this);
    this.updateClick = this.updateClick.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  handleButtonClick = (CustomerID, NameFirst, NameLast, ZipCode, PriceCode) => {
    const { setCustomer } = this.context;
    this.setState(
      {
        CustomerID: CustomerID,
        NameFirst: NameFirst,
        PriceCode: PriceCode,
        ZipCode: ZipCode,
      },
      () => {
        setCustomer(
          this.state.CustomerID,
          this.state.NameFirst,
          this.state.PriceCode,
          this.state.ZipCode
        );
        localStorage.setItem("NameFirst", NameFirst);
        localStorage.setItem("NameLast", NameLast);
        localStorage.setItem("ZipCode", ZipCode);
        localStorage.setItem("PriceCode", PriceCode);
        localStorage.setItem("CustomerID", CustomerID);
        localStorage.removeItem('oDelivery')
        console.log("CustomerID", this.state.CustomerID);
        console.log("NameFirst", this.state.NameFirst);
        console.log("NameLast", this.state.NameLast);
        console.log("ZipCode", this.state.ZipCode);
        console.log("PriceCode", this.state.PriceCode);

        this.props.history.push("/order/" + CustomerID);
      }
    );
  };
  closeAlert(){
    this.setState({ showAlert: false });
  }
  clearSearchInput = () => {
    this.setState({ searchInput: "" });
    this.FilterFn();
    localStorage.removeItem("filteredCustomers"); // Clear stored filtered customers
  };

  ///Filter stuff////////////////////////////////
  FilterFn() {
    const token = localStorage.getItem("authToken");
    if(token == null){
      this.props.history.push("/login/")
    }
    const { CustomerFilter, offset } = this.state;
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "customer/filter?filter=" +
        encodeURIComponent(CustomerFilter) +
        "&offset=" +
        offset,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
          this.props.history.push("/login/")
        }
        return response.json()})
      .then((data) => {
        console.log(data)

        this.setState(
          (prevState) => ({
            customers: data,
          }),
          () => {
            // Save the filtered customers to localStorage
            localStorage.setItem(
              "filteredCustomers",
              JSON.stringify(this.state.customers)
            );
          }
        );
      });
  }

  loadMore = () => {
    this.setState(
      (prevState) => ({
        offset: prevState.offset + 20,
      }),
      () => {
        this.FilterFn();
      }
    );
  };

  // sortResult(prop, asc) {
  //     var sortedData = this.state.CustomersWithoutFilter.sort(function (a, b) {
  //         if (asc) {
  //             return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
  //         }
  //         else {
  //             return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
  //         }
  //     });

  //     this.setState({ customers: sortedData });
  // }

  changeCustomerNameFirstFilter = (e) => {
    this.state.CustomerFilter = e.target.value;
    this.FilterFn();
  };

  changeCustomerNameFilter = (e) => {
    const filterValue = e.target.value;
    localStorage.removeItem("searchValue");
    localStorage.setItem("searchValue", filterValue);
    this.setState({ searchInput: filterValue });
    this.setState({ CustomerFilter: filterValue }, () => {
      // Check if Enter key was pressed
      if (e.key === "Enter") {
        this.FilterFn();
      } else if (filterValue.length === 0) {
        // If the input is cleared, reset the customers list to the original data
        this.setState({
          customers: this.state.CustomersWithoutFilter,
          offset: 0,
        });
      } else {
      }
    });
  };

  changePhoneFilter = (e) => {
    this.state.CustomerFilter = e.target.value;
    this.FilterFn();
  };

  changeEmailFilter = (e) => {
    this.state.CustomerFilter = e.target.value;
    this.FilterFn();
  };

  ////////End Filter///////////////

  refreshList() {
    const token = localStorage.getItem("authToken");
    if(token == null){
      this.props.history.push("/login/")
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "customer",{
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      }})
      .then((response) => {
        if(response?.status === 401){
          this.props.history.push("/login/")
        }
        return response.json()})
      .then((data) => {
        this.setState({ customers: data, CustomersWithoutFilter: data });
      });

    fetch(process.env.REACT_APP_API_ENDPOINT + "invoice", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      }})
      .then((response) =>{
        if(response?.status === 401){
          this.props.history.push("/login/")
        }
        return response.json()})
      .then((data) => {
        this.setState({ orders: data });
      });
  }

  componentDidMount() {
    //alert(localStorage.getItem('searchValue'));
    const storedFilteredCustomers = localStorage.getItem("filteredCustomers");
    if (storedFilteredCustomers) {
      this.setState({ customers: JSON.parse(storedFilteredCustomers) });
    } else {
      this.FilterFn();
    }

    // const { customerContextValue } = this.context;

    // // Retrieve the NameFirst from local storage
    // let NameFirst = localStorage.getItem('NameFirst');

    // // Check if the customerContextValue is empty (customerContextValue is undefined or null)
    // if (!customerContextValue) {
    //     // If NameFirst from local storage is also empty, set CustomerID to 0 and NameFirst to "Select Customer"
    //     if (!NameFirst) {
    //         this.setState({ CustomerID: 0, NameFirst: "Select Customer" });
    //     } else {
    //         // If NameFirst has a value, set CustomerID to 0 (since we don't have a customer ID in the context) and NameFirst to the value from local storage
    //         this.setState({ CustomerID: 0, NameFirst: NameFirst });
    //     }
    // } else {
    //     // If customerContextValue is not empty, set CustomerID and NameFirst accordingly
    //     this.setState({ CustomerID: customerContextValue.customerId, NameFirst: customerContextValue.customerNameFirst });
    // }

    // // Fetch the data and update the state as needed
    // this.refreshList();




  //   const autocomplete = new window.google.maps.places.Autocomplete(
  //     document.getElementById('autocomplete')
  // );

  // // Listen for the 'place_changed' event
  // autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }


  
  // Define the handler for when a place is selected
// handlePlaceChanged = () => {
//   const place = document.getElementById('autocomplete').value;
//   // Handle the selected place (e.g., update state, perform further actions)
//   console.log('Selected place:', place);
// }

  changecustomerNameLast = (e) => {
    this.setState({ NameLast: e.target.value });
  };

  changecustomerNameFirst = (e) => {
    this.setState({ NameFirst: e.target.value });
  };

  changecustomerAddress1 = (value) => {
    if(value.value.terms.length >=3){
      const addressSlice = value.value.terms.slice(0, value.value.terms.length - 3);
      console.log(addressSlice);
      this.setState({ Address1: addressSlice.map(term => term.value).join(', ') });

      this.setState({ State: value.value.terms[value.value.terms.length-2].value});
      this.setState({ City: value.value.terms[value.value.terms.length-3].value});
      
      // const element = document.querySelector('. css-1qrxvr1-singleValue');
      // if (element) {
      //     element.innerHTML = ''; // Set inner HTML to empty string
      // }
      // const other = document.querySelector('. css-1jqq78o-placeholder');
      // if (other) {
      //     other.innerHTML = this.state.Address1; // Set inner HTML to empty string
      // }
    }else {
    this.setState({ Address1: value.label });

    }
  };


  changecustomerAddress2 = (e) => {
    this.setState({ Address2: e.target.value });
  };

  changecustomerCity = (e) => {
    this.setState({ City: e.target.value });
  };

  changecustomerState = (e) => {
    this.setState({ State: e.target.value });
  };

  changecustomerZipCode = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, "");
    const zipCode = sanitizedValue.slice(0, 5);
    this.setState({ ZipCode: zipCode });
  };

  changecustomerPhone1 = (e) => {
    const phone = e.target.value;
    this.setState({ Phone1: phone });
    const phoneRegex = /^\d{10}$/;
    this.setState({ IsValidPhoneNumber: phoneRegex.test(phone) });
  };

  changecustomerEmailAdrs = (e) => {
    const email = e.target.value;
    this.setState({ EmailAdrs: email });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({ IsEmailValid: emailRegex.test(email) });
  };

  searchClick() {
    this.setState({ CustomerFilter: this.state.searchInput }, () => {
      this.FilterFn();
    });
  }

  addClick() {
    this.setState({
      modalTitle: "Add New Customer",
      //customerId:0,
      CustomerID: 0,
      Name: "",
      NameLast: "",
      NameFirst: "",
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      ZipCode: "",
      Phone1: "",
      EmailAdrs: "",
      PriceCode: "",
      PriceSrch: "",
    });
  }

  editClick(cus) {
    this.setState({
      modalTitle: "Edit Customer",
      CustomerID: cus.CustomerID,
      NameLast: cus.NameLast,
      NameFirst: cus.NameFirst,
      Address1: cus.Address1,
      Address2: cus.Address2,
      City: cus.City,
      State: cus.State,
      ZipCode: cus.ZipCode,
      Phone1: cus.Phone1,
      EmailAdrs: cus.EmailAdrs,
      PriceCode: cus.PriceCode,
    });
  }

  createClick() {
    // if (this.state.ZipCode.length < 5 && this.state.zipCode.length > 0) {
    //   this.setState({ alertText: "Please Enter 5 digit Zipcode" , showAlert: true });
    //   // alert("Please Enter 5 digit Zipcode");
    //   return;
    // }

    // if (!this.state.IsEmailValid) {
    //   this.setState({ alertText: "Please Enter Valid Email Address!" , showAlert: true });
    //   // alert("Please Enter Valid Email Address!");
    //   return;
    // }

    // if (!this.state.IsValidPhoneNumber) {
    //   this.setState({ alertText: "Please Enter Valid Phone Number!" , showAlert: true });
    //   // alert("Please Enter Valid Phone Number!");
    //   return;
    // }
    if (!this.state.NameFirst) {
      alert('First Name')
      this.setState({ nameFirstError: "NameFirst is required." });
      return;
    }

    if (!this.state.NameLast) {
      alert('Last Name')
      this.setState({ nameLastError: "NameLast is required." });
      return;
    }

    if (!this.state.Phone1) {
      alert('Phone')
      this.setState({ phone1Error: "Phone1 is required." });
      return;
    }

    // Clear any existing validation errors
    this.setState({
      nameFirstError: "",
      nameLastError: "",
      phone1Error: "",
    });
    const uniqueID = nanoid(7); // Generate a unique 5-character ID
    const fullName = `${this.state.NameFirst} ${this.state.NameLast}`; // Combine first and last name
    const token = localStorage.getItem("authToken");
    if(token == null){
      this.props.history.push("/login/")
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "customer", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        CustomerID: uniqueID,
        Name: fullName, // Use the combined name here
        NameLast: this.state.NameLast,
        NameFirst: this.state.NameFirst,
        Address1: this.state.Address1,
        Address2: this.state.Address2,
        City: this.state.City,
        State: this.state.State,
        ZipCode: this.state.ZipCode,
        Phone1: this.state.Phone1,
        EmailAdrs: this.state.EmailAdrs,
        PriceCode: this.state.PriceCode,
        Comment: this.state.Comment,
      }),
    })
      .then((res) =>{
        if(res?.status === 401){
          this.props.history.push("/login/")
        }
        return res.json()
      } 
      )
      .then(
        (result) => {
          this.refreshList();
          this.handleButtonClick(
            uniqueID,
            this.state.NameFirst,
            this.state.NameLast,
            this.state.ZipCode,
            this.state.PriceCode
          );
          // this.props.history.push(`/order/${uniqueID}`);
          // window.location.reload();
          // console.log(uniqueID)
          // alert(result);
        },
        (error) => {
          this.setState({ alertText: "Failed" , showAlert: true });
          // alert("Failed");
        }
      );
  }

  updateClick() {
    if (this.state.ZipCode.length < 5 && this.state.zipCode.length > 0) {
      this.setState({ alertText: "Please Enter 5 digit Zipcode" , showAlert: true });
      // alert("Please Enter 5 digit Zipcode");
      return;
    }
    const token = localStorage.getItem("authToken");
    if(token == null){
      this.props.history.push("/login/")
    }
    const fullName = `${this.state.NameFirst} ${this.state.NameLast}`; // Combine first and last name
    fetch(process.env.REACT_APP_API_ENDPOINT + "customer", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        CustomerID: this.state.CustomerID,
        Name: fullName, // Use the combined name here
        NameLast: this.state.NameLast,
        NameFirst: this.state.NameFirst,
        Address1: this.state.Address1,
        Address2: this.state.Address2,
        City: this.state.City,
        State: this.state.State,
        ZipCode: this.state.ZipCode,
        Phone1: this.state.Phone1,
        EmailAdrs: this.state.EmailAdrs,
        PriceCode: this.state.PriceCode,
      }),
    })
      .then((res) => {
        if(res?.status === 401){
          this.props.history.push("/login/")
        }
        return res.json()})
      .then(
        (result) => {
          this.setState({ alertText: result , showAlert: true });
          // alert(result);
          this.refreshList();
        },
        (error) => {
          this.setState({ alertText: "Failed" , showAlert: true });
          // alert("Failed");
        }
      );
  }

  deleteClick(id) {
    if (window.confirm("Are you sure?")) {
      const token = localStorage.getItem("authToken");
      if(token == null){
        this.props.history.push("/login/")
      }
      fetch(process.env.REACT_APP_API_ENDPOINT + "customer/" + id, {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
        .then((res) => {
          if(res?.status === 401){
            this.props.history.push("/login/")
          }
          return res.json()})
        .then(
          (result) => {
            this.setState({ alertText: result , showAlert: true });
            // alert(result);
            this.refreshList();
          },
          (error) => {
            this.setState({ alertText: "Failed" , showAlert: true });
            // alert("Failed");
          }
        );
    }
  }

  imageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(process.env.REACT_APP_API_ENDPOINT + "customer/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
         if(res?.status === 401){
           this.props.history.push("/login/")
         }
        return res.json()})
      .then((data) => {
        this.setState({ PhotoFileName: data });
      });
  };

  showOrdersHandler = () => {
    this.props.history.push("/showorders/");
  };

  render() {
    const {
      //orders,
      customers,
      modalTitle,
      CustomerID,
      NameLast,
      NameFirst,
      Address1,
      Address2,
      City,
      State,
      ZipCode,
      Phone1,
      EmailAdrs,
      PriceCode,
    } = this.state;

    // Set displayedName to "Select Customer" if NameFirst is empty, and to NameFirst otherwise
    const displayedName = NameFirst ? NameFirst : "Select Customer";

    return (
      <>
      {this.state.showAlert && <GeneralAlert popupText={this.state.alertText} onClose={this.closeAlert}/>}
      <div className="outer-container">
        <div className="title-date-container">
          <div>
            <h1>{displayedName}</h1>
          </div>
          <div>
            <span>
              {new Date().toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              {"  -  "}
              {new Date().toLocaleDateString("he-IL", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="table-main">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <input
                type="search"
                className="form-control2 m-2 search-input"
                onChange={this.changeCustomerNameFilter}
                value={this.state.searchInput}
                onKeyPress={this.changeCustomerNameFilter}
                placeholder="Search for customer"
                style={{
                  width: "auto",
                  maxWidth: "200px",
                  height: "38px",
                  borderRadius: "8px",
                  paddingLeft: "10px",
                }}
                autoFocus
              />
              <div
                className="d-flex flex-row align-items-center m-2"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  padding: "5px",
                  color: "black",
                  height: "38px",
                  width: "70px",
                  backgroundColor: "#1f1d2b",
                  cursor: "pointer",
                }}
                onClick={() => this.searchClick()}
              >
                <span
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Search
                </span>
              </div>
              <div
                className="d-flex flex-row align-items-center m-2"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  padding: "5px",
                  color: "black",
                  height: "38px",
                  width: "140px",
                  backgroundColor: "#1f1d2b",
                  cursor: "pointer",
                }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => this.addClick()}
              >
                <span
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Add New Customer
                </span>
              </div>
              <div
                className="d-flex flex-row align-items-center m-2"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  padding: "5px",
                  color: "black",
                  height: "38px",
                  width: "125px",
                  backgroundColor: "#1f1d2b",
                  cursor: "pointer",
                }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => this.showOrdersHandler()}
              >
                <span
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Show All Orders
                </span>
              </div>
            </div>
            <hr />
            {customers.length > 0 ? (
              <div className="table-container">
                <div style={{ overflow: "auto" }}>
                  <table className="table table-striped p-1 my-fixed-width-table">
                    <thead>
                      <tr>
                        <th className="table-header fixed-width-cell">Name</th>
                        <th className="table-header fixed-width-cell">Phone</th>
                        <th className="table-header fixed-width-cell">Email</th>
                        <th className="table-header fixed-width-cell">
                          Address
                        </th>
                        <th className="table-header">Actions</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {customers.map((cus) => {
                        return (
                          <tr
                            key={cus.CustomerID}
                            onClick={() =>
                              this.handleButtonClick(
                                cus.CustomerID,
                                cus.NameFirst,
                                cus.NameLast,
                                cus.ZipCode,
                                cus.PriceCode
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td className="my-table-cell fixed-width-cell">
                              {cus.NameFirst} {cus.NameLast}
                            </td>
                            <td className="my-table-cell fixed-width-cell">
                              {cus.Phone1}
                            </td>
                            <td className="my-table-cell fixed-width-cell">
                              {cus.EmailAdrs}
                            </td>
                            <td className="my-table-cell fixed-width-cell">
                              {cus.Address1}
                            </td>

                            <td>
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light mr-1 btn-sm"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "white",
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the row's onClick from being triggered
                                    this.editClick(cus);
                                  }}
                                >
                                  &#8230;
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {customers.length > 0 && (
                  <div className="center-container">
                    <button
                      className="btn transparent-button mt-3"
                      onClick={this.loadMore}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <p>No customers to display.</p>
            )}

            {/* <span style={{ marginRight: '20px' }}></span>
        <button
          type="button"
          className="btn btn-light mr-1 btn-sm"
          style={{ backgroundColor: '#324c4f', color: '4cc19f' }}
          onClick={() => this.deleteClick(cus.CustomerID)}
        >
          Delete
        </button> */}

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content"  style={{
              backgroundColor: "rgb(31, 29, 43)",
              border: "solid 2px #673F36",
              width: "100%",
              padding: "30px",
              borderRadius: "10px",
              position:
                "relative" /* or fixed, depending on your layout requirements */,
              top: "15%" /* Adjust the top position as needed */,
              right: "5%" /* Adjust the left position as needed */,
              zIndex: "20",
              opacity: "1",
            }}>
                  <div className="modal-header">
                    <h5 className="modal-title" style={{ color: "white" }}>
                      {modalTitle}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{ color: "white" }}
                    ></button>
                  </div>

                  <div className="modal-body">
                    <div className="d-flex flex-row bd-highlight mb-3">
                      <div className="p-2 bd-highlight" style={{width: "80%"}}>
                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>First Name</span>
                          <input
                            type="text"
                            className={`form-control ${
                              this.state.nameFirstError && "is-invalid"
                            }`}
                            value={this.state.NameFirst}
                            onChange={this.changecustomerNameFirst}
                            style={{height:"40px"}}
                          />
                          {this.state.nameFirstError && (
                            <div className="invalid-feedback">
                              {this.state.nameFirstError}
                            </div>
                          )}
                        </div>
                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>Last Name</span>
                          <input
                            type="text"
                            className={`form-control ${
                              this.state.nameLastError && "is-invalid"
                            }`}
                            value={this.state.NameLast}
                            onChange={this.changecustomerNameLast}
                            style={{height:"40px"}}
                          />
                          {this.state.nameLastError && (
                            <div className="invalid-feedback">
                              {this.state.nameLastError}
                            </div>
                          )}
                        </div>
                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>Adr1</span>
                          

{
  
}
<GooglePlacesAutocomplete
      apiKey={this.state.GOOGLE_API_KEY}
      
      selectProps={{
        
        onChange:  value => this.changecustomerAddress1( value),
        styles: {
          input: (provided) => ({
            ...provided,
            color: 'black',
            cursor: 'text',
            height: "30px",
            minWidth: "430px",
            width: "430px"
          }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'black',
          }),
        },
      }}
     
      
      
      />
        

    

                        </div>
                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>Adr2</span>
                          {/* <GooglePlacesAutocomplete
      apiKey={this.state.GOOGLE_API_KEY}
      selectProps={{
        Address2,
        onChange:  value => this.changecustomerAddress2(value),
        styles: {
          input: (provided) => ({
            ...provided,
            color: 'black',
            cursor: 'text',
            height: "30px",
            minWidth: "430px",
            width: "430px"
          }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'black',
          }),
        },
      }}
     
      
      
      /> */}
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={Address2}
                            onChange={this.changecustomerAddress2}
                            style={{height:"40px"}}
                          />
                        </div>

                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>City</span>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={City}
                            onChange={this.changecustomerCity}
                            style={{height:"40px"}}
                          />
                        </div>

                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>State</span>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={State}
                            onChange={this.changecustomerState}
                            style={{height:"40px"}}
                          />
                        </div>

                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>Zip</span>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.ZipCode}
                            onChange={this.changecustomerZipCode}
                            style={{height:"40px"}}
                          />
                        </div>
                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>Phone</span>
                          <input
                            type="text"
                            className={`form-control ${
                              this.state.phone1Error && "is-invalid"
                            }`}
                            value={this.state.Phone1}
                            onChange={this.changecustomerPhone1}
                            style={{height:"40px"}}
                          />
                          {this.state.phone1Error && (
                            <div className="invalid-feedback">
                              {this.state.phone1Error}
                            </div>
                          )}
                        </div>

                        <div className="input-group mb-3" style={{display:"flex", alignItems:"center"}}>
                          <span className="input-group-text" style={{height:"40px"}}>Email</span>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={EmailAdrs}
                            onChange={this.changecustomerEmailAdrs}
                            style={{height:"40px"}}
                          />
                        </div>
                      </div>
                      {/* <div className="p-2 w-50 bd-highlight">
         <img width="250px" height="250px"
         src={PhotoPath+PhotoFileName}/>
         <input className="m-2" type="file" onChange={this.imageUpload}/>
     </div> */}
                    </div>

                    {CustomerID === 0 ? (
                      <button
                        type="button"
                        className="btn btn-primary float-start"
                        onClick={() => this.createClick()}
                      >
                        Create
                      </button>
                    ) : null}

                    {CustomerID !== 0 ? (
                      <button
                        type="button"
                        className="btn btn-primary float-start"
                        onClick={() => this.updateClick()}
                      >
                        Update
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withRouter(Home);
