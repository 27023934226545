import React, { Component, createRef } from "react";
import matzo from "../images/matzo.png";
import CartContext from "../context/CartContext";
import Cart from "./Cart";
import { CustomerContext } from "../context/CustomerContext";
import PriceCodeDataContext from "../context/PriceCodeDataContext";
import "../styles/Inventory.css";
import PopupModal from "../components/PopupModal";
import AlertPopup from "../components/AlertPopup";
import GeneralAlert from "../components/GeneralAlert";

export class Inventory extends Component {
  
  _isMounted = false;
  static contextType = CustomerContext;
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      customers: [],
      inventories: [],
      matzaRef: createRef(),
      flourRef: createRef(),
      etcRef: createRef(),
      kimchaRef: createRef(),
      erevPesachRef: createRef(),
      modalTitle: "",
      StyleNo: 0,
      StyleDesc: "",
      Price: "",
      cartActive: false,
      activeTab: "Matza",
      popupShown: false,
      alertShown: false,
      totalUpdateAlert:false,
      singlePopupInvoice: 0,
      totalDiscount: 0,
      showAlert: false,
      alertText: "",
      activeTab: "Matza",
    };
    this.closeAlert = this.closeAlert.bind(this);
    this.handleHideAlert = this.handleHideAlert.bind(this);
  }
  closeAlert() {
    this.setState({ showAlert: false });
    this.setState({ alertShown: false });
  }
  handleHideAlert() {
    this.setState({ totalUpdateAlert: false });
  }
  handleActivePopup = (invoiceNumber, alertShow,discount ) => {
if(alertShow) {
  this.setState({ alertText: `Please write order # ${invoiceNumber} on all checks`, showAlert: true });
}
    this.setState({ popupShown: true });
    this.setState({ totalDiscount: discount });
    this.setState({ singlePopupInvoice: invoiceNumber });
  };
  handleActiveAlert = (alert, invoiceNumber) => {
    if (alert) {
      this.setState({ singlePopupInvoice: invoiceNumber });
      this.setState({ alertShown: true });
      
    }
  };
  handleInActiveAlert = () => {
    this.setState({ alertShown: false });
  };

  handleInActivePopup = () => {
    this.setState({ popupShown: false });
  };
  handleCartActive = () => {
    this.setState({ cartActive: true });
  };

  handleCartInactive = () => {
    this.setState({ cartActive: false });
  };
   handleShowAlert = (discount) => {
    this.setState({ totalDiscount: discount });
    this.setState({ totalUpdateAlert: true });

  };
  refreshList() {
    const token = localStorage.getItem("authToken");
    //this.context.priceCode = this.context.priceCode || '1';
    if(token == null){
      window.location.replace("/login/");
    }
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "inventory/GetByPriceCode/" +
        (this.context.priceCode || "1"),
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if(response?.status === 401){
          window.location.replace("/login/");

        }
        return response.json()}
        )
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            inventories: data,
            priceCode: this.context.priceCode,
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.context.priceCode !== prevState.priceCode) {
      this.refreshList();
    }
  }

  componentDidMount() {
    
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
    document.body.style.overflow = "auto";
    this._isMounted = true;
    this.setState({ priceCode: this.context.priceCode });
    this.refreshList();
    setTimeout(() => {
      const iframe = document.getElementsByTagName("iframe")[0];
      if (iframe) {
        iframe.remove();
      }
    }, 1000);
  }

  componentWillUnmount() {
    document.body.style.overflow = "initial";
    this._isMounted = false;
  }

  changeorderName = (e) => {
    this.setState({ orderNo: e.target.value });
  };

  changeLARGESTART = (e) => {
    this.setState({ LARGESTART: e.target.value });
  };

  addClick() {
    this.setState({
      modalTitle: "Add Inventory",
      StyleNo: 0,
      StyleDesc: "",
      Price: "",
      Price1: "",
      Price2: "",
      Price3: "",
      Price4: "",
      Price5: "",
      Price6: "",
      Price7: "",
      Price8: "",
    });
  }
  editClick(inv) {
    this.setState({
      modalTitle: "Edit Inventory",
      StyleNo: inv.StyleNo,
      StyleDesc: inv.StyleDesc,
      Price: inv.Price,
    });
  }

  createClick() {
    const token = localStorage.getItem("authToken");
    if(token == null){
      window.location.replace("/login/");

    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "inventory", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        StyleNo: this.state.StyleNo,
        StyleDesc: this.state.StyleDesc,
        Price: this.state.Price,
      }),
    })
      .then((res) =>{
        if(res?.status === 401){
          window.location.replace("/login/");

        }

        return res.json()
      } 
      )
      .then(
        (result) => {
          this.setState({ alertText: result, showAlert: true });
          // alert(result);
          this.refreshList();
        },
        (error) => {
          this.setState({ alertText: "Failed", showAlert: true });
          // alert("Failed");
        }
      );
  }

  updateClick() {
    const token = localStorage.getItem("authToken");
    if(token == null){
      window.location.replace("/login/");

    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "inventory", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        StyleNo: this.state.StyleNo,
        StyleDesc: this.state.StyleDesc,
        Price: this.state.Price,
      }),
    })
      .then((res) =>{
        if(res?.status === 401){
          window.location.replace("/login/");

        }
        return res.json()
      }
       )
      .then(
        (result) => {
          this.setState({ alertText: result, showAlert: true });
          // alert(result);
          this.refreshList();
        },
        (error) => {
          this.setState({ alertText: "Failed", showAlert: true });
          // alert("Failed");
        }
      );
  }

  deleteClick(id) {
    if (window.confirm("Are you sure?")) {
      const token = localStorage.getItem("authToken");
      if(token == null){
        window.location.replace("/login/");

      }
      fetch(process.env.REACT_APP_API_ENDPOINT + "inventory/" + id, {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
        .then((res) => {
          if(res?.status === 401){
            window.location.replace("/login/");

          }
          return res.json()})
        .then(
          (result) => {
            this.setState({ alertText: result, showAlert: true });
            // alert(result);
            this.refreshList();
          },
          (error) => {
            this.setState({ alertText: "Failed", showAlert: true });
            // alert("Failed");
          }
        );
    }
  }
  handleTabClick(tabName) {
    // this.setState({ activeTab: tabName }, () => {
    //   switch (tabName) {
    //     case "Matza":
    //       this.state.matzaRef.current.scrollIntoView({ behavior: 'smooth', block:"start" })
    //       // this.scrollToRef(this.state.matzaRef);
    //       break;
    //     case "Flour":
    //       this.state.flourRef.current.scrollIntoView({ behavior: 'smooth', block:"start" })
    //       // this.scrollToRef(this.state.flourRef);
    //       break;
    //     case "ETC":
    //       this.state.etcRef.current.scrollIntoView({ behavior: 'smooth', block:"start",  inline: 'nearest' })
    //       // this.scrollToRef(this.state.etcRef);
    //       break;
    //     case "Kimcha":
    //       this.state.kimchaRef.current.scrollIntoView({ behavior: 'smooth', block:"start",inline: 'nearest' })
    //       // this.scrollToRef(this.state.kimchaRef);
    //       break;
    //     default:
    //       break;
    //   }
    // });
    this.setState({ activeTab: tabName }, () => {
      let offset = -140; // Adjust this value as needed
      let targetElement = null;
      switch (tabName) {
        case "Matza":
          targetElement = this.state.matzaRef.current;
          break;
        case "Flour":
          targetElement = this.state.flourRef.current;
          break;
        case "ETC":
          targetElement = this.state.etcRef.current;
          break;
        case "Kimcha":
          targetElement = this.state.kimchaRef.current;
          break;
        case "ErevPesach":
          targetElement = this.state.erevPesachRef.current;
          break;
        default:
          break;
      }
      if (targetElement) {
        const topPos = targetElement.getBoundingClientRect().top;
        window.scrollTo({
          top: topPos + window.pageYOffset + offset,
          behavior: "smooth",
        });
      }
    });
  }

  addToCart = (inventoryItem, cart, setCart) => {
      setTimeout(()=>{

        localStorage.removeItem('oDelivery');
      })
    
    console.log("Inventory Item:", inventoryItem);
    const newItem = {
      ...inventoryItem,
      StyleNo: `${inventoryItem.StyleNo}`,
      name: inventoryItem.StyleDesc,
      price: inventoryItem.Price,
      originalPrice: inventoryItem.OriginalPrice,
      discount: inventoryItem.OriginalPrice - inventoryItem.Price,
      quantity: 1, // Initialize quantity to 1
    };
    let newCart;
    // Check if item already exists in the cart
    console.log(cart)
    const existingItem = cart.find((item) => item.StyleNo === newItem.StyleNo);
    console.log(existingItem?.Balance)
    if (existingItem) {
      // if(existingItem.Balance !== undefined){
      //   newCart = cart.map((item) =>
      //   item.StyleNo === existingItem.StyleNo
      //     ? { ...item, newquantity: item.newquantity ? item.newquantity + 1 : 1 }
      //     : item
      // );
      // }
      // else {
        // Increase quantity if item already exists
        newCart = cart.map((item) =>
          item.StyleNo === existingItem.StyleNo
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      // }
    } else {
      // Add new item to the cart
      newCart = [...cart, newItem];
    }

    // Update the cart in the state
    setCart(newCart);
    // Calculate new total price
    const totalPrice = newCart.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    // Update totalPrice in localStorage
    if(existingItem?.Balance || existingItem?.Balance == 0){
      const getCurr = localStorage.getItem("currSelected")
      if(getCurr){
        let newOne =parseFloat(getCurr)+parseFloat(newItem.price)
      localStorage.setItem("currSelected",newOne );
      } else {
        localStorage.setItem("currSelected",newItem.price );
      }

    }
    localStorage.setItem("totalPrice", totalPrice.toFixed(2));
  };

  render() {
    const { balance, getTabTitle, displayedName, PriceCode, customerData } =
      this.props;
    const { inventories, modalTitle, StyleNo } = this.state;
    return (
      <>
        {this.state.showAlert && (
          <GeneralAlert
            popupText={this.state.alertText}
            onClose={this.closeAlert}
          />
        )}
        {this.state.popupShown && this.state.singlePopupInvoice ? (
          <PopupModal
            onClose={this.handleInActivePopup}
            invoiceNo={this.state.singlePopupInvoice}
            discount={this.state.totalDiscount}
          />
        ) : null}
        {this.state.alertShown ? (
          <AlertPopup
            onClose={this.handleInActiveAlert}
            popupText="
No payment done but total balance has been updated."
            invoiceNo={this.state.singlePopupInvoice}
          discount={this.state.totalDiscount}

          />
        ) : null}
        {this.state.totalUpdateAlert ? (
         
<AlertPopup
          onClose={this.handleHideAlert}
          popupText={`Order Updated Successfully with a total discount of $${this.state.totalDiscount} on complete order`}
          discount={this.state.totalDiscount}
        />
        ) : null}

        <CustomerContext.Consumer>
          {({ customerId, customerNameFirst, setCustomer, setPriceCode }) => (
            <PriceCodeDataContext.Consumer>
              {({ priceCodeData, setPriceCodeData }) => (
                <CartContext.Consumer>
                  {([cart, setCart, isPlacingOrder]) => (
                    <div
                      className="outer-container"
                      style={{ paddingLeft: "0px", marginBottom: "50px" }}
                    >
                      {/* Nav Tabs
                      <div
                        className="nav-tabs-container"
                        style={{
                          position: "sticky",
                          top: 130,
                          zIndex: 10,
                          background: "#252836",
                        }}
                      >
                        <ul className="nav nav-tabs navTabInventory">
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                this.state.activeTab === "Matza" ? "active" : ""
                              }`}
                              onClick={() =>
                                this.setState({ activeTab: "Matza" })
                              }
                            >
                              Matza
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                this.state.activeTab === "Flour" ? "active" : ""
                              }`}
                              onClick={() =>
                                this.setState({ activeTab: "Flour" })
                              }
                            >
                              Flour
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                this.state.activeTab === "ETC" ? "active" : ""
                              }`}
                              onClick={() =>
                                this.setState({ activeTab: "ETC" })
                              }
                            >
                              ETC
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                this.state.activeTab === "Kimcha"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                this.setState({ activeTab: "Kimcha" })
                              }
                            >
                              Kimcha
                            </a>
                          </li>
                        </ul>
                      </div> */}

                      <div></div>

                      <div style={{ display: "flex" }}>
                        <div className="content" style={{ width: "55%" }}>
                          <div style={{ display: "flex", width: "100%" }}>
                            {/* Nav Tabs */}
                            <div
                              className="nav-tabs-container"
                              style={{
                                position: "fixed",
                                top: 130,
                                zIndex: 10,
                                background: "#252836",
                              }}
                            >
                              <ul
                                className="nav nav-tabs navTabInventory"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor:"pointer",
                                }}
                              >
                                <li className="nav-item" style={{width:"100px"}}>
                                  <a
                                    className={`nav-link ${
                                      this.state.activeTab === "Matza"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => this.handleTabClick("Matza")}
                                  >
                                    Matza
                                  </a>
                                </li>
                                <li className="nav-item" style={{width:"100px"}}>
                                  <a
                                    className={`nav-link ${
                                      this.state.activeTab === "Flour"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => this.handleTabClick("Flour")}
                                  >
                                    Flour
                                  </a>
                                </li>
                                <li className="nav-item" style={{width:"100px"}}>
                                  <a
                                    className={`nav-link ${
                                      this.state.activeTab === "ETC"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => this.handleTabClick("ETC")}
                                  >
                                    ETC
                                  </a>
                                </li>
                                <li className="nav-item" style={{width:"100px"}}>
                                  <a
                                    className={`nav-link ${
                                      this.state.activeTab === "Kimcha"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      this.handleTabClick("Kimcha")
                                    }
                                  >
                                    Kimcha
                                  </a>
                                </li>
                                <li className="nav-item" style={{width:"100px"}}>
                                  <a
                                    className={`nav-link ${
                                      this.state.activeTab === "ErevPesach"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      this.handleTabClick("ErevPesach")
                                    }
                                  >
                                    Erev Pesach
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div
                              className="inventory"
                              style={{ flex: cart && cart.length > 0 ? 1 : 1 }}
                            >
                              {/* Item Containers */}
                              <div style={{ marginLeft: "85px" }}>
                                <div ref={this.state.matzaRef}>
                                  <h6
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                      marginRight: "25px",

                                    }}
                                  >
                                    Matza
                                  </h6>
                                  <div className="item-container" style={{display:"flex", alignItems:"center", flexWrap:"wrap"}} >
                                    {inventories
                                      ?.filter?.((inv) => inv.PriceList === 1)
                                      ?.map((inv) => (
                                        <div
                                          className="col-sm-6 narrow-container"
                                          style={{
                                            width: "30%",
                                            marginTop:"10px",
                                            paddingLeft: "20px",
                                          }}
                                          key={inv.StyleNo}
                                          onClick={() =>
                                            this.addToCart(inv, cart, setCart)
                                          }
                                        >
                                          <div
                                            className="card d-flex align-items-center justify-content-center"
                                            style={{
                                              background:
                                                "linear-gradient(to bottom, transparent 30%, #1f1d2b 20%, #1f1d2b 100%)",
                                              backgroundPosition: "center 30%",
                                              border: "none",
                                            }}
                                          >
                                            <div className="image-circle">
                                              <img
                                                className="card-img-top rounded-circle"
                                                src={matzo}
                                                alt="Product"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              />
                                            </div>
                                            <div className="card-body">
                                              <h5
                                                className="card-title text-center"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "normal",
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                {inv.StyleDesc}
                                              </h5>
                                              {inv.Price ==
                                              inv.OriginalPrice ? (
                                                <p
                                                  className="card-text text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    lineHeight: "1.5",
                                                  }}
                                                >
                                                  ${inv.Price?.toFixed(2)}
                                                </p>
                                              ) : (
                                                <div>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    $
                                                    {inv.OriginalPrice.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      marginTop: "-15px",
                                                    }}
                                                  >
                                                    ${inv.Price?.toFixed(2)}
                                                  </p>
                                                </div>
                                              )}
                                            </div>

                                            <button
                                              style={{
                                                marginTop: 0,
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px", // Reduced padding
                                                fontSize: "14px", // Reduced font size
                                                border: "2px solid #2E8B57", // Added a slightly darker border
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                width: "80%",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                                transition: "all 0.3s ease", // Smooth transition for hover effects
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)"; // Slightly enlarge button on hover
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)"; // Reset size on mouse out
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                                              }}
                                              onClick={() =>
                                                this.addToCart(
                                                  inv,
                                                  cart,
                                                  setCart
                                                )
                                              }
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                <div ref={this.state.flourRef}>
                                  <h6
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                      marginRight: "25px",

                                    }}
                                  >
                                    Flour
                                  </h6>

                                  <div className="item-container" style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>
                                    {inventories
                                      ?.filter?.((inv) => inv.PriceList === 2)
                                      ?.map((inv) => (
                                        <div
                                          className="col-sm-6 narrow-container"
                                          style={{
                                            width: "30%",
                                            marginTop:"10px",

                                            paddingLeft: "20px",
                                          }}
                                          key={inv.StyleNo}
                                          onClick={() =>
                                            this.addToCart(inv, cart, setCart)
                                          }
                                        >
                                          <div
                                            className="card d-flex align-items-center justify-content-center"
                                            style={{
                                              background:
                                                "linear-gradient(to bottom, transparent 30%, #1f1d2b 20%, #1f1d2b 100%)",
                                              backgroundPosition: "center 30%",
                                              border: "none",
                                            }}
                                          >
                                            <div className="image-circle">
                                              <img
                                                className="card-img-top rounded-circle"
                                                src={matzo}
                                                alt="Product"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              />
                                            </div>
                                            <div className="card-body">
                                              <h5
                                                className="card-title text-center"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "normal",
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                {inv.StyleDesc}
                                              </h5>
                                              {inv.Price ==
                                              inv.OriginalPrice ? (
                                                <p
                                                  className="card-text text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    lineHeight: "1.5",
                                                  }}
                                                >
                                                  ${inv.Price?.toFixed(2)}
                                                </p>
                                              ) : (
                                                <div>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    $
                                                    {inv.OriginalPrice.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      marginTop: "-15px",
                                                    }}
                                                  >
                                                    ${inv.Price?.toFixed(2)}
                                                  </p>
                                                </div>
                                              )}
                                            </div>

                                            <button
                                              style={{
                                                marginTop: 0,
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px", // Reduced padding
                                                fontSize: "14px", // Reduced font size
                                                border: "2px solid #2E8B57", // Added a slightly darker border
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                width: "90%",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                                transition: "all 0.3s ease", // Smooth transition for hover effects
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)"; // Slightly enlarge button on hover
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)"; // Reset size on mouse out
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                                              }}
                                              onClick={() =>
                                                this.addToCart(
                                                  inv,
                                                  cart,
                                                  setCart
                                                )
                                              }
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                <div ref={this.state.etcRef}>
                                  <h6
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                      marginRight: "25px",
                                    }}
                                  >
                                    ETC
                                  </h6>

                                  <div className="item-container" style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>
                                    {inventories
                                      ?.filter?.(
                                        (inv) =>
                                          inv.PriceList === 0 ||
                                          inv.PriceList === null
                                      )
                                      ?.map((inv) => (
                                        <div
                                          className="col-sm-6 narrow-container"
                                          style={{
                                            width: "30%",
                                            marginTop:"10px",

                                            paddingLeft: "20px",
                                          }}
                                          key={inv.StyleNo}
                                          onClick={() =>
                                            this.addToCart(inv, cart, setCart)
                                          }
                                        >
                                          <div
                                            className="card d-flex align-items-center justify-content-center"
                                            style={{
                                              background:
                                                "linear-gradient(to bottom, transparent 30%, #1f1d2b 20%, #1f1d2b 100%)",
                                              backgroundPosition: "center 30%",
                                              border: "none",
                                            }}
                                          >
                                            <div className="image-circle">
                                              <img
                                                className="card-img-top rounded-circle"
                                                src={matzo}
                                                alt="Product"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              />
                                            </div>
                                            <div className="card-body">
                                              <h5
                                                className="card-title text-center"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "normal",
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                {inv.StyleDesc}
                                              </h5>
                                              {inv.Price ==
                                              inv.OriginalPrice ? (
                                                <p
                                                  className="card-text text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    lineHeight: "1.5",
                                                  }}
                                                >
                                                  ${inv.Price?.toFixed(2)}
                                                </p>
                                              ) : (
                                                <div>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    $
                                                    {inv.OriginalPrice.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      marginTop: "-15px",
                                                    }}
                                                  >
                                                    ${inv.Price?.toFixed(2)}
                                                  </p>
                                                </div>
                                              )}
                                            </div>

                                            <button
                                              style={{
                                                marginTop: 0,
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px", // Reduced padding
                                                fontSize: "14px", // Reduced font size
                                                border: "2px solid #2E8B57", // Added a slightly darker border
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                width: "90%",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                                transition: "all 0.3s ease", // Smooth transition for hover effects
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)"; // Slightly enlarge button on hover
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)"; // Reset size on mouse out
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                                              }}
                                              onClick={() =>
                                                this.addToCart(
                                                  inv,
                                                  cart,
                                                  setCart
                                                )
                                              }
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                <div ref={this.state.kimchaRef}>
                                  <h6
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                      marginRight: "25px",

                                    }}
                                  >
                                    Kimcha
                                  </h6>

                                  <div className="item-container" style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>
                                    {inventories
                                      ?.filter?.(
                                        (inv) =>
                                          inv.PriceList === 3 ||
                                          inv.PriceList === null
                                      )
                                      ?.map((inv) => (
                                        <div
                                          className="col-sm-6 narrow-container"
                                          style={{
                                            width: "30%",
                                            marginTop:"10px",

                                            paddingLeft: "20px",
                                          }}
                                          key={inv.StyleNo}
                                          onClick={() =>
                                            this.addToCart(inv, cart, setCart)
                                          }
                                        >
                                          <div
                                            className="card d-flex align-items-center justify-content-center"
                                            style={{
                                              background:
                                                "linear-gradient(to bottom, transparent 30%, #1f1d2b 20%, #1f1d2b 100%)",
                                              backgroundPosition: "center 30%",
                                              border: "none",
                                            }}
                                          >
                                            <div className="image-circle">
                                              <img
                                                className="card-img-top rounded-circle"
                                                src={matzo}
                                                alt="Product"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              />
                                            </div>
                                            <div className="card-body">
                                              <h5
                                                className="card-title text-center"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "normal",
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                {inv.StyleDesc}
                                              </h5>
                                              {inv.Price ==
                                              inv.OriginalPrice ? (
                                                <p
                                                  className="card-text text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    lineHeight: "1.5",
                                                  }}
                                                >
                                                  ${inv.Price?.toFixed(2)}
                                                </p>
                                              ) : (
                                                <div>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    $
                                                    {inv.OriginalPrice.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      marginTop: "-15px",
                                                    }}
                                                  >
                                                    ${inv.Price?.toFixed(2)}
                                                  </p>
                                                </div>
                                              )}
                                            </div>

                                            <button
                                              style={{
                                                marginTop: 0,
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px", // Reduced padding
                                                fontSize: "14px", // Reduced font size
                                                border: "2px solid #2E8B57", // Added a slightly darker border
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                width: "90%",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                                transition: "all 0.3s ease", // Smooth transition for hover effects
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)"; // Slightly enlarge button on hover
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)"; // Reset size on mouse out
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                                              }}
                                              onClick={() =>
                                                this.addToCart(
                                                  inv,
                                                  cart,
                                                  setCart
                                                )
                                              }
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                <div ref={this.state.erevPesachRef}>
                                  <h6
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                      marginRight: "25px",

                                    }}
                                  >
                                    Erev Pesach
                                  </h6>

                                  {/* <div className="item-container">
                                    {inventories
                                      ?.filter?.(
                                        (inv) =>
                                          inv.PriceList === 3 ||
                                          inv.PriceList === null
                                      )
                                      ?.map((inv) => (
                                        <div
                                          className="col-sm-6 narrow-container"
                                          style={{
                                            width: "90%",
                                            paddingLeft: "20px",
                                          }}
                                          key={inv.StyleNo}
                                          onClick={() =>
                                            this.addToCart(inv, cart, setCart)
                                          }
                                        >
                                          <div
                                            className="card d-flex align-items-center justify-content-center"
                                            style={{
                                              background:
                                                "linear-gradient(to bottom, transparent 30%, #1f1d2b 20%, #1f1d2b 100%)",
                                              backgroundPosition: "center 30%",
                                              border: "none",
                                            }}
                                          >
                                            <div className="image-circle">
                                              <img
                                                className="card-img-top rounded-circle"
                                                src={matzo}
                                                alt="Product"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              />
                                            </div>
                                            <div className="card-body">
                                              <h5
                                                className="card-title text-center"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "normal",
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                {inv.StyleDesc}
                                              </h5>
                                              {inv.Price ==
                                              inv.OriginalPrice ? (
                                                <p
                                                  className="card-text text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    lineHeight: "1.5",
                                                  }}
                                                >
                                                  ${inv.Price?.toFixed(2)}
                                                </p>
                                              ) : (
                                                <div>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    $
                                                    {inv.OriginalPrice.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                  <p
                                                    className="card-text text-center"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                      lineHeight: "1.5",
                                                      marginTop: "-15px",
                                                    }}
                                                  >
                                                    ${inv.Price?.toFixed(2)}
                                                  </p>
                                                </div>
                                              )}
                                            </div>

                                            <button
                                              style={{
                                                marginTop: 0,
                                                backgroundColor: "transparent",
                                                color: "white",
                                                padding: "3px 7px", // Reduced padding
                                                fontSize: "14px", // Reduced font size
                                                border: "2px solid #2E8B57", // Added a slightly darker border
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                width: "30%",
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
                                                transition: "all 0.3s ease", // Smooth transition for hover effects
                                              }}
                                              onMouseOver={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1.05)"; // Slightly enlarge button on hover
                                                e.currentTarget.style.boxShadow =
                                                  "0 6px 12px rgba(0,0,0,0.2)"; // Increase shadow on hover
                                              }}
                                              onMouseOut={(e) => {
                                                e.currentTarget.style.transform =
                                                  "scale(1)"; // Reset size on mouse out
                                                e.currentTarget.style.boxShadow =
                                                  "0 4px 8px rgba(0,0,0,0.1)"; // Reset shadow on mouse out
                                              }}
                                              onClick={() =>
                                                this.addToCart(
                                                  inv,
                                                  cart,
                                                  setCart
                                                )
                                              }
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                  </div> */}
                                </div>
                              </div>
                            </div>

                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      {modalTitle}
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="d-flex flex-row bd-highlight mb-3">
                                      <div className="p-2 w-50 bd-highlight">
                                        <div className="input-group mb-3">
                                          <span className="input-group-text">
                                            Cus Name
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={StyleNo}
                                            onChange={this.changeorderName}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {StyleNo === 0 ? (
                                      <button
                                        type="button"
                                        className="btn btn-primary float-start"
                                        onClick={() => this.createClick()}
                                      >
                                        Create
                                      </button>
                                    ) : null}

                                    {StyleNo !== 0 ? (
                                      <button
                                        type="button"
                                        className="btn btn-primary float-start"
                                        onClick={() => this.updateClick()}
                                      >
                                        Update
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "30%",
                            height: "fit-content",
                            position: "sticky",
                            top: 160,
                          }}
                        >
                          <div>
                            <h2>{getTabTitle(displayedName)}</h2>
                            <span style={{color: balance>0?'red':'white'}}>
                              <strong>Balance:</strong> $ {parseFloat(balance).toFixed(2)}
                            </span>
                            <hr style={{marginTop:"0.5rem", marginBottom:"0.5rem"}}/>
                              <span >
                                <strong>Price Code:</strong> {PriceCode}
                              </span>
                              <hr style={{marginTop:"0.5rem", marginBottom:"0.5rem"}}/>

                            {customerData.map((item, index) => (
                              <div key={index}>
                                {/* <h3 style={{ color: '#e7d2b7', borderBottom: '1px solid #e7d2b7', paddingBottom: '10px' }}>{item.NameFirst} {item.NameLast}</h3> */}
                                <span>
                                  <strong>Address:</strong> {item.Address1}
                                  {item.Address1 && ", "}
                                  {item.Address2}
                                  {item.Address2 && ", "}
                                  {item.City}
                                  {item.City && ", "}
                                  {item.State}
                                  {item.State && ", "}
                                  {item.ZipCode}
                                </span>
                                <hr style={{marginTop:"0.5rem", marginBottom:"0.5rem"}}/>

                                <span>
                                  <strong>Phone1:</strong> {formatPhoneNumber(item?.Phone1?.replace(/\D/g, ''))}
                                </span>
                            <hr style={{marginTop:"0.5rem", marginBottom:"0.5rem"}}/>

                                <span>
                                  <strong>Phone2:</strong> {item.Phone2 && formatPhoneNumber(item?.Phone2?.replace(/\D/g, ''))}
                                </span>
                            <hr style={{marginTop:"0.5rem", marginBottom:"0.5rem"}}/>

                                {/* <p><strong>Email:</strong> {item.EmailAdrs}</p> */}
                                {/* <button onClick={() => this.handleEdit(item)} style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    marginLeft: '10px',
                    width: '50px',
                    padding: '3px 7px', // Reduced padding
                    fontSize: '12px',  // Reduced font size
                    border: '2px solid #2E8B57', // Added a slightly darker border
                    borderRadius: '3px',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Subtle shadow for depth
                    transition: 'all 0.3s ease' // Smooth transition for hover effects
                  }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = 'scale(1.05)';  // Slightly enlarge button on hover
                      e.currentTarget.style.boxShadow = '0 6px 12px rgba(0,0,0,0.2)'; // Increase shadow on hover
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = 'scale(1)'; // Reset size on mouse out
                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'; // Reset shadow on mouse out
                    }}>Edit</button> */}
                              </div>
                            ))}
                            <span>
                              {new Date().toLocaleDateString("en-US", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                              {"  -  "}
                              {new Date().toLocaleDateString("he-IL", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </span>
                          </div>
                        </div>
                        
                        <div
                          style={{
                            zIndex: 10,
                            position: "fixed",
                            top: "0px",
                            right: "5px",
                            bottom: 0,
                            overflowY: 'auto'
                          }}
                        >
                          <Cart
                            balance={balance}
                            handleShowAlert={this.handleShowAlert}
                            popupHandler={this.handleActivePopup}
                            alertHandler={this.handleActiveAlert}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </CartContext.Consumer>
              )}
            </PriceCodeDataContext.Consumer>
          )}
        </CustomerContext.Consumer>
      </>
    );
  }
}

export function formatPhoneNumber(s) {
  if(!s) return 
  // Ensure the string is exactly 10 digits long
  if (s?.length !== 10 || !/^\d{10}$/.test(s)) {
      return "";
  }

  // Format the string as a phone number
  const formattedNumber = `(${s.substring(0, 3)}) ${s.substring(3, 6)}-${s.substring(6)}`;

  return formattedNumber;
}