import React from 'react'

const GeneralAlert = ({onClose, popupText}) => {
    return (
      <>
      {/* Background overlay */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)", // Change opacity as needed
          zIndex: 9998, // Set a lower z-index than your popup
        }}
      ></div>
        <div
          style={{
            position: "fixed",
            zIndex: 99991,
            border: "2px solid rgb(103, 63, 54)",
            borderRadius: "12px",
            width: "30%",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#252836",
            borderRadius: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          }}
        >
          <div style={{ textAlign: "right"}}>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
              style={{
                border: "none",
                background: "none",
                color: "white",
                cursor: "pointer",
                outline: "none",
              }}
            >
              X
            </button>
          </div>
    
          <br />
          <h5
            style={{
              paddingBottom: "8px",
              textAlign: "center",
              borderBottom: "2px solid rgb(103, 63, 54)",
            }}
          >
            {popupText}
          </h5>
    
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              gap: "1rem",
            }}
          >
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              OK
            </button>
          </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  OK
                </button>
             
              </div>
            
          
         
        </div>
      </>
      );
}

export default GeneralAlert